import { Box, Grid, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { InputField } from "../../../../ui-library/form-elements/CustomTextField";
import * as Yup from "yup";
import { useState } from "react";
import UserManagementManager from "../../managers/userManagementManager";
import { toast } from "react-toastify";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { FormContainer } from "../../../../ui-library/form-elements/FormContainer";
import { tooltipText } from "../../constants/common";
import { ToggleSwitch } from "../../../../ui-library/buttons/ToggleSwitch";

const initialValues = {
  name: "",
  email: "",
  addPromotion: true,
  onboardingFees: 40000,
  upfrontDrawdown: 40000,
  placementFees: 0,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  onboardingFees: Yup.number().when("addPromotion", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Onboarding Fee is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  upfrontDrawdown: Yup.number().when("addPromotion", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Upfront Drawdown is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  placementFees: Yup.number().when("addPromotion", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Placement Fee is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export default function AddUser() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const [submitting, setSubmitting] = useState(false);

  const handleAddUser = async (values: any, actions: any) => {
    try {
      setSubmitting(true);
      const result = await userManagementManager.addUser(values);
      setSubmitting(false);
      if (result?.data) toast.success("User added successfully");
      actions.resetForm();
    } catch (error: any) {
      toast.error(error?.detail);
      setSubmitting(false);
    }
  };
  const breadCrumbData: any = {
    stepKey: 1,
    steps: [
      {
        title: "User Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: "Add New Investor",
      navigationButton: false,
      helpText: tooltipText.addNewInvestor,
    },
  };
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={handleAddUser}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          setTouched,
          setFieldValue,
          handleSubmit,
        }) => {
          return (
            <FormContainer>
              <Form>
                <Stack gap={2}>
                  <InputField
                    label="Full Name"
                    error={errors?.name}
                    id="name"
                    name="name"
                    fieldName="name"
                    value={values?.name}
                    required
                  />
                  <InputField
                    label="Email"
                    fieldName="email"
                    error={errors?.email}
                    id="email"
                    name="email"
                    value={values?.email}
                    required
                  />

                  <Box textAlign="left" my={1} ml={1}>
                    <ToggleSwitch
                      switchLabel="Add Promotion"
                      showYesOrNo
                      switchLabelPositon="start"
                      defaultChecked={values?.addPromotion}
                      labelSwitchDistance="20px"
                      onSwitchToggle={(value: any) => {
                        setFieldValue("addPromotion", value);
                      }}
                    />
                  </Box>
                  {values?.addPromotion ? (
                    <>
                      {" "}
                      <InputField
                        label={"Onboarding Fee"}
                        id="onboardingFees"
                        name="onboardingFees"
                        fieldName="onboardingFees"
                        value={values?.onboardingFees}
                        type="number"
                        required
                        error={errors?.onboardingFees}
                      />
                      <InputField
                        label={"Upfront Drawdown"}
                        id="upfrontDrawdown"
                        name="upfrontDrawdown"
                        fieldName="upfrontDrawdown"
                        value={values?.upfrontDrawdown}
                        type="number"
                        required
                        error={errors?.upfrontDrawdown}
                      />
                      <InputField
                        label={"Placement Fee"}
                        error={errors?.placementFees}
                        id="placementFees"
                        name="placementFees"
                        fieldName="placementFees"
                        value={values?.placementFees}
                        type="number"
                        required
                      />{" "}
                    </>
                  ) : null}
                  <Stack direction="row" gap={1} justifyContent="end">
                    <ActionButton
                      onClick={handleSubmit}
                      disabled={submitting}
                      isLoading={submitting}
                    >
                      Create Investor
                    </ActionButton>
                  </Stack>
                </Stack>
              </Form>
            </FormContainer>
          );
        }}
      </Formik>
    </Box>
  );
}
