import { SxProps } from "@mui/material";
import { getColor } from "../Styles/Colors";
import { CSSProperties } from "react";

export const SectionHeaderSx: { [key: string]: SxProps } = {
  tooltip: {
    backgroundColor: `${getColor("shades", 0)} !important`,
    color: `${getColor("shades", 100)} !important`,
    boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.4) !important",
    padding: "16px !important",
    right: "8px !important",
  },
  tooltipArrow: {
    "&:before": { backgroundColor: `${getColor("shades", 0)} !important` },
    fontSize: "0.9rem !important",
    left: "4px !important",
  },
  tooltipArrowDropdown: {
    "&:before": { backgroundColor: `${getColor("shades", 0)} !important` },
    fontSize: "0.9rem !important",
  },
  tooltipImage: {
    display: "inline",
    paddingLeft: "12px",
    verticalAlign: "middle",
    zIndex: 1000,
  },
};
