import requestManager from "../../../api/requestManager";

export const getVerifyingInvestor = (
  mode: string,
  search: string,
  page: number
) => {
  const finalURL = `/v1/admin/user_management/verify_investor/?mode=${mode}&search_text=${search}&page=${page}`;
  return requestManager.apiGet(finalURL);
};

export const getNewVerifyingInvestor = (queryString: string) => {
  const finalURL = `/v1/admin/user_management/new_verify_investor/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const approveInvestor = (userId: string, data: any) => {
  const finalURL = `/v1/admin/user_management/new_verify_investor/approve_user/?user_id=${userId}`;
  return requestManager.apiPut(finalURL, data);
};

export const rejectInvestor = (userId: string, data: any) => {
  const finalURL = `/v1/admin/user_management/new_verify_investor/reject_user/?user_id=${userId}`;
  return requestManager.apiPut(finalURL, data);
};

export const getNonSebiInvestors = (queryString: string) => {
  const finalURL = `/v1/admin/user_management/non_sebi_investors/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const getProfilePendingInvestors = (queryString: string) => {
  const finalURL = `/v1/admin/user_detail/profile_incomplete_users/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const getLookupData = (queryString: string) => {
  const finalURL = `/v1/admin/lookups/get_lookup_data/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const getNoteForUser = (id: string, type: string) => {
  const finalURL = `/v1/admin/lv_notes/?resource_id=${id}&resource_type=${type}`;
  return requestManager.apiGet(finalURL);
};

export const addNoteForUser = (id: string, type: string, note: string) => {
  const user: any = localStorage.getItem("user");
  const userId = JSON.parse(user)?.id;
  const finalURL = `/v1/admin/lv_notes/`;
  const data = {
    note,
    resource_type: type,
    resource_id: id,
    user_id: userId,
  };
  return requestManager.apiPost(finalURL, data);
};

export const markVerified = (id: string) => {
  const finalURL = `/v1/admin/user_management/verify_phone_number/mark_verified/`;
  const data = {
    id: id,
  };
  return requestManager.apiPost(finalURL, data);
};

export const moveToAwaiting = (data: any) => {
  const finalURL = `/v1/admin/user_management/non_sebi_investors/move_to_awaiting/`;
  return requestManager.apiPost(finalURL, data);
};

export const getAllUsers = (queryString: string) => {
  const finalURL = `/v1/admin/user_management/user_list/?${queryString}`;

  return requestManager.apiGet(finalURL);
};

export const getUserDetail = (userId: string) => {
  const finalURL = `/v1/admin/user_detail/get_user_details/?user_id=${userId}`;
  return requestManager.apiGet(finalURL);
};

export const updateUserData = (data: any, userId: any) => {
  const finalURL = `/v1/admin/user_detail/admin_action/${
    userId ? `?user_id=${userId}` : ""
  }`;
  return requestManager.apiPut(finalURL, data);
};

export const updatePlanType = (data: any) => {
  const finalURL = `/v1/subscriptions/give_free_subscrption/`;
  return requestManager.apiPost(finalURL, data);
};

export const addUser = (data: any) => {
  const finalURL = `/v1/syndicates/invite_member/?type_of_member=user&type_of_action=invite`;
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  return requestManager.apiPost(finalURL, data, config);
};

export const getInvestmentProfile = (search: string, investor_id: string) => {
  const finalURL = `/v1/admin/lookups/get_lookup_data/?type=document_vault_investor_informations&q=${search}&user_id=${investor_id}`;
  return requestManager.apiGet(finalURL);
};

export const getKycStatusData = (queryString: string) => {
  let finalURL = `/v1/kyc/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const updateReviewStatus = (recordId: string, body: any) => {
  const finalURL = `/v1/kyc/update_kyc_review_status/?kyc_id=${recordId}`;
  return requestManager.apiPost(finalURL, body);
};

/**Verify Phone Number */
export const getVerifyingPhoneNumber = (queryString: string) => {
  const finalURL = `/v1/admin/user_management/onboard_phone_no_user_list/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const getNewVerifyingPhoneNumber = (queryString: string) => {
  const finalURL = `/v1/admin/user_management/verify_phone_number/?${queryString}`;
  return requestManager.apiGet(finalURL);
};

export const impersonateUser = (userId: string) => {
  const finalURL = `/v1/admin/impersonate/impersonate_user/`;
  return requestManager.apiPost(finalURL, { user_id: userId });
}
