import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/authContext";
import { ApplicationLoader } from "../ui-library/loaders/application-loader";

const PrivateRoute = (props: any) => {
  const { children } = props;
  const { authState, isAuthLoading } = useContext(AuthContext);
  const isAuthenticated = !!authState?.token;
  const location = useLocation();

  if (isAuthLoading) {
    return <ApplicationLoader fullScreen />;
  }
  if (!isAuthenticated && !isAuthLoading) {
    return <Navigate to="login" replace />;
  }
  if (isAuthenticated && location.pathname === "/")
    return <Navigate to="/dashboard" replace />;

  return children;
};

export default PrivateRoute;
