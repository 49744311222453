import { Box, Grid } from "@mui/material";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { useState } from "react";
import StartCaGeneration from "./StartCaGeneration";
import CAApprovalProcess from "./CAApprovalProcess";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import {
  breadCrumbDataCADocManagement,
  CADoctabs,
} from "../../constants/CADocConstants";

export default function CaDocaManagement() {
  const [selectedTab, setSelectedTab] = useState(CADoctabs[0].value);

  return (
    <Box>
      <Grid container>
        <Grid item xs={8}>
          <Breadcrumb
            steps={breadCrumbDataCADocManagement?.steps}
            pageHeader={breadCrumbDataCADocManagement.pageHeader}
            stepKey={breadCrumbDataCADocManagement.stepKey}
          />
        </Grid>
      </Grid>
      <HorizontalTabs
        tabs={CADoctabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
        }}
        scrollable={false}
      />
      {selectedTab === "generate_ca_document" ? <StartCaGeneration /> : null}
      {selectedTab === "approve_ca_process" ? <CAApprovalProcess /> : null}
    </Box>
  );
}
