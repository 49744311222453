import { InputAdornment, Stack, Typography } from "@mui/material";
import { Dropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import { FileUpload } from "../../../../ui-library/form-elements/file-upload/FileUpload";
import { InputField } from "../../../../ui-library/form-elements/CustomTextField";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import SyndicateManagement from "../../managers/syndicateManagementManager";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import {
  bussinessTypeOptions,
  startupStageOptions,
} from "../../../../constants/sartupConstants";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { Form, Formik } from "formik";
import { TimePeriodDropdown } from "../../../../ui-library/dropdowns/time-period-dropdown";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { FormContainer } from "../../../../ui-library/form-elements/FormContainer";
import { tooltipText } from "../../../startupManagement/constants/StartupDetailsContant";

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Startup Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Create Startup",
    navigationButton: false,
    helpText: tooltipText.createStartup,
  },
};

const initialValues = {
  startupName: "",
  startupStage: "",
  startupPermalink: "",
  businessType: "",
  summary: "",
  twitterPitch: "",
  amount: "",
  pitchDeck: [],
  amountUnit: { label: "Lakhs", value: 100000 },
};
const validationSchema = Yup.object().shape({
  startupName: Yup.string().required("Startup Name is required"),
  startupStage: Yup.object().required("Startup Stage is required"),
  startupPermalink: Yup.string().required("Startup Permalink is required"),
  businessType: Yup.object().required("Business Type is required"),
  summary: Yup.string().required("Summary is required"),
  twitterPitch: Yup.string().required("Twitter Pitch is required"),
  amount: Yup.number().required("Amount is required"),
});
export default function CreateStartup() {
  const authUser = useContext(AuthContext);
  const syndicateManagementManager = new SyndicateManagement(authUser);
  const userManagementManager = new UserManagementManager(authUser);
  const [creating, setCreating] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [locationOptions, setLocationOptions] = useState<any>([]);
  const getLocationOptions = async (search: string) => {
    try {
      let result = await userManagementManager.getLookupData({
        search,
        type: "locations",
      });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setLocationOptions(result);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const createStartup = async (values: any, actions: any) => {
    try {
      setCreating(true);
      const result = await syndicateManagementManager.createStartup(
        values,
        selectedLocation
      );
      setCreating(false);
      setSelectedLocation(null);

      actions.resetForm({ touched: {} });
      toast.success(result?.message);
    } catch (e: any) {
      setCreating(false);
      toast.error(e?.detail);
    }
  };

  return (
    <Stack>
      <Breadcrumb
        steps={breadCrumbData?.steps}
        pageHeader={breadCrumbData.pageHeader}
        stepKey={breadCrumbData.stepKey}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createStartup}
        enableReinitialize
      >
        {({ values, errors, handleSubmit, setFieldValue, touched }) => {
          return (
            <FormContainer>
              <Form>
                <Stack gap={2} mb={3}>
                  <InputField
                    label="Startup Name"
                    fieldName="startupName"
                    id="startupName"
                    name="startupName"
                    value={values.startupName}
                    error={!!errors.startupName}
                    type="text"
                    required
                  />
                  <Dropdown
                    label="Startup Stage"
                    required
                    id="startupStage"
                    name="startupStage"
                    fieldName="startupStage"
                    options={startupStageOptions}
                    value={values.startupStage}
                    error={!!errors.startupStage}
                  />
                  <LvAutoComplete
                    label="Location"
                    options={locationOptions}
                    setOptions={setLocationOptions}
                    selectedObject={selectedLocation}
                    setSelectedObject={setSelectedLocation}
                    onSelect={() => setLocationOptions([])}
                    onChange={getLocationOptions}
                  />
                  <InputField
                    label="Startup Permalink"
                    fieldName="startupPermalink"
                    id="startupPermalink"
                    name="startupPermalink"
                    value={values.startupPermalink}
                    error={!!errors.startupPermalink}
                    type="text"
                    required
                    helperText={tooltipText.createStartupPermalink}
                  />
                  <Dropdown
                    label="Type of Business"
                    required
                    id="businessType"
                    name="businessType"
                    fieldName="businessType"
                    options={bussinessTypeOptions}
                    value={values.businessType}
                    error={!!errors.businessType}
                  />
                  <InputField
                    label="Product/Service Summary"
                    fieldName="summary"
                    id="summary"
                    name="summary"
                    value={values.summary}
                    error={!!errors.summary}
                    type="text"
                    required
                  />
                  <InputField
                    label="Twitter Pitch"
                    fieldName="twitterPitch"
                    id="twitterPitch"
                    name="twitterPitch"
                    value={values.twitterPitch}
                    error={!!errors.twitterPitch}
                    type="text"
                    required
                  />
                  <TimePeriodDropdown
                    coreDropdownProps={{
                      label: "",
                      options: [{ label: "Lakhs", value: 100000 }],
                      disabled: true,
                      defaultValue: { label: "Lakhs", value: 100000 },
                      value: values.amountUnit,
                      id: "amountUnit",
                      name: "amountUnit",
                      fieldName: "amountUnit",
                      hideEndAdornment: true,
                    }}
                    customTextFieldProps={{
                      id: "amount",
                      name: "amount",
                      fieldName: "amount",
                      required: true,
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography color={getColor("primary", 700)}>
                              ₹
                            </Typography>
                          </InputAdornment>
                        ),
                      },
                      value: values.amount,
                      label: "Amount to Raise",
                      type: "number",
                    }}
                    dropdownWidth="120px"
                    id="amount"
                    name="amount"
                    fieldName="amount"
                    // error={errors?.amount}
                    // touched={!!touched?.amount}
                  />
                  <FileUpload
                    label="Pitch Deck"
                    placeholder="select a file"
                    selectedFile={(file: any) =>
                      setFieldValue("pitchDeck", file)
                    }
                    multiple
                    value={values.pitchDeck}
                    accept={{
                      "text/pdf": [".pdf"],
                    }}
                    title="Upload Pitch Deck"
                    hideDocumentVault
                  />
                  <Stack mt={2} direction="row" justifyContent="right">
                    <ActionButton
                      onClick={handleSubmit}
                      type={"submit"}
                      isLoading={creating}
                      disabled={creating}
                    >
                      Create Startup
                    </ActionButton>
                  </Stack>
                </Stack>
              </Form>
            </FormContainer>
          );
        }}
      </Formik>
    </Stack>
  );
}
