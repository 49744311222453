import { FC, useCallback, useState } from "react";
import { Grid, InputAdornment, Stack, Typography } from "@mui/material";
import { Formik } from "formik";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";
import { InputField } from "../../../../../ui-library/form-elements/CustomTextField";
import { ToggleSwitch } from "../../../../../ui-library/buttons/ToggleSwitch";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "../../../../../ui-library/navs/breadcrumb";
import { FormContainer } from "../../../../../ui-library/form-elements/FormContainer";
import { TimePeriodDropdown } from "../../../../../ui-library/dropdowns/time-period-dropdown";
import { getColor } from "../../../../../ui-library/Styles/Colors";
import LvAutoComplete from "../../../../../ui-library/autoComplete/LvAutoComplete";
import { isEmpty } from "../../../../../utils/commonUtils";
import StartupManagementManager from "../../../managers/startupManager";
import { AuthContext } from "../../../../../contexts/authContext";
import { getChangedValues } from "../../../../../utils/formUtils";
import { toast } from "react-toastify";

type PortfolioDetailsEditType = {};

const PortfolioDetailsEdit: FC<PortfolioDetailsEditType> = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;
  const startupDetails = state?.startupDetails ?? {};
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const startupManagementManager = new StartupManagementManager(AuthContext);
  const PrimaryStartup = startupDetails?.Primary_startup;
  const formattedPrimaryStartup = !isEmpty(PrimaryStartup)
    ? { label: PrimaryStartup.name, value: PrimaryStartup.id }
    : null;
  const [primaryStartupOptions, setPrimaryStartupOptions] = useState([]);

  const initialValues = {
    fundPanNumber: startupDetails?.startup_pan_number ?? "",
    selectedPrimaryStartup: formattedPrimaryStartup,
  };

  const EditBreadCrumbData = {
    stepKey: 3,
    breadcrumbSeparator: true,
    steps: [
      {
        title: "Startup Management",
        titleDisplay: true,
      },
      {
        title: "Portfolio Details",
        titleDisplay: true,
      },
      {
        title: "Edit Portfolio Details",
        titleDisplay: true,
      },
    ],
    pageHeader: {
      header: startupDetails?.name ?? "Edit Portfolio Details",
      navigationButton: true,
      navigationFunction: () => {
        navigate(`/startup/startup-panel/${startupDetails.id}/`, {
          state: { selectedTab: "portfolio_details" },
        });
      },
    },
  };

  const fetchPrimaryStartups = async (searchString: any) => {
    // API call to fetch primary startups
  };

  const handleStartupDetailsSubmit = async (values: any) => {
    try {
      setFormSubmitting(true);
      const newValues = getChangedValues(values, initialValues);
      await startupManagementManager.updateStartupData(
        newValues,
        startupDetails.id
      );
      setFormSubmitting(false);
      toast.success("Startup details updated successfully");
      setIsConfirmationModalOpen(false);
      navigate(`/startup/startup-panel/${startupDetails.id}/`, {
        state: { selectedTab: "portfolio_details" },
      });
    } catch (error) {
      console.error("Error while submitting the startup details");
      toast.error("Error while submitting the startup details");
      setFormSubmitting(false);
    }
  };

  return (
    <Stack>
      <Grid container>
        <Grid item xs={5}>
          <Breadcrumb
            steps={EditBreadCrumbData?.steps}
            pageHeader={EditBreadCrumbData.pageHeader}
            stepKey={EditBreadCrumbData.stepKey}
            breadcrumbSeparator
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={() => setIsConfirmationModalOpen(true)}
      >
        {({ values, errors, setFieldValue, handleSubmit, dirty }) => {
          return (
            <FormContainer>
              <Stack gap={2} mt={2}>
                <LvAutoComplete
                  label="Primary Startup"
                  options={primaryStartupOptions}
                  setOptions={setPrimaryStartupOptions}
                  selectedObject={values.selectedPrimaryStartup}
                  setSelectedObject={(values: any) =>
                    setFieldValue("selectedPrimaryStartup", values)
                  }
                  onChange={fetchPrimaryStartups}
                />
                <Stack direction="row" justifyContent="end" mt={2}>
                  <ActionButton disabled={!dirty} onClick={handleSubmit}>
                    Submit
                  </ActionButton>
                </Stack>
              </Stack>
              <CommonModal
                isOpen={isConfirmationModalOpen}
                handleClose={() => setIsConfirmationModalOpen(false)}
                handleSubmit={() => handleStartupDetailsSubmit(values)}
                title="Are you sure?"
                submitText="Submit"
                buttonStyles={{ width: "100%" }}
                closeIcon
                hideCancelButton
                isLoading={formSubmitting}
                disableSubmitButton={formSubmitting}
              >
                <Stack>Are you sure you want to submit the changes?</Stack>
              </CommonModal>
            </FormContainer>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default PortfolioDetailsEdit;
