import {
  getNonSebiInvestors,
  getVerifyingInvestor,
  getNewVerifyingInvestor,
  getLookupData,
  getNoteForUser,
  addNoteForUser,
  getUserDetail,
  addUser,
  getInvestmentProfile,
  getKycStatusData,
  updateReviewStatus,
  getNewVerifyingPhoneNumber,
  getVerifyingPhoneNumber,
  approveInvestor,
  rejectInvestor,
  moveToAwaiting,
  markVerified,
  getProfilePendingInvestors,
  getAllUsers,
  updateUserData,
  updatePlanType,
  impersonateUser
} from "../services/userManagementService";
import { AuthContextProps, UserAuthInfo } from "../../../contexts/authContext";
import { GenderOptions } from "../constants/common";
import {
  getFormattedData,
  getFormattedDataJson,
} from "../../../utils/formUtils";
import { getQueryString } from "../../../utils/apiUtils";

type lookupDataParams = {
  search: string;
  type: string;
  user_search_private_hashtag?: string;
  without_investment_profile?: boolean;
  user_id?: string;
};
export default class UserManagementManager {
  private userData: UserAuthInfo;

  constructor(AuthContext: AuthContextProps) {
    this.userData = AuthContext.authState;
  }

  async getVerifyingInvestorAwaiting(
    search: string,
    page: number,
    isNew?: string
  ) {
    try {
      const queryString = getQueryString({
        mode: "awaiting",
        search_text: search,
        page,
      });
      let result = isNew
        ? await getNewVerifyingInvestor(queryString)
        : await getVerifyingInvestor("awaiting", search, page);
      let formattedData = result?.data?.results?.map((item: any) => ({
        ...item,
        date: item.signup_date,
        joined: item?.joined ? "Yes" : "No",
        type: item.investor_type,
        location: isNew
          ? item?.city[0]?.label + " , " + item?.citizenship_country[0]?.label
          : item.city,
        profileType: item?.join_user_info?.describes_your_profile,
        netWorth2Cr: item?.join_user_info?.net_worth ? "yes" : "no",
        individualHaving: {
          fresher: !!item?.join_user_info?.individual_statement_fresher,
          intermediate:
            !!item?.join_user_info?.individual_statement_intermediate,
          experience: !!item?.join_user_info?.individual_statement_experience,
          noneOfAbove: !(
            !!item?.join_user_info?.individual_statement_fresher ||
            !!item?.join_user_info?.individual_statement_intermediate ||
            !!item?.join_user_info?.individual_statement_experience
          ),
        },
        country: item?.join_user_info?.citizenship_country,
        mobile: isNew ? item?.phone_no : item?.mobile,
        gender: GenderOptions?.find((it: any) => it?.value === item?.gender),
      }));
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getVerifyingInvestorVerified(
    search: string,
    page: number,
    isNew?: string
  ) {
    try {
      const queryString = getQueryString({
        mode: "onboarded",
        search_text: search,
        page,
      });
      let result = isNew
        ? await getNewVerifyingInvestor(queryString)
        : await getVerifyingInvestor("onboarded", search, page);
      let formattedData = result?.data?.results?.map((item: any) => ({
        ...item,
        date: item.approval_date,
        type: item.investor_type,
        joined: item?.joined ? "Yes" : "No",
        manager: item.relationship_manager,
        phone_no: isNew ? item?.phone_no : item.users_phone,
        location: isNew
          ? item?.city[0]?.label + " , " + item?.citizenship_country[0]?.label
          : item.city,
        outbound_user: item?.outbound ? "Yes" : "No",
      }));
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getVerifyingInvestorRejected(
    search: string,
    page: number,
    isNew?: string
  ) {
    try {
      const queryString = getQueryString({
        mode: "rejected",
        search_text: search,
        page,
      });

      let result = isNew
        ? await getNewVerifyingInvestor(queryString)
        : await getVerifyingInvestor("rejected", search, page);
      let formattedData = result?.data?.results?.map((item: any) => ({
        ...item,
        date: item.approval_date,
        joined: item?.joined ? "Yes" : "No",
        manager: item.relationship_manager,
        phone_no: isNew ? item?.phone_no : item.users_phone,
        location: isNew
          ? item?.city[0]?.label + " , " + item?.citizenship_country[0]?.label
          : item.city,
        type: item.investor_type,
        outbound_user: item?.outbound ? "Yes" : "No",
      }));
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getNonSebiInvestors(search: string, page: number) {
    try {
      const queryString = getQueryString({ search_text: search, page });
      let result = await getNonSebiInvestors(queryString);
      let formattedData = result?.data?.results?.map((item: any) => ({
        ...item,
        onboarding_as: item.onboard,
      }));
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getProfilePendingInvestors(search: string, page: number) {
    try {
      const queryString = getQueryString({
        search_params: search,
        page,
        per_page: 20,
      });
      let result = await getProfilePendingInvestors(queryString);
      let formattedData = result?.data?.users;
      return { formattedData, count: result?.data?.total_items };
    } catch (error) {
      throw error;
    }
  }

  async getLookupData({
    search,
    type,
    user_search_private_hashtag,
    without_investment_profile,
    user_id,
  }: lookupDataParams) {
    try {
      const queryString = getQueryString({
        q: search,
        type,
        user_search_private_hashtag,
        without_investment_profile,
        user_id,
      });
      let result = await getLookupData(queryString);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async getNoteForUser(id: string, type: string) {
    try {
      let result = await getNoteForUser(id, type);
      return { data: result.data.results };
    } catch (error) {
      throw error;
    }
  }

  async addNoteForUser(id: string, type: string, note: string) {
    try {
      let result = await addNoteForUser(id, type, note);
      return { data: result.data };
    } catch (error) {
      throw error;
    }
  }

  async getAllUsers(
    page: number,
    user_id?: string,
    user_type?: string,
    investor_type?: string
  ) {
    try {
      const queryString = getQueryString({
        page,
        user_id,
        page_size: 20,
        user_type,
        investor_type,
      });
      let result: any = await getAllUsers(queryString);
      let formattedData = result.data?.results ?? [];
      let count = result.data?.count;
      return { formattedData, count };
    } catch (error) {
      throw error;
    }
  }

  async getUserDetail(userId: string) {
    try {
      let result = await getUserDetail(userId);
      return result?.data?.result ?? {};
    } catch (error) {
      throw error;
    }
  }

  async markVerified(id: string) {
    try {
      let result = await markVerified(id);
      return { data: result.data };
    } catch (error) {
      throw error;
    }
  }

  async moveToAwaiting(data: any) {
    try {
      let result = await moveToAwaiting(data);
      return { data: result.data };
    } catch (error) {
      throw error;
    }
  }

  async addUser(data: any) {
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("email", data.email);
      if (data.addPromotion) {
        formData.append("onboarding_fee", data.onboardingFees);
        formData.append("upfront_drawdown", data.upfrontDrawdown);
        formData.append("placement_fee", data.placementFees);
      }
      let result = await addUser(formData);
      return { data: result?.data };
    } catch (error) {
      throw error;
    }
  }

  async getInvestmentProfile(investor_id: string) {
    try {
      let result = await getInvestmentProfile("", investor_id);
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async getKycStatusData(
    page: number,
    investor_id: string,
    stage: string,
    onboarding_complete: string
  ) {
    try {
      let onBoardComplete =
        onboarding_complete === "yes"
          ? true
          : onboarding_complete === "no"
          ? false
          : null;
      const queryString = getQueryString({
        admin_view: true,
        page,
        user_id: investor_id,
        lv_review_stage: stage,
        onboarding_complete: onBoardComplete,
      });

      let result = await getKycStatusData(queryString);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateReviewStatus(
    recordId: string,
    user_id: string,
    ckyc_no: string,
    comments: string,
    kra_id: string,
    kra_matches: boolean,
    kyc_matches: boolean,
    lv_review_stage: string
  ) {
    try {
      let data = {
        ckyc_no,
        comments,
        kra_id,
        kra_matches,
        kyc_matches,
        user_id,
        lv_review_stage,
      };
      let result = await updateReviewStatus(recordId, data);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  /** Verify Phone Number*/
  async getVerifyingPhoneAwaiting(page: number, isNew?: string) {
    try {
      const queryString = getQueryString({ mode: "awaiting", page });
      let result = isNew
        ? await getNewVerifyingPhoneNumber(queryString)
        : await getVerifyingPhoneNumber(queryString);

      let formattedData = result?.data?.results?.map((item: any) => ({
        ...item,
        date: item.date,
        name: item.name,
        email: item?.email,
        mobile: item.mobile_number,
        type: item.type,
      }));
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getVerifyingPhoneVerified(page: number, isNew?: string) {
    try {
      const queryString = getQueryString({ mode: "verified", page });

      let result: any = isNew
        ? await getNewVerifyingPhoneNumber(queryString)
        : await getVerifyingPhoneNumber(queryString);

      let formattedData = result?.data?.results?.map((item: any) => ({
        ...item,
        date: item.date,
        name: item.name,
        email: item?.email,
        mobile: item?.mobile_number,
        type: item?.type,
      }));
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async approveInvestor(userId: any, data: any) {
    try {
      let result = await approveInvestor(userId, data);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async rejectInvestor(userId: any, data: any) {
    try {
      let result = await rejectInvestor(userId, data);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateUserData(userId: any, data: any) {
    try {
      const keyMapping: { [key: string]: string } = {
        // basic detail
        email: "new_user_email",
        hashtags: "hashtag_token",
        onboardingFee: "onboarding_fee",
        upfrontDrawdown: "upfront_drawdown",
        placementFee: "placement_fee",
        isFaas: "faas_status",
        isLeadInvester: "lead_investor_status",
        backerOfPrivateSyndicate: "backer_of_private_syndicate",
        BackerOfPrivateSyndicateAdded: "backer_of_private_syndicate_added",
        backerOfPrivateSyndicateRemoved: "backer_of_private_syndicate_removed",

        //access detail
        activated: "activation_status",
        published: "publish",
        isBlocked: "blockdealaccess",
        privateSyndicateInvestorLandingPage: "platform_access_status",
        deactivateNote: "deactivate_note",

        // commitment actions
        addCommit: "add_commit",
        modifyCommit: "modify_commit",
        withdrawCommit: "withdraw_commit",
        startupId: "startup_id",
        proposedAmount: "proposed_amount",
        commitmentDate: "commitment_date",
        investmentPrivacy: "investment_privacy",
        outsideCommitment: "outside_commitment",
        triggerMail: "trigger_mail",

        // dropdown actions
        markTrending: "trending_status",
        markFeatured: "mark_featured",
        removeFeatured: "remove_featured",
        markFunded: "mark_funded",
        markFundedOutsideLV: "mark_funded_outside_lv",
        lockEditProfilePage: "lock_edit_profile",
      };
      if (data?.hashtags) {
        data.hashtags = data?.hashtags?.map((item: any) => item.value);
      }
      const formData = new FormData();
      const formattedData = getFormattedDataJson(data, keyMapping, formData);

      let result = await updateUserData(formattedData, userId);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async updatePlanType(userId: any, data: any = {}) {
    try {
      const formattedData = {
        user_id: userId,
        ...data,
      };

      let result = await updatePlanType(formattedData);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async impersonateUser(userId: string) {
    try {
      let result = await impersonateUser(userId);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }
}
