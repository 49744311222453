import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import React from "react";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import "../lead-investor-list/leadinvestor.css";
import { OutlinedButton } from "../../../../../../ui-library/buttons/OutlinedButton";
import { getColor } from "../../../../../../ui-library/Styles/Colors";
import { InputField } from "../../../../../../ui-library/form-elements/CustomTextField";
import { FileUpload } from "../../../../../../ui-library/form-elements/file-upload/FileUpload";
import { FieldArray } from "formik";
import { SmartDisplay } from "@mui/icons-material";
import { CommonModal } from "../../../../../../ui-library/modals/common-modal";

export default function PitchingSessionList(props: any) {
  const {
    isMandatory,
    carryError,
    labelTitle,
    notes,
    innerText,
    errors,
    labelName,
    values,
  } = props;

  const [playVideo, setPlayVideo] = React.useState("");
  const [pitchingTitle, setPitchingTitle] = React.useState("");

  const closeModal = () => {
    setPlayVideo("");
    setPitchingTitle("");
  };

  return (
    <div className="edit-form-row">
      {labelTitle && (
        <label className="edit__profile_label-title">
          {labelTitle}{" "}
          {isMandatory ? <span className="text-danger">*</span> : ""}
        </label>
      )}

      {!!playVideo && (
        <CommonModal
          isOpen={!!playVideo}
          handleClose={closeModal}
          title={pitchingTitle}
          closeIcon
          handleSubmit={closeModal}
          hideButtons
        >
          <Box width="40vw">
            <video width="100%" height="100%" controls src={playVideo}>
              Your browser does not support the video tag.
            </video>
          </Box>
        </CommonModal>
      )}

      <FieldArray
        name="pitchingSession"
        render={(arrayHelpers) => (
          <>
            <Grid container item xs={6} mt={1} mb={2}>
              <OutlinedButton
                onClick={() => arrayHelpers.push({ title: "", file: [] })}
                customStyles={{ whiteSpace: "nowrap" }}
              >
                <AddIcon style={{ marginRight: "10px" }} />
                {innerText}
              </OutlinedButton>
            </Grid>

            {values?.pitchingSession?.map((investor: any, index: number) => {
              return (
                <Stack
                  direction="row"
                  gap={2}
                  mt={index !== 0 ? 2 : "unset"}
                  alignItems="center"
                >
                  <Box>
                    <InputField
                      label={`Title ${index + 1}`}
                      placeholder="Add Title"
                      name={`pitchingSession[${index}].title`}
                      fieldName={`pitchingSession[${index}].title`}
                      id={`pitchingSession[${index}].title`}
                      value={investor.title}
                      disabled={investor?.id}
                      onChange={(value: any) => {
                        arrayHelpers.replace(index, {
                          file: investor?.file,
                          title: value,
                        });
                      }}
                    />
                  </Box>

                  <Box flex={1} display="flex" alignItems={"center"} gap={2}>
                    {investor.file?.length ? (
                      <IconButton
                        onClick={() => {
                          setPlayVideo(
                            investor?.id
                              ? investor?.file[0]?.path
                              : URL.createObjectURL(investor?.file[0])
                          );
                          setPitchingTitle(investor.title);
                        }}
                        sx={{
                          color: getColor("action_button", 300),
                        }}
                      >
                        <SmartDisplay
                          sx={{ fontSize: 30 }}
                          color={getColor("action_button", 300)}
                        />
                      </IconButton>
                    ) : null}
                    <FileUpload
                      // hideInputArea
                      title="Pitching Session"
                      placeholder="select file..."
                      selectedFile={(file: any) =>
                        arrayHelpers.replace(index, {
                          title: investor.title,
                          file: file,
                        })
                      }
                      disabled={investor?.id}
                      value={values?.pitchingSession?.[index]?.file}
                      error={!!errors?.pitchingSession?.[index]?.file}
                      // label="Upload File"
                      width="auto"
                      accept={{
                        "video/audio": [
                          "video/mp4",
                          "video/webm",
                          "video/ogg",
                          ".mp3",
                          ".ogg",
                          ".wav",
                        ],
                      }}
                      // helperText="You can upload video file only. Maximum File Size 250MB."
                      hideDocumentVault
                      maxFileSizeLimit={250000000}
                    />
                  </Box>

                  <div
                    className="ms-auto"
                    style={{ cursor: "pointer" }}
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    <DeleteOutlinedIcon />
                  </div>
                </Stack>
              );
            })}
          </>
        )}
      />

      {(isMandatory || carryError) && errors && errors[labelName] && (
        <Typography variant="subtitle1" color={getColor("error", 800)}>
          {errors[labelName]}
        </Typography>
      )}
      {!!notes && (
        <Typography variant="caption_regular" my="10px" textAlign="left">
          <span style={{ color: getColor("neutral", 600) }}>{notes}</span>
        </Typography>
      )}
    </div>
  );
}
