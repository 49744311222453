import { Tooltip, Typography } from "@mui/material";
import React, { FC } from "react";
import { LinkButton } from "../../../../../ui-library/buttons/LinkButton";

type Props = { array: any; labelKey: any };
type PropsHtml = { content: any; contentLength?: number };

const RenderShowMore: FC<Props> = ({ array, labelKey }) => {
  return array?.length ? (
    array?.length > 1 ? (
      <>
        <Typography align="left" variant="heading_04_medium" fontWeight={600}>
          {array?.[0]?.[labelKey]}{" "}
          <Tooltip
            title={array
              .slice(1)
              .map((investor: any) => investor?.[labelKey] ?? "User")
              .join(", ")}
            placement="top-start"
            arrow
            componentsProps={{
              tooltip: { sx: { background: "#f2f2f2", color: "#000" } },
            }}
          >
            <Typography display="inline" variant="paragraph_03_bold">
              <LinkButton title={`+ ${array?.length - 1} more`} />{" "}
            </Typography>
          </Tooltip>
        </Typography>
      </>
    ) : (
      <>{array?.[0]?.[labelKey]} </>
    )
  ) : (
    <p>-</p>
  );
};

const RenderShowMoreHtml = ({ content, contentLength = 30 }: PropsHtml) => {
  return content ? (
    content?.length > contentLength ? (
      <>
        <Typography align="left" mr={2}>
          <Typography
            component={"span"}
            display="inline"
            sx={{ "& p": { display: "inline" } }}
            dangerouslySetInnerHTML={{
              __html: `${content?.slice(0, contentLength)}...`,
            }}
          />

          <Tooltip
            title={
              <Typography
                variant="caption_medium"
                component={"span"}
                display="inline"
                sx={{ "& p": { display: "inline" } }}
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            }
            placement="top-start"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  background: "#f2f2f2",
                  color: "#000",
                },
              },
            }}
          >
            <Typography display="inline" variant="paragraph_03_bold">
              <LinkButton title={`read more`} />{" "}
            </Typography>
          </Tooltip>
        </Typography>
      </>
    ) : (
      <Typography
        component={"span"}
        display="inline"
        sx={{ "& p": { display: "inline" } }}
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    )
  ) : (
    <p>-</p>
  );
};

export { RenderShowMore as default, RenderShowMoreHtml };
