export const tooltipText = {
  initialScreening: (
    <p>
      In LV, startup evaluation occurs in two stages: Initial and Final
      Screening, both conducted by the startup’s team.
      <br /> Access to the detailed profile for updates is granted only after
      the Initial Screening is approved.
    </p>
  ),
  finalScreening: (
    <p>
      In LV, startup evaluation occurs in two stages: Initial and Final
      Screening, both conducted by the startup’s team.
      <br /> The startup can be marked as featured and made live for investors
      only after the Final Screening is approved.
    </p>
  ),
  publicDeal: (
    <p>
      Public deals are generally accessible to all investors unless their
      visibility is set to private in the admin startup panel within the admin
      dashboard.
      <br />• If an allow list is enabled, only the investors on that list can
      view the deal.
      <br />• Investors marked as private (FAAS investors) will not have access
      to public deals; they can only view FAAS deals.
    </p>
  ),
  liveDeal:
    "Deals that are currently live, visible to investors on the platform, and open for commitments.",
  faasDeal:
    "FAAS deals are exclusively visible to members of the private syndicate.",
  fundraisingClosed: (
    <p>
      Once live deals complete fundraising and are marked as “Fundraising
      Closed” in the startup panel within the admin dashboard, they will no
      longer be visible to investors on the platform.
      <br />
      The deal will then move to the AIF team for the next set of compliance
      processes.
    </p>
  ),
  funded:
    "Once all investor funds have been received, legal and compliance work is completed, and the startup has received the funds from LV AIF, the deal should be marked as “Funded.”",
  schemeName: (
    <p>
      For every deal that goes live on the LV platform, the deals team must
      create a scheme in ZOHO to capture key details, such as the SEBI term
      sheet, deal terms (including lead carry, pre-money valuation, etc.).
    </p>
  ),
};
