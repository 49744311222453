import { useState } from "react";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import CaDocPaymentTable from "./CaDocPaymentTable";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { Box, Grid } from "@mui/material";
import { tooltipText } from "../../constants/CADocConstants";

export default function CaDocPayment() {
  const [selectedTab, setSelectedTab] = useState("paid");
  const [page, setPage] = useState(1);

  const tabs = [
    {
      name: "Paid User",
      value: "paid",
      content: (
        <CaDocPaymentTable
          status={selectedTab}
          page={page}
          setPage={setPage}
          type="paid"
        />
      ),
    },
    {
      name: "Unpaid Users",
      value: "pending",
      content: (
        <CaDocPaymentTable
          status={selectedTab}
          page={page}
          setPage={setPage}
          type="pending"
        />
      ),
    },
  ];

  const handleTabChange = (tab: any) => {
    setPage(1);
    setSelectedTab(tab);
  };

  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "AIF Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: "CA Doc Online Payment Status",
      navigationButton: false,
      helpText: tooltipText.caDocPaymentStatus,
    },
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
      </Grid>

      <HorizontalTabs
        tabs={tabs}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
        xAxisPadding={false}
        customTabsStyles={{
          width: "100%",
          height: "44px",
        }}
        customTabStyles={{
          fontFamily: "Work Sans",
          lineHeight: "21px",
          textAlign: "center",
          letterSpacing: "0.5px",
          fontStyle: "normal",
        }}
      />
    </Box>
  );
}
