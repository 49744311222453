import { USER_ROLES } from "../../constants/appConstants";
import { Outlet } from "react-router-dom";
import FinalScreening from "../../app/startupManagement/components/finalScreening";
import CommittedInvestorCommunication from "../../app/startupManagement/components/committedInvestorCommunication";
import InitialScreening from "../../app/startupManagement/components/initialScreening";
import StartupDetails from "../../app/startupManagement/components/startupPanel/showDetails";
import StartupPanel from "../../app/startupManagement/components/startupPanel";
import AddUser from "../../app/userManagement/components/addUser";
import KycStatus from "../../app/userManagement/components/kycStatus";
import VerifyNewInvestors from "../../app/userManagement/components/verifyNewInvestors";
import ProfilePendingInvestor from "../../app/userManagement/components/profilePendingInvestor";
import ShowUser from "../../app/userManagement/components/userPanel/showUser";
import CommitTracker from "../../app/commitmentManagement/components/commit_tracker/page";
import PostCommitStatusTracker from "../../app/commitmentManagement/components/post_commit_status/page";
import CommitWithdrawInfo from "../../app/commitmentManagement/components/commit_withdraw_info/page";
import FeePaymentStatus from "../../app/commitmentManagement/components/fee_payment_status/page";
import DrawdownPaymentStatus from "../../app/commitmentManagement/components/drawdown_payment_status/page";
import CreatePaymentLink from "../../app/commitmentManagement/components/create_payment_link";
import DealDocuments from "../../app/documentManagement/components/dealDocuments";
import Rsvp from "../../app/campaignManagement/components/rsvp";
import CaDocaManagement from "../../app/aifManagement/components/caDocManagement";
import CaDocStatus from "../../app/aifManagement/components/caDocStatus";
import CaDocPayment from "../../app/aifManagement/components/caDocPaymentStatus";
import SchemeDocManagement from "../../app/aifManagement/components/schemeDocManagement";
import CreateStartup from "../../app/syndicateManagement/components/createStartup";
import NonSebiInvestor from "../../app/userManagement/components/nonSebiInvestor";
import InstitutionsPanel from "../../app/institutionsManagement/components/institutionsPanel";
import ShowInstitution from "../../app/institutionsManagement/components/showInstitution";
import InvestorDocuments from "../../app/documentManagement/components/investorDocuments";
import VerifyPhoneNumber from "../../app/userManagement/components/verifyPhoneNumber";
import NomineeInformation from "../../app/userManagement/components/nomineeInformation";
import DematInformation from "../../app/userManagement/components/dematInformation";
// import CreateMailerList from "../../app/campaignManagement/components/mailCentral/createMailerList";
// import NewCampaign from "../../app/campaignManagement/components/mailCentral/newCampaign";
import LvConfig from "../../app/technicalConfiguration/components/LvConfig";
import Logout from "../../app/auth/components/logout";
import Dashboard from "../../app/dashboard/components/index";
import CreateSyndicate from "../../app/syndicateManagement/components/createUpdateSyndicate/CreateSyndicate";
import UpdateSyndicate from "../../app/syndicateManagement/components/createUpdateSyndicate/UpdateSyndicate";
// import PostCommitmentDetail from "../../app/commitmentManagement/components/post_commitment_detail";
import QueryEditor from "../../app/technicalConfiguration/components/QueryEditor";
import UserPanel from "../../app/userManagement/components/userPanel";
import InvestorInformation from "../../app/userManagement/components/investorInformation/InvestorInformation";
import BasicDetailsEdit from "../../app/startupManagement/components/startupPanel/editDetails/BasicDetailsEdit";
import FundraiseDetailsEdit from "../../app/startupManagement/components/startupPanel/editDetails/FundraiseDetailsEdit";
import PortfolioDetailsEdit from "../../app/startupManagement/components/startupPanel/editDetails/PortfolioDetailsEdit";
import BasicUserDetailsEdit from "../../app/userManagement/components/userPanel/editUser/BasicUserDetailsEdit";
import AccessDetailsEdit from "../../app/userManagement/components/userPanel/editUser/AccessDetailsEdit";
import CommitmentActions from "../../app/userManagement/components/userPanel/editUser/CommitmentActions";
import SubscriptionDetailEdit from "../../app/userManagement/components/userPanel/editUser/SubscriptionDetailEdit";
import ShowAllCommitments from "../../app/startupManagement/components/startupPanel/showDetails/ShowAllCommitments";
import StartupExitData from "../../app/startupManagement/components/startupPanel/showDetails/StartupExitData";
import StartupReports from "../../app/startupManagement/components/startupReports";
import ForeignInvestorPaymentStatus from "../../app/aifManagement/components/foriegnInvestorPaymentStatus";

export const AdminRoutes = () => [
  {
    label: "DASHBOARD",
    value: "dashboard",
    icon: "fa-regular fa-grid-2",
    link: "dashboard",
    component: <Dashboard />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
  },
  {
    label: "MANAGE INVESTORS",
    value: "user_management",
    icon: "fa-regular fa-user",
    link: "user",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "All Investors",
        value: "investor",
        link: "investor",
        component: <UserPanel />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        value: "add_user",
        link: "add-user",
        component: <AddUser />,
        hide: true,
        roles: [USER_ROLES.ADMIN],
      },

      {
        value: "user_panel",
        link: "user-panel/:user_id",
        component: <ShowUser />,
        hide: true,
        roles: [USER_ROLES.ADMIN],
      },

      {
        value: "basic_user_edit",
        link: "user-panel/:user_id/edit/basic",
        component: <BasicUserDetailsEdit />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        value: "access_edit",
        link: "user-panel/:user_id/edit/access",
        component: <AccessDetailsEdit />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        value: "subscription_edit",
        link: "user-panel/:user_id/edit/subscription",
        component: <SubscriptionDetailEdit />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        value: "commit_actions",
        link: "user-panel/:user_id/edit/commit-actions",
        component: <CommitmentActions />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        label: "Verify New Investors",
        value: "verify_new_investors",
        link: "verify-new-investors",
        component: <VerifyNewInvestors />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Verify Phone Number",
        value: "verify_phone_number",
        link: "verify-phone-number",
        component: <VerifyPhoneNumber />,
        roles: [USER_ROLES.ADMIN],
      },

      {
        label: "Profile Pending Investor",
        value: "profile_pending_investor",
        link: "profile-pending-investor",
        component: <ProfilePendingInvestor />,
        roles: [USER_ROLES.ADMIN],
      },

      {
        label: "User KYC Status",
        value: "kyc_status",
        link: "kyc-status",
        component: <KycStatus />,
        roles: [USER_ROLES.ADMIN],
      },

      {
        label: "Investor Information",
        value: "investor_information",
        link: "investor-information",
        component: <InvestorInformation />,
        roles: [USER_ROLES.ADMIN],
      },

      {
        label: "Nominee Information",
        value: "nominee_information",
        link: "nominee-information",
        component: <NomineeInformation />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Demat Information",
        value: "demat_information",
        link: "demat-information",
        component: <DematInformation />,
        roles: [USER_ROLES.ADMIN],
      },
    ],
  },
  // {
  //   label: "MANAGE INSTITUTION",
  //   value: "institutions_management",
  //   icon: "fa-regular fa-building",
  //   link: "institution",
  //   component: <Outlet />,
  //   order: 1,
  //   roles: [USER_ROLES.ADMIN],
  //   suboptions: [
  //     {
  //       label: "Institution's Panel",
  //       value: "institution_panel",
  //       link: "institution-panel",
  //       component: <InstitutionsPanel />,
  //       roles: [USER_ROLES.ADMIN],
  //     },

  //     {
  //       value: "institution_panel",
  //       link: "show-institution/:id",
  //       component: <ShowInstitution />,
  //       hide: true,
  //       roles: [USER_ROLES.ADMIN],
  //     },
  //   ],
  // },
  {
    label: "MANAGE STARTUP",
    value: "startup_management",
    icon: "fa-regular fa-rocket",
    link: "startup",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "All Startups",
        value: "startup_panel",
        link: "startup-panel",
        component: <StartupPanel />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        hide: true,
        value: "create_startup",
        link: "create-startup",
        component: <CreateStartup />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "All Founders",
        value: "founder",
        link: "founder",
        component: <UserPanel />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        value: "startup_panel",
        link: "startup-panel/:startup_id",
        component: <StartupDetails />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        value: "basic_edit",
        link: "startup-panel/:startup_id/edit/basic",
        component: <BasicDetailsEdit />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        value: "fundraise_edit",
        link: "startup-panel/:startup_id/edit/fundraise",
        component: <FundraiseDetailsEdit />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        label: "Startup Reports",
        value: "startup_reports",
        link: "startup_reports",
        component: <StartupReports />,
        roles: [USER_ROLES.ADMIN],
      },
      // {
      //   value: "screening_edit",
      //   link: "startup-panel/:startup_id/edit/screening",
      //   component: <ScreeningDetailsEdit />,
      //   roles: [USER_ROLES.ADMIN],
      //   hide: true,
      // },
      {
        value: "portfolio_edit",
        link: "startup-panel/:startup_id/edit/portfolio",
        component: <PortfolioDetailsEdit />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        value: "portfolio_show_all_commitments",
        link: "startup-panel/:startup_id/show-all-commitments",
        component: <ShowAllCommitments />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        value: "portfolio_startup_exit_data",
        link: "startup-panel/:startup_id/portfolio-startup-exit-data",
        component: <StartupExitData />,
        roles: [USER_ROLES.ADMIN],
        hide: true,
      },
      {
        label: "Initial Screening",
        value: "initial_screening",
        link: "initial-screening",
        component: <InitialScreening />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Final Screening",
        value: "final_screening",
        link: "final-screening",
        component: <FinalScreening />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Investor Communication",
        value: "committed_investor_communication",
        link: "committed-investor-communication",
        component: <CommittedInvestorCommunication />,
        roles: [USER_ROLES.ADMIN],
      },
    ],
  },
  {
    label: "MANAGE SYNDICATE",
    value: "syndicate_management",
    icon: "fa-regular fa-handshake",
    link: "syndicate",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "Create Syndicate",
        value: "create_syndicate",
        link: "create-syndicate",
        component: <CreateSyndicate />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Update Syndicate",
        value: "update_syndicate",
        link: "update-syndicate",
        component: <UpdateSyndicate />,
        roles: [USER_ROLES.ADMIN],
      },
    ],
  },
  {
    label: "MANAGE COMMITMENT",
    value: "commitment_management",
    icon: "fa-regular fa-file-signature",
    link: "commitment",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "Commit Tracker",
        value: "commit_tracker",
        link: "commit-tracker",
        component: <CommitTracker />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Post Commit Status",
        value: "post_commit_status",
        link: "post-commit-status",
        component: <PostCommitStatusTracker />,
        roles: [USER_ROLES.ADMIN],
      },
      // {
      //   value: "post_commit_status",
      //   link: "post-commit-status/:id",
      //   component: <PostCommitmentDetail />,
      //   roles: [USER_ROLES.ADMIN],
      //   hide: true,
      // },
      {
        label: "Commit Withdraw Info",
        value: "commit_withdraw_info",
        link: "commit-withdraw-info",
        component: <CommitWithdrawInfo />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Transaction Payment Status",
        value: "fee_payment_status",
        link: "fee-payment-status",
        component: <FeePaymentStatus />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Drawdown Payment Status",
        value: "drawdown_payment_status",
        link: "drawdown-payment-status",
        component: <DrawdownPaymentStatus />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Create Payment Link",
        value: "create_payment_link",
        link: "create-payment-link",
        component: <CreatePaymentLink />,
        roles: [USER_ROLES.ADMIN],
      },
    ],
  },
  {
    label: "MANAGE AIF",
    value: "aif_management",
    icon: "fa-regular fa-file-alt",
    link: "aif",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "CA Doc Management",
        value: "ca_doc_management",
        link: "ca-doc-management",
        component: <CaDocaManagement />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "CA Doc Status",
        value: "ca_doc_status",
        link: "ca-doc-status",
        component: <CaDocStatus />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "CA Doc Payment Status",
        value: "ca_doc_online_payment_status",
        link: "ca-doc-online-payment-status",
        component: <CaDocPayment />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Payment Status Foreign Inv",
        value: "payment_status_for_foreign_investors",
        link: "payment-status-for-foreign-investors",
        component: <ForeignInvestorPaymentStatus />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Scheme Doc Management",
        value: "scheme_doc_management",
        link: "scheme-doc-management",
        component: <SchemeDocManagement />,
        roles: [USER_ROLES.ADMIN],
      },
    ],
  },
  {
    label: "MANAGE DOCUMENTS",
    value: "document_management",
    icon: "fa-regular fa-folder",
    link: "document",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "Investor Documents",
        value: "investor_documents",
        link: "investor-documents",
        component: <InvestorDocuments />,
        roles: [USER_ROLES.ADMIN],
      },
      {
        label: "Deal Documents",
        value: "deal_documents",
        link: "deal-documents",
        component: <DealDocuments />,
        roles: [USER_ROLES.ADMIN],
      },
    ],
  },
  {
    label: "MANAGE CAMPAIGN",
    value: "campaign_management",
    icon: "fa-regular fa-bullhorn",
    link: "campaign",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.ADMIN],
    suboptions: [
      {
        label: "RSVP",
        value: "rsvp",
        link: "rsvp",
        component: <Rsvp />,
        roles: [USER_ROLES.ADMIN],
      },
      // {
      //   label: "Mail Central",
      //   value: "mail_central",
      //   link: "mail-central",
      //   component: <MailCentral />,
      //   roles: [USER_ROLES.ADMIN],
      // },
      // {
      //   label: "Mailer Lists",
      //   value: "mailer_lists",
      //   link: "mailer-lists",
      //   component: <MailerLists />,
      //   roles: [USER_ROLES.ADMIN],
      //   hide: true,
      // },
      // {
      //   label: "Create Mailer List",
      //   value: "create_mailer_list",
      //   link: "create-mailer-list",
      //   component: <CreateMailerList />,
      //   roles: [USER_ROLES.ADMIN],
      //   hide: true,
      // },
      // {
      //   value: "edit_mailer_list",
      //   link: "edit-mailer-list/:list_id",
      //   component: <CreateMailerList />,
      //   roles: [USER_ROLES.ADMIN],
      //   hide: true,
      // },
      // {
      //   label: "Campaigns List",
      //   value: "campaigns_list",
      //   link: "campaigns-list",
      //   component: <CampaignsList />,
      //   roles: [USER_ROLES.ADMIN],
      //   hide: true,
      // },
      // {
      //   label: "New Campaign",
      //   value: "new_campaign",
      //   link: "new-campaign",
      //   component: <NewCampaign />,
      //   roles: [USER_ROLES.ADMIN],
      //   hide: true,
      // },
      // {
      //   value: "edit_campaign",
      //   link: "edit-campaign/:campaign_id",
      //   component: <NewCampaign />,
      //   roles: [USER_ROLES.ADMIN],
      //   hide: true,
      // },
      // {
      //   label: "Mailer Analytics",
      //   value: "mailer_analytics",
      //   link: "mailer-analytics",
      //   component: <MailerAnalytics />,
      //   roles: [USER_ROLES.ADMIN],
      //   hide: true,
      // },
    ],
  },
  {
    label: "TECHNICAL CONFIGS",
    value: "technical_configurations",
    icon: "fa-regular fa-cogs",
    link: "configurations",
    component: <Outlet />,
    order: 1,
    roles: [USER_ROLES.SUPER_ADMIN],
    suboptions: [
      {
        label: "LV Configs",
        value: "lv_configs",
        link: "lv_configs",
        component: <LvConfig />,
        roles: [USER_ROLES.SUPER_ADMIN],
      },
      {
        label: "Query Editor",
        value: "query_editor",
        link: "query-editor",
        component: <QueryEditor />,
        roles: [USER_ROLES.SUPER_ADMIN],
      },
    ],
  },
  {
    label: "LOGOUT",
    value: "lv-components",
    icon: "fa-regular fa-left-from-bracket",
    link: "lv/logout",
    component: <Logout />,
    order: 2,
    roles: [USER_ROLES.ADMIN],
    position: "bottom",
  },
];
