import { Box, Grid, Skeleton, Stack } from "@mui/material";
import { StatsticCard } from "../../../ui-library/card/statistic-card";
import { SectionHeader } from "../../../ui-library/header/section-header";
import { TablePlaceholder } from "../../../ui-library/placeholders/table-placeholder/TablePlaceholder";

export const DashboardSkeleton = () => {
  return (
    <Box>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <StatsticCard
            title={<Skeleton variant="text" width={100} />}
            valueComp={<Skeleton variant="text" width={50} />}
            width="250px"
            height="94px"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <StatsticCard
            title={<Skeleton variant="text" width={100} />}
            valueComp={<Skeleton variant="text" width={50} />}
            height="94px"
            width="250px"
          />
        </Grid>
      </Grid>

      <Box mt={3}>
        <SectionHeader>Public Deals</SectionHeader>

        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <StatsticCard
              title={<Skeleton variant="text" width={100} />}
              valueComp={<Skeleton variant="text" width={50} />}
              width="250px"
              height="94px"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <StatsticCard
              title={<Skeleton variant="text" width={100} />}
              valueComp={<Skeleton variant="text" width={50} />}
              height="94px"
              width="250px"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <StatsticCard
              title={<Skeleton variant="text" width={100} />}
              valueComp={<Skeleton variant="text" width={50} />}
              height="94px"
              width="250px"
            />
          </Grid>
        </Grid>
        <Grid mt={2}>
          <TablePlaceholder
            rows={5}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        </Grid>
      </Box>

      <Box mt={3}>
        <SectionHeader>FAAS Deals</SectionHeader>

        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <StatsticCard
              title={<Skeleton variant="text" width={100} />}
              valueComp={<Skeleton variant="text" width={50} />}
              width="250px"
              height="94px"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <StatsticCard
              title={<Skeleton variant="text" width={100} />}
              valueComp={<Skeleton variant="text" width={50} />}
              height="94px"
              width="250px"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <StatsticCard
              title={<Skeleton variant="text" width={100} />}
              valueComp={<Skeleton variant="text" width={50} />}
              height="94px"
              width="250px"
            />
          </Grid>
        </Grid>
        <Grid mt={2}>
          <TablePlaceholder
            rows={5}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        </Grid>
      </Box>
    </Box>
  );
};
