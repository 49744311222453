import { getUserMe } from "../authService/authService";

export class AuthManager {
  async getUserMe() {
    try {
      let result = await getUserMe();
      return result?.data;
    } catch (error) {
      throw error;
    }
  }
}
