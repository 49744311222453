import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { InputField } from "../../../../ui-library/form-elements/CustomTextField";
import { Form, Formik } from "formik";
import React, { useState, useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { DatePicker } from "../../../../ui-library/form-elements/DatePicker";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { RadioButtonSelect } from "../../../../ui-library/form-elements/RadioButtonSelect";
import { FormContainer } from "../../../../ui-library/form-elements/FormContainer";
import AifManagementManager from "../../managers/aifManagementManagers";
import { toast } from "react-toastify";
import { StartCaGenerationFormSchema } from "../../constants/CADocConstants";

const initialValuesForm = {
  date: "",
  onboardingFee: "",
  upfrontDrawdown: "",
  placementFee: "",
  isKycVerified: "no",
  isKraVerified: "no",
  uniqueId: "",
  stampPaper: [],
  isAadhaarAvailable: "no",
  kycNumber: "",
  pay_link: false,
};

const StartCaGeneration = () => {
  const userData = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(userData);
  const aifManagementManager = new AifManagementManager();

  const [selectedVal, setSelectedVal] = useState<any>({});
  const [option, setOption] = useState<any>([]);
  const [caDocLink, setCADocLink] = useState("");
  const [leegalityLink, setLeegalityLink] = useState("");
  const [caButtonLoading, setCAButtonLoading] = useState(false);
  const [llButtonLoading, setLLButtonLoading] = useState(false);
  const [smButtonLoading, setSMButtonLoading] = useState(false);
  const [initialValues, setInitailValues] = useState(initialValuesForm);

  const handleInvSelection = async (value: any) => {
    setInitailValues({
      ...initialValues,
      isKycVerified: !!value?.ckyc_no ? "yes" : "no",
      kycNumber: value?.ckyc_no,
      isAadhaarAvailable: value?.aadhar_available,
    });
    setSelectedVal(value);
  };

  const handleTextChange = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData({
        search: value,
        type: "only_aif_investor_users",
      });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
        investment_profile: item?.investment_profile,
        aadhar_available: !!item?.aadhar_available ? "yes" : "no",
        ckyc_no: item?.ckyc_no,
      }));
      setOption(result);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const viewFile = () => {
    window.open(caDocLink, "_blank");
  };

  const generateCADocument = async (values: any) => {
    try {
      const data = {
        user_id: selectedVal.value,
        date: values.date,
        onboarding_fee: values.onboardingFee,
        upfront_drawdown: values.upfrontDrawdown,
        placement_fee: values.placementFee,
        is_kyc_verified: values.isKycVerified,
        is_kra_verified: values.isKraVerified,
        kycNumber: values.kycNumber,
        kraNumber: values.kraNumber,
      };
      setCAButtonLoading(true);
      let result = await aifManagementManager.generateCADocument(data);
      if (result?.success) {
        setCAButtonLoading(false);
        toast.success("CA Document Generated Successfully");
        setCADocLink(result?.ca_doc_pdf_link);
      } else {
        setCAButtonLoading(false);
        toast.error("Failed to generate CA Document");
      }
    } catch (err: any) {
      setCAButtonLoading(false);
      toast.error(err?.detail || "Failed to generate CA Document");
    }
  };

  const generateLeegalityLinkCAdoc = async (values: any) => {
    try {
      const data = {
        user_id: selectedVal.value,
        investor_aadhar_available: String(values.isAadhaarAvailable == "yes"),
      };
      setLLButtonLoading(true);
      let result = await aifManagementManager.generateLeegalityLinkCAdoc(data);
      if (result?.leegality_url) {
        setLLButtonLoading(false);
        toast.success("Leegality Link Generated Successfully");
        setLeegalityLink(result?.leegality_url);
      } else {
        setLLButtonLoading(false);
        toast.error("Failed to generate Leegality Link");
      }
    } catch (err) {
      setLLButtonLoading(false);
      toast.error("Failed to generate Leegality Link");
    }
  };

  const sendPaymentLinkMail = async (values: any) => {
    try {
      const data = {
        user_id: selectedVal.value,
        legality_link: leegalityLink,
        pay_link: String(values.pay_link),
      };
      setSMButtonLoading(true);
      let result = await aifManagementManager.sendPaymentLinkMail(data);
      if (result?.success) {
        toast.success("Mail Sent Successfully");
      } else {
        toast.error("Failed to send mail");
      }
      setInitailValues(initialValuesForm);
      setSelectedVal({});
      setOption([]);
      setCADocLink("");
      setLeegalityLink("");
    } catch (err) {
      toast.error("Failed to send mail");
    } finally {
      setCAButtonLoading(false);
      setLLButtonLoading(false);
      setSMButtonLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={StartCaGenerationFormSchema}
      onSubmit={generateCADocument}
      enableReinitialize
    >
      {({ values, errors, setFieldValue, handleSubmit, touched }: any) => {
        return (
          <FormContainer>
            <Form>
              <Stack gap={2}>
                <LvAutoComplete
                  label="Select Investor User"
                  options={option}
                  setOptions={setOption}
                  selectedObject={selectedVal}
                  setSelectedObject={handleInvSelection}
                  onChange={handleTextChange}
                  required
                />
                {selectedVal?.value && (
                  <>
                    {selectedVal?.investment_profile && (
                      <InputField
                        label="Investment Profile"
                        fieldName="investmentProfile"
                        id="investmentProfile"
                        name="investmentProfile"
                        value={selectedVal?.investment_profile}
                        error={!!errors.investmentProfile}
                        type="text"
                        disabled
                      />
                    )}
                    <DatePicker
                      placeholder="CA Doc Sent Date"
                      alignCalenderIcon="right"
                      iconColor={getColor("primary", 700)}
                      required
                      name="date"
                      id="date"
                      onChange={(value: any) => setFieldValue("date", value)}
                      error={!!errors?.date && touched?.date}
                      value={values?.date}
                    />
                    <InputField
                      label="Onboarding Fee"
                      fieldName="onboardingFee"
                      id="onboardingFee"
                      name="onboardingFee"
                      value={values.onboardingFee}
                      error={!!errors.onboardingFee}
                      type="number"
                      required
                    />
                    <InputField
                      label="Upfront drawdown"
                      fieldName="upfrontDrawdown"
                      id="upfrontDrawdown"
                      name="upfrontDrawdown"
                      value={values.upfrontDrawdown}
                      error={!!errors.upfrontDrawdown}
                      type="number"
                      required
                    />
                    <InputField
                      label="Placement Fee"
                      fieldName="placementFee"
                      id="placementFee"
                      name="placementFee"
                      value={values.placementFee}
                      error={!!errors.placementFee}
                      type="number"
                      required
                    />
                    <RadioButtonSelect
                      formLabel="Is KYC Verified"
                      id="isKycVerified"
                      fieldName="isKycVerified"
                      formLabelStyle={{ textAlign: "left" }}
                      options={[
                        { name: "Yes", value: "yes" },
                        { name: "No", value: "no" },
                      ]}
                      value={values?.isKycVerified}
                      required
                    />
                    {values?.isKycVerified == "yes" && (
                      <InputField
                        label="KYC Number"
                        fieldName="kycNumber"
                        id="kycNumber"
                        name="kycNumber"
                        value={values?.kycNumber}
                        error={!!errors?.kycNumber}
                        type="text"
                        required={values?.isKycVerified == "yes"}
                      />
                    )}
                    <RadioButtonSelect
                      formLabel="Is KRA Verified"
                      id="isKraVerified"
                      fieldName="isKraVerified"
                      formLabelStyle={{ textAlign: "left" }}
                      options={[
                        { name: "Yes", value: "yes" },
                        { name: "No", value: "no" },
                      ]}
                      value={values?.isKraVerified}
                      required
                    />
                    {values?.isKraVerified == "yes" && (
                      <InputField
                        label="KRA Number"
                        fieldName="kraNumber"
                        id="kraNumber"
                        name="kraNumber"
                        value={values?.kraNumber}
                        error={!!errors.kraNumber}
                        type="text"
                        required={values?.isKraVerified == "yes"}
                      />
                    )}

                    <InputField
                      label="Unique ID"
                      fieldName="uniqueId"
                      id="uniqueId"
                      name="uniqueId"
                      value={selectedVal?.value}
                      error={!!errors.uniqueId}
                      type="number"
                      disabled
                    />

                    <RadioButtonSelect
                      formLabel="Is Aadhaar Available"
                      id="isAadhaarAvailable"
                      fieldName="isAadhaarAvailable"
                      formLabelStyle={{ textAlign: "left" }}
                      options={[
                        { name: "Yes", value: "yes" },
                        { name: "No", value: "no" },
                      ]}
                      value={values?.isAadhaarAvailable}
                      required
                    />
                  </>
                )}
                <Stack mt={2} direction="row" spacing={2} justifyContent="end">
                  {!caDocLink ? (
                    <ActionButton
                      onClick={handleSubmit}
                      isLoading={caButtonLoading}
                      disabled={caButtonLoading}
                    >
                      Generate CA Document
                    </ActionButton>
                  ) : (
                    <ActionButton onClick={viewFile}>View</ActionButton>
                  )}
                  {!!caDocLink && (
                    <ActionButton
                      onClick={() => generateLeegalityLinkCAdoc(values)}
                      isLoading={llButtonLoading}
                      disabled={llButtonLoading}
                    >
                      Generate Leegality Link
                    </ActionButton>
                  )}
                </Stack>
                {!!caDocLink && !!leegalityLink && (
                  <Stack gap={2}>
                    <InputField
                      label="Legality Link"
                      fieldName="legalityLink"
                      id="legalityLink"
                      name="legalityLink"
                      value={leegalityLink}
                      error={!!errors.uniqueId}
                      type="text"
                    />
                    <FormControlLabel
                      key={1}
                      control={
                        <Checkbox
                          checked={values?.pay_link}
                          onChange={(e) =>
                            setFieldValue("pay_link", e.target.checked)
                          }
                          value={values?.pay_link}
                          size="small"
                        />
                      }
                      label="Send CA DOC payment link"
                    />
                    <Stack mt={2} direction="row" justifyContent="end">
                      <ActionButton
                        onClick={() => sendPaymentLinkMail(values)}
                        isLoading={smButtonLoading}
                        disabled={smButtonLoading}
                      >
                        Send Mail
                      </ActionButton>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Form>
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default StartCaGeneration;
