import {
  getInitialScreeningDocuments,
  getStartupDetail,
  startupActions,
  initialScreeningAction,
  finalScreeningAction,
  initialScreeningMarkAsGood,
  getFinalScreeningDocuments,
  getStartupReports,
  parseReport,
  reportAction,
  getCommitmentInvestorCommunication,
  searchDealName,
  startupReportAction,
  SendInvestorCommunication,
  getStartupList,
  updateStartupData,
  cloneStartup,
  updateStartupActions,
  notifyInvestors,
} from "../services/startupServices";
import { getFormattedDate } from "../../../utils/dateUtils";
import { getFormattedData } from "../../../utils/formUtils";
import {
  keyMapping,
  tooltipText,
  visibilityOptions,
} from "../constants/StartupDetailsContant";
import { getQueryString } from "../../../utils/apiUtils";
import { isEmpty } from "../../../utils/commonUtils";
import RenderFounders from "../components/startupPanel/showDetails/RenderFounders";
import RenderShowMore, {
  RenderShowMoreHtml,
} from "../components/startupPanel/showDetails/RenderShowMore";
import DownloadButton from "../../../ui-library/buttons/Downloadbutton/DownloadButton";

type awaitingDocs = {
  serial_no: number;
  startup_name: string;
  startup_sector: string;
  applied_on: string;
  mark_as_good: any;
  referred_type_and_by: string;
  action: any;
};

type rejectedDocs = {
  serial_no: number;
  startup_name: string;
  applied_on: string;
  marked_good: any;
  referred_type_and_by: string;
  rejected_by: string;
  action: any;
};

export default class StartupManagement {
  private userData: any;

  constructor(AuthContext: any) {
    this.userData = AuthContext.authState;
  }
  private getReferredTypeAndBy(by: string, type: string) {
    return `${by ? `${by}` : ""} - ${type ? `${type}` : ""}`;
  }

  private getFormattedAwaitingDocs = (data: any[], page: number) => {
    const startId = page === 1 ? 1 : (page - 1) * 10 + 1;
    let result: awaitingDocs[] = [];
    data.forEach((item: any, idx: number) => {
      result.push({
        ...item,
        serial_no: startId + idx,
        startup_name: item?.name ?? "",
        startup_sector: item?.sector ?? "",
        applied_on: getFormattedDate(item?.applied_on) ?? "",
        referred_type_and_by: this.getReferredTypeAndBy(
          item?.referred_by,
          item?.referrer_type
        ),
        mark_as_good: item?.mark_good ?? false,
        action: "",
      });
    });
    return result;
  };

  private getFormattedRejectedDocs = (data: any[], page: number) => {
    const startId = page === 1 ? 1 : (page - 1) * 10 + 1;
    let result: rejectedDocs[] = [];
    data.forEach((item: any, idx: number) => {
      result.push({
        ...item,
        serial_no: startId + idx,
        startup_name: item?.name ?? "",
        applied_on: getFormattedDate(item?.applied_on) ?? "",
        referred_type_and_by: this.getReferredTypeAndBy(
          item?.referred_by,
          item?.referrer_type
        ),
        rejected_by: item?.action_taken_by ?? "",
        marked_good: item?.mark_good === 1,
        action: "",
      });
    });
    return result;
  };

  private getFormattedFinalScreeningDocs = (data: any[], page: number) => {
    const startId = page === 1 ? 1 : (page - 1) * 10 + 1;
    let result: any[] = [];
    data.forEach((item: any, idx: number) => {
      result.push({
        ...item,
        serial_no: startId + idx,
        startup_name: item?.name ?? "",
        startup_sector: item?.sector ?? "",
        approved_from_initial: getFormattedDate(item?.action_taken_on) ?? "",
        approved_from_initial_by: item?.action_taken_by ?? "",
        action: "",
      });
    });
    return result;
  };

  async getInitialScreeningDocs(status: string, page: number, name: string) {
    try {
      const queryString = getQueryString({
        tab: status,
        page,
        name,
      });
      let result = await getInitialScreeningDocuments(queryString);
      let formattedData =
        status === "awaiting"
          ? this.getFormattedAwaitingDocs(result?.data?.results, page)
          : this.getFormattedRejectedDocs(result?.data?.results, page);
      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getFinalScreeningDocs(
    status: string,
    page: number,

    name: string
  ) {
    try {
      const queryString = getQueryString({
        tab: status,
        page,
        name,
      });
      let result = await getFinalScreeningDocuments(queryString);
      let formattedData = this.getFormattedFinalScreeningDocs(
        result?.data?.results,
        page
      );

      return { formattedData, count: result?.data?.count };
    } catch (error) {
      throw error;
    }
  }

  async getStartupDetail(startup_id: string) {
    try {
      let result = await getStartupDetail(startup_id);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async startupActions(start_id: string, action: string, data: any = {}) {
    try {
      const formData = new FormData();
      if (data?.document_url) {
        formData.append("document_url", data?.document_url);
      }
      let result = await startupActions(start_id, action, formData);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async initialScreeningAction(startup_id: string, accept: boolean | null) {
    try {
      let data = {
        startup_id,
        accept: accept,
      };
      let result = await initialScreeningAction(data);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async finalScreeningAction(startup_id: string, accept: any, reason?: string) {
    try {
      let data = {
        startup_id,
        accept,
        content: reason,
      };
      let result = await finalScreeningAction(data);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }
  async initialScreeningMarkAsGood(startup_id: string, flag: any) {
    try {
      let data = {
        startup_id,
        mark_good: flag,
      };
      let result = await initialScreeningMarkAsGood(data);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async getStartupReports(startupId: string, page: number) {
    try {
      let result = await getStartupReports(startupId, page);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async parseReport() {
    try {
      let result = await parseReport();

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async publishReport(startupId: string) {
    try {
      let data = {
        publish: true,
      };
      let result = await reportAction(startupId, data);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async handleReportAction(reportId: string, status: string) {
    try {
      let result = await startupReportAction(reportId, status);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async SendInvestorCommunication(data: any) {
    try {
      let result = await SendInvestorCommunication(data);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async archiveReport(startupId: string) {
    try {
      let data = {
        archive: true,
      };
      let result = await reportAction(startupId, data);

      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  private getFormattedCommitmentInvestorCommunication = (
    data: any[],
    page: number
  ) => {
    let result: any[] = [];
    data.forEach((item: any, idx: number) => {
      result.push({
        ...item,
        startup_name: item?.startup_name ?? "",
        update_type: item?.update_type ?? "",
        email_subject: item?.email_subject ?? "",
        email_content: item?.email_content ?? "",
        attachements: item?.attachement ?? "",
        submitted_date: getFormattedDate(item?.created_date) ?? "",
        action: item?.publish ? "unpublish" : "publish",
      });
    });
    return result;
  };

  async getCommitmentInvestorCommunication(page: number) {
    try {
      try {
        let result: any = await getCommitmentInvestorCommunication(page);
        let formattedData = this.getFormattedCommitmentInvestorCommunication(
          result?.data?.result,
          page
        );
        return { formattedData, count: result?.data?.count };
      } catch (error) {
        throw error;
      }
    } catch (error) {
      throw error;
    }
  }

  async searchDealName(search: string) {
    try {
      const res = await searchDealName(search);
      return { data: res.data.results };
    } catch (error) {
      throw error;
    }
  }

  async getStartupList(
    page: number,
    startupId: any = null,
    schemeId: any = null,
    startupType: any = null,
    startupStatus: any = null
  ) {
    try {
      let result = await getStartupList(
        page,
        startupId,
        schemeId,
        startupType,
        startupStatus
      );
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async cloneStartup(startupId: any = null) {
    try {
      let result = await cloneStartup(startupId);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateStartupData(data: any, startupId: any, allValues?: any) {
    try {
      if (!data?.lightningDeal) {
        delete data.minCommitAmount;
        delete data.startDate;
        delete data.endDate;
      }
      const formData = new FormData();
      if (data?.pitchingSession) {
        data.pitchingSession = data.pitchingSession.map(
          (item: any, index: number) => {
            formData.append(`pitching_medias[${index}][title]`, item?.title);
            formData.append(`pitching_medias[${index}][file]`, item?.file[0]);
            if (item?.id) {
              formData.append(`pitching_medias[${index}][id]`, item?.id);
            }
          }
        );
        formData.append(`pitching_medias`, data?.pitchingSession?.length);

        delete data.pitchingSession;
      }

      if (data?.typeOfStartup) {
        if (data?.typeOfStartup === "normal_private") {
          data.privateSyndicateId = data?.startupAsNormalSyndicate?.value;
        } else if (data?.typeOfStartup === "zeno_private") {
          data.privateSyndicateId = data?.startupAsZenoSyndicate?.value;
        }
        delete data.startupAsNormalSyndicate;
        delete data.startupAsZenoSyndicate;
      } else if (data.startupAsNormalSyndicate || data.startupAsZenoSyndicate) {
        data.typeOfStartup = allValues?.typeOfStartup;
        if (allValues?.typeOfStartup === "normal_private") {
          data.privateSyndicateId = data?.startupAsNormalSyndicate?.value;
        } else if (allValues?.typeOfStartup === "zeno_private") {
          data.privateSyndicateId = data?.startupAsZenoSyndicate?.value;
        }
        delete data.startupAsNormalSyndicate;
        delete data.startupAsZenoSyndicate;
      }

      const formattedData = getFormattedData(data, keyMapping, formData);
      let result = await updateStartupData(formattedData, startupId);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateStartupActions(reportId: string, data: any) {
    try {
      let result = await updateStartupActions(reportId, data);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  async notifyInvestors(reportId: any) {
    try {
      let result = await notifyInvestors(reportId);
      return result?.data;
    } catch (error) {
      throw error;
    }
  }

  getBasicDetailsCardptions = (details: any) => {
    return [
      {
        label: "Startup Id",
        spacing: 3,
        value: details?.id ?? "-",
      },
      {
        label: "Startup Name",
        spacing: 3,
        value: details?.name ?? "-",
      },
      {
        label: "Entity Name",
        spacing: 3,
        value: details?.entity_name ?? "-",
        helpText: tooltipText.entityName,
      },
      {
        label: "Scheme Name",
        spacing: 3,
        value: details?.scheme_name ?? "-",
        helpText: tooltipText.schemeName,
      },
      {
        label: "Investment Type",
        spacing: 3,
        value: details?.investment_type
          ? details?.investment_type?.toUpperCase()
          : "-",
        helpText: tooltipText.investmentType,
      },
      {
        label: "Website",
        spacing: 3,
        value: details?.website ?? "-",
      },

      {
        label: "Sector",
        spacing: 3,
        value: !isEmpty(details?.sector) ? details?.sector : "-",
      },
      {
        label: "Location",
        spacing: 3,
        value: details?.location ?? "-",
      },
      {
        label: "Stage",
        spacing: 3,
        value: details?.stage ?? "-",
      },
      {
        label: "Pitching Founder",
        spacing: 3,
        value: !isEmpty(details?.pitching_founder)
          ? details?.pitching_founder?.name
          : "-",
        helpText: tooltipText.pitchingFounder,
      },
      {
        label: "Founders",
        spacing: 3,
        renderer: <RenderFounders details={details} />,
      },
      {
        label: "Founded",
        spacing: 3,
        value: details?.founded ?? "-",
      },
      {
        label: "Published",
        spacing: 3,
        value: details?.published ? "Yes" : "No",
        helpText: tooltipText.published,
      },
      {
        label: "Deal Dashboard Accessibility",
        spacing: 3,
        value: details?.deal_dashboard_access ? "Yes" : "No",
        helpText: tooltipText.dealDashboardAccessibility,
      },
      {
        label: "Visibility",
        spacing: 3,
        value: details?.visibility
          ? visibilityOptions?.find(
              (option) => option.value == details?.visibility
            )?.label
          : "-",
        helpText: tooltipText.visibility,
      },
      {
        label: "Super Visibility",
        spacing: 3,
        value: details?.super_visibility
          ? "Super Stealth Mode On"
          : "Super Stealth Mode Off",
        helpText: tooltipText.superVisibility,
      },
      {
        label: "Startup As Private Normal Syndicate",
        spacing: 3,
        value: details?.part_of_private_syndicate ? "Yes" : "No",
        helpText: tooltipText.startupAsPrivateNormalSyndicate,
      },
      {
        label: "Startup As Private Zeno Syndicate",
        spacing: 3,
        value: details?.is_zeno ? "Yes" : "No",
        helpText: tooltipText.startupAsPrivateZenoSyndicate,
      },

      {
        label: "Profile Created",
        spacing: 3,
        value: details?.created_at ?? "-",
        helpText: tooltipText.profileCreated,
      },
      {
        label: "Profile Updated",
        spacing: 3,
        value: details?.updated_at ?? "-",
        helpText: tooltipText.profileUpdated,
      },
    ];
  };

  getFundraiseDetailsCardoptions = (details: any) => {
    return [
      {
        label: "Fundraising Since",
        spacing: 3,
        value: details?.fund_raising_since ?? "-",
        helpText: tooltipText.fundraisingSince,
      },
      {
        label: "Funded",
        spacing: 3,
        value: !isEmpty(details?.syndicate_funded) ? "Yes" : "No",
        helpText: tooltipText.funded,
      },
      {
        label: "Syndicate Close Threshold Percent",
        spacing: 3,
        value: details?.commit_threshold
          ? `${details?.commit_threshold}%`
          : "-",
        helpText: tooltipText.syndicateCloseThresholdPercent,
      },
      {
        label: "Fund Carry Percent",
        spacing: 3,
        value: details?.fund_carry ? `${details?.fund_carry}%` : "-",
        helpText: tooltipText.fundCarryPercent,
      },
      {
        label: "Lead Carry",
        spacing: 3,
        value: details?.lead_carry ? `${details?.lead_carry}%` : "-",
        helpText: tooltipText.leadCarry,
      },
      {
        label: "Hurdle Rate",
        spacing: 3,
        value: details?.hurdle_rate ? `${details?.hurdle_rate}%` : "-",
        helpText: tooltipText.hurdleRate,
      },
      {
        label: "LV Allocation",
        spacing: 3,
        renderer: !isEmpty(details?.lv_allocated_deal_amount?.amount)
          ? `${details?.lv_allocated_deal_amount?.symbol} ${details?.lv_allocated_deal_amount?.amount} ${details?.lv_allocated_deal_amount?.short_unit}`
          : "-",
        helpText: tooltipText.lvAllocation,
      },
      {
        label: "Lightning Deal",
        spacing: 3,
        value: details?.lightning_deal ? "Yes" : "No",
        helpText: tooltipText.lightningDeal,
      },
      {
        label: "Transaction Fees Percent",
        spacing: 3,
        value: details?.transaction_fees_percentage
          ? `${details?.transaction_fees_percentage}%`
          : "-",
        helpText: tooltipText.transactionFeesPercent,
      },
      {
        label: "Transaction Fees Amount",
        spacing: 3,
        value: details?.transaction_fees_amount ?? "-",
        helpText: tooltipText.transactionFeesAmount,
      },
      {
        label: "Minimum Commitment",
        spacing: 3,
        renderer: !isEmpty(details?.min_commitment?.amount)
          ? `${details?.min_commitment?.symbol} ${details?.min_commitment?.amount} ${details?.min_commitment?.short_unit}`
          : "-",
        helpText: tooltipText.minimumCommitment,
      },
      {
        label: "MoU",
        spacing: 3,
        renderer: details?.mou_download_url ? (
          <DownloadButton url={details?.mou_download_url} />
        ) : (
          "-"
        ),
        helpText: tooltipText.mou,
      },
      {
        label: "Write Up By Startup",
        spacing: 3,
        renderer: (
          <RenderShowMoreHtml content={details?.startup_blurb_uploaded} />
        ),
        helpText: tooltipText.writeUp,
      },

      {
        label: "Lead Investors",
        spacing: 3,
        renderer: (
          <RenderShowMore
            array={details?.lead_investors}
            labelKey="investor_name"
          />
        ),
        helpText: tooltipText.leadInvestors,
      },

      {
        label: "Lead Investor Commitment Switch",
        spacing: 3,
        value: details?.lead_show_commitment ? "Shown" : "Hidden",
        helpText: tooltipText.showLeadInvestorCommitmentSwitch,
      },
      {
        label: "Startup DD",
        spacing: 3,
        value: details?.dd ? "Yes" : "No",
        helpText: tooltipText.startupDD,
      },
      {
        label: "Pro Rata Rights",
        spacing: 3,
        value: details?.prr ? "Yes" : "No",
        helpText: tooltipText.proRataRights,
      },
      {
        label: "Switch to toggle Off Scheme Consent",
        spacing: 3,
        value: details?.scheme_consent_switch ? "Yes" : "No",
        helpText: tooltipText.switchToToggleSchemeConsent,
      },
      {
        label: "Startup POC",
        spacing: 3,
        value: details?.startup_poc ?? "-",
        helpText: tooltipText.startupPoc,
      },
      {
        label: "Pitching Session",
        spacing: 3,
        renderer: (
          <RenderShowMore array={details?.startup_medias} labelKey="title" />
        ),
        helpText: tooltipText.pitchingSession,
      },
      {
        label: "Syndicate Started",
        spacing: 3,
        value: details?.syndicate_start ?? "-",
        helpText: tooltipText.syndicateStarted,
      },
      {
        label: "Syndicate Closed",
        spacing: 3,
        value: details?.syndicate_closed ?? "-",
        helpText: tooltipText.syndicateClosed,
      },
      {
        label: "Syndicate Funded",
        spacing: 3,
        value: details?.syndicate_funded ?? "-",
        helpText: tooltipText.syndicateFunded,
      },
    ];
  };
}
