export const startupReportsBreadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Startup Management",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Startup Reports",
    navigationButton: false,
    helpText:
      'After Funding, the startups need to submit a quarterly report that is shown to the investors in the portfolio page. The quarterly report contains financial metrics (MIS) as well as business metrics information. The report submitted by founders is processed using LLM engine (currently onfinance) to show brief summary to the invetors in these sections - Detailed Summary of Operational Metrics, Financial Health Report, "Key Updates, Fundraising Status, and Investor Assistance Requirements for the Period".',
  },
};
