import {
  AttachEmailOutlined,
  AttachFile,
  DescriptionOutlined,
  PictureAsPdf,
  TableChartOutlined,
  TextSnippetOutlined,
  WysiwygOutlined,
} from "@mui/icons-material";

export const isEmpty = (value: any, falseStrings = [""]) =>
  value instanceof File
    ? false
    : typeof value === "undefined" ||
      value === null ||
      Number.isNaN(value) ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0) ||
      (typeof value === "string" && falseStrings.includes(value));

export const redirectToLetsventure = (
  url: string,
  openInNewTab: Boolean = true,
  addAccessToken: Boolean = true,
  addAccessKeyword: Boolean = false,
  accessToken: any = null
) => {
  const BASE_URL = process.env.REACT_APP_LETSVENTURE_BASE_URL;
  const URL = `${BASE_URL}${url}?${addAccessKeyword ? `access=true&` : ""}${
    addAccessToken
      ? `access_token=${accessToken ?? localStorage.getItem("token")}`
      : ""
  }`;
  if (process.env.REACT_APP_ENV === "development") {
    navigator.clipboard.writeText(URL);
  }
  if (openInNewTab) {
    window.open(URL, "_blank");
  } else {
    window.location.href = URL;
  }
};

export const FileTypeIcon = ({ fileType }: { fileType: string }) => {
  switch (fileType) {
    case "pdf":
      return (
        <i className="fa-solid fa-file-pdf" style={{ color: "#DD2428" }}></i>
      );
    case "pptx":
      return (
        <i className="fa-solid fa-file-ppt" style={{ color: "#DD2428" }}></i>
      );
    case "docx":
      return (
        <i className="fa-solid fa-file-doc" style={{ color: "#297FCF" }}></i>
      );
    case "xlsx":
      return (
        <i className="fa-solid fa-file-xls" style={{ color: "#0E783F" }}></i>
      );
    case "txt":
      return (
        <i className="fa-solid fa-file-lines" style={{ color: "#98A6A6" }}></i>
      );
    default:
      return (
        <i
          className="fa-solid fa-file-fragment"
          style={{ color: "#297FCF" }}
        ></i>
      );
  }
};
