import React from "react";
import { CommonModal } from "../../../../ui-library/modals/common-modal";
import { FC } from "react";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import UserManagementManager from "../../managers/userManagementManager";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { ApplicationLoader } from "../../../../ui-library/loaders/application-loader";
import { NoDataAvailable } from "../../../../ui-library/error/NoDataAvailable";
import { IconConstant } from "../../../../constants/IconConstants";
import RichTextEditor from "../../../../ui-library/form-elements/RichTextEditor";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { RenderShowMoreHtml } from "../../../startupManagement/components/startupPanel/showDetails/RenderShowMore";

type NoteModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
  type: string;
};

let columns = [
  {
    field: "note",
    headerName: "Note",
    headerAlign: "left",
    align: "left",
    flex: 3,
    renderCell: (params: any) => (
      <RenderShowMoreHtml content={params.row?.note} contentLength={40} />
    ),
  },
  {
    field: "added_by",
    headerName: "Note added by",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },

  {
    field: "created_at",
    headerAlign: "right",
    align: "right",
    headerName: "Date of note",
    flex: 1,
  },
];

const NoteModal: FC<NoteModalType> = (props) => {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);

  const { isOpen, handleClose, selected, type } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);
  const [editorState, setEditorState] = useState("");
  const [allNote, setAllNote] = useState<any>([]);

  const getNoteforUser = async () => {
    try {
      setIsLoading(true);
      const result = await userManagementManager.getNoteForUser(
        selected.id,
        type
      );
      setAllNote(result?.data ?? []);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };
  const handleAddNote = async () => {
    try {
      setAdding(true);

      let result = await userManagementManager.addNoteForUser(
        selected.id,
        type,
        editorState
      );
      if (result?.data) toast.success("note added !");
      setEditorState("");

      setAdding(false);
      setOpenEditor(false);
      getNoteforUser();
    } catch (err: any) {
      setAdding(false);
      toast.error(err);
    }
  };

  useEffect(() => {
    if (selected) {
      getNoteforUser();
    }
  }, [selected]);

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleClose}
      title={`Notes for ${selected?.name} - Awaiting Onboarding`}
      buttonStyles={{ width: "min-width", padding: "0 15px" }}
      closeIcon
      hideButtons
    >
      <Box width="55vw" minHeight={300}>
        {openEditor ? (
          <Box>
            <RichTextEditor
              editorState={editorState}
              setEditorState={setEditorState}
            />
            <br />
            <Box display="flex" gap={2}>
              <OutlinedButton onClick={() => setOpenEditor(false)}>
                Cancel
              </OutlinedButton>
              <ActionButton
                onClick={handleAddNote}
                isLoading={adding}
                disabled={adding || !editorState}
              >
                Add
              </ActionButton>
            </Box>
          </Box>
        ) : null}

        <Box mt={1}>
          {isLoading ? (
            <ApplicationLoader />
          ) : (
            <>
              {!isLoading && allNote?.length ? (
                <>
                  {!openEditor && (
                    <ActionButton onClick={() => setOpenEditor(true)}>
                      Add Note
                    </ActionButton>
                  )}
                  <br />
                  <br />
                  <LvComplexTable
                    rows={allNote}
                    columns={columns}
                    rowHeight={40}
                    headerHeight={40}
                    filterMode="server"
                    enableAdvancedFiltering
                    isUnFilteredDataPresent={!isEmpty(allNote)}
                    enableAutoHeight={true}
                    hideToobar
                  />
                </>
              ) : (
                !openEditor && (
                  <NoDataAvailable
                    title="No Notes Available"
                    subTitle={"Try to add some notes"}
                    image={IconConstant.DATA_NOT_FOUND_ICON}
                    buttonComponent={
                      <ActionButton onClick={() => setOpenEditor(true)}>
                        Add Note
                      </ActionButton>
                    }
                  />
                )
              )}{" "}
            </>
          )}
        </Box>
      </Box>
    </CommonModal>
  );
};

export default NoteModal;
