import { tooltipText } from "./common";

export const caDocStatus = [
  { label: "Select Status" },
  {
    label: "Sent",
    value: "sent",
  },
  {
    label: "Not Sent",
    value: "notsent",
  },
];

export const faasNonFaasTypes = [
  { label: "Select Type" },
  {
    label: "FaaS",
    value: "faas",
  },
  {
    label: "NON-FaaS",
    value: "non_fass",
  },
  {
    label: "Institutional",
    value: "institutional",
  },
];

export const BreadcrumbUserPanel = (UserType: String = "User") => {
  const capitalizedUserType =
    UserType.charAt(0).toUpperCase() + UserType.slice(1).toLowerCase();
  return {
    stepKey: 1,
    steps: [
      {
        title: "User Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: `${capitalizedUserType} List`,
      navigationButton: false,
    },
  };
};

export const BreadcrumbShowUser = {
  stepKey: 2,
  steps: [
    {
      title: "User Management",
      titleDisplay: false,
    },
    {
      title: "User Detail",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "User Detail",
    navigationButton: true,
  },
};

export const BreadCrumbDataPvtInvestorInformation = {
  stepKey: 1,
  steps: [
    {
      title: "User Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Investor Informations",
    navigationButton: false,
    helpText: tooltipText.investorInformation,
  },
};

export const BreadCrumbDataNomineeInformation = {
  stepKey: 1,
  steps: [
    {
      title: "User Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Nominee Information",
    navigationButton: false,
    helpText: tooltipText.nomineeInformation,
  },
};

//============================

export const BreadCrumbDataDematInformation = {
  stepKey: 1,
  steps: [
    {
      title: "User Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Demat Information",
    navigationButton: false,
    helpText: tooltipText.deamtInformation,
  },
};

export const DematStatusType = {
  WITH_DEMAT_ACCOUNT: "with_demat",
  WITHOUT_DEMAT_ACCOUNT: "without_demat",
};

export const DematStatusTabs: any = [
  {
    name: "WITH DEMAT ACCOUNT",
    value: DematStatusType.WITH_DEMAT_ACCOUNT,
  },
  {
    name: "WITHOUT DEMAT ACCOUNT",
    value: DematStatusType.WITHOUT_DEMAT_ACCOUNT,
  },
];
