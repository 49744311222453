import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationLoader } from "../../../ui-library/loaders/application-loader";
import { AuthContext } from "../../../contexts/authContext";
import { toast } from "react-toastify";

const UserLoginRedirection = () => {
  const { isAuthLoading, authState } = useContext(AuthContext);
  const { isAuthenticated = false } = useAuth0();

  const navigate = useNavigate();

  const redirectUserTo = async () => {
    try {
      if (isAuthenticated && authState.user) {
        navigate("/dashboard");
      }
    } catch (error: any) {
      toast.error(error?.details || "Something went wrong");
    }
  };

  useEffect(() => {
    if (authState.token) {
      redirectUserTo();
    }
  }, [authState.token, isAuthenticated]);

  if (isAuthLoading) {
    return <ApplicationLoader fullScreen />;
  }
  return <></>;
};

export default UserLoginRedirection;
