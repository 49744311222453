export const FinalScreeningTabs: any = [
  {
    name: "AWAITING",
    value: "awaiting",
  },
  {
    name: "APPROVED",
    value: "approved",
  },
  {
    name: "REJECTED",
    value: "rejected",
  },
];

export const SortByDocOptions = [
  { label: "MoU", value: "mou" },
  { label: "Startup Blurb", value: "write_up" },
];

export const DealTypeOptions = [
  { label: "Faas", value: "faas" },
  { label: "Non-Faas", value: "non_faas" },
];

export const FinalScreeningBreadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Startup Management",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Final Screening",
    navigationButton: false,
  },
};
