import { FC, useContext, useState } from "react";
import {
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";
import { InputField } from "../../../../../ui-library/form-elements/CustomTextField";
import { ToggleSwitch } from "../../../../../ui-library/buttons/ToggleSwitch";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "../../../../../ui-library/navs/breadcrumb";
import { FormContainer } from "../../../../../ui-library/form-elements/FormContainer";
import { TimePeriodDropdown } from "../../../../../ui-library/dropdowns/time-period-dropdown";
import { getColor } from "../../../../../ui-library/Styles/Colors";
import { AuthContext } from "../../../../../contexts/authContext";
import StartupManagementManager from "../../../managers/startupManager";
import { toast } from "react-toastify";
import { getChangedValues } from "../../../../../utils/formUtils";
import LeadInvestorList from "./lead-investor-list/LeadInvestorsList";
import * as Yup from "yup";
import { EditFundraiseDetailsBreadCrumbData } from "../../../constants/StartupDetailsContant";
import { DatePicker } from "../../../../../ui-library/form-elements/DatePicker";
import PitchingSessionList from "./pitching-session-list/PitchingSessionList";

type FundraiseDetailsEditType = {};

const FundraiseDetailsEdit: FC<FundraiseDetailsEditType> = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const state = location.state;

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const startupManagementManager = new StartupManagementManager(authContext);

  const startupDetails = state?.startupDetails ?? {};
  const lvAllocation = startupDetails?.lv_allocated_deal_amount;
  const minCommitment = startupDetails?.min_commitment;
  const lvAllocationUnit =
    lvAllocation && lvAllocation.short_unit === "Cr"
      ? { label: "Crores", value: 10000000 }
      : { label: "Lakhs", value: 100000 };
  const minCommitmentUnit =
    minCommitment && minCommitment.short_unit === "Cr"
      ? { label: "Crores", value: 10000000 }
      : { label: "Lakhs", value: 100000 };
  const initialValues = {
    syndicateCloseThresholdPercent: startupDetails?.commit_threshold ?? "",
    fundCarryPercent: startupDetails?.fund_carry ?? "",
    leadCarryPercent: startupDetails?.lead_carry ?? "",
    leadInvestors: startupDetails?.lead_investors ?? [],
    hurdleRate: startupDetails?.hurdle_rate ?? "",
    lvAllocation: lvAllocation?.amount ?? "",
    lvAllocationUnit: lvAllocationUnit,
    schemeConsentToggle: startupDetails?.scheme_consent_switch ?? false,
    transactionFeesPercent: startupDetails?.transaction_fees_percentage ?? "",
    defaultTransactionFee: startupDetails?.default_transaction_fee ?? "",
    transactionFeesAmount: startupDetails?.transaction_fees_amount ?? "",
    minCommitmentUnit: minCommitmentUnit,
    minCommitment: minCommitment?.amount ?? "",

    pitchingSession: startupDetails?.startup_medias?.length
      ? startupDetails?.startup_medias?.map((item: any) => ({
          title: item?.title,
          file: item?.file ? [{ path: item?.file }] : [],
          id: item?.id,
        }))
      : [],
    showCommitmentSwitch: startupDetails?.lead_show_commitment ?? false,
    lightningDeal: startupDetails?.lightning_deal ?? false,
    minCommitAmount:
      startupDetails?.lightning_deal_conditions?.min_commit_amount ?? "",
    startDate: startupDetails?.lightning_deal_conditions?.start_date
      ? new Date(startupDetails?.lightning_deal_conditions?.start_date)
      : "",
    endDate: startupDetails?.lightning_deal_conditions?.end_date
      ? new Date(startupDetails?.lightning_deal_conditions?.end_date)
      : "",
    startupDD: startupDetails?.dd ?? false,
    proRataRights: startupDetails?.prr ?? false,
  };

  const validationSchema = Yup.object().shape({
    minCommitAmount: Yup.number().when("lightningDeal", {
      is: true,
      then: (schema) =>
        schema
          .required("Field is required")
          .min(0, "Above 0 L is required")
          .max(minCommitment.amount, `Max ${minCommitment.amount} L allowed`),
      otherwise: (schema) => schema,
    }),
    startDate: Yup.date().when("lightningDeal", {
      is: true,
      then: (schema) => schema.required("Field is required"),
      otherwise: (schema) => schema,
    }),
    endDate: Yup.date().when("lightningDeal", {
      is: true,
      then: (schema) =>
        schema
          .required("Field is required and should be greater than start date")
          .min(
            Yup.ref("startDate"),
            "End date should be greater than start date"
          ),
      otherwise: (schema) => schema,
    }),
    leadInvestors: Yup.array().test(
      "my_lead_carry_test",
      (value: any, validationContext) => {
        const total_carry = value.reduce(
          (sum: any, item: any) => sum + Number(item?.lead_carry ?? 0),
          0.0
        );
        const totalLeadCarryPercent =
          validationContext?.parent?.leadCarryPercent ?? 0;

        const checkLeadAllocation = () => {
          let msg = "";
          if (total_carry > totalLeadCarryPercent) {
            msg = "Lead Carry is exceeding total lead carry";
            return validationContext.createError({ message: msg });
          }
          return true;
        };

        const checkResult = checkLeadAllocation();
        return checkResult;
      }
    ),
  });

  let BreadCrumbData: any = EditFundraiseDetailsBreadCrumbData;
  BreadCrumbData.pageHeader["navigationFunction"] = () => {
    navigate(`/startup/startup-panel/${startupDetails.id}/`, {
      state: { selectedTab: "fundraise_details" },
    });
  };
  BreadCrumbData.pageHeader["header"] = startupDetails?.name;

  const handleStartupDetailsSubmit = async (values: any) => {
    try {
      setFormSubmitting(true);
      const newValues = getChangedValues(values, initialValues);
      await startupManagementManager.updateStartupData(
        newValues,
        startupDetails.id
      );
      setFormSubmitting(false);
      toast.success("Startup details updated successfully");
      setIsConfirmationModalOpen(false);
      navigate(`/startup/startup-panel/${startupDetails.id}/`, {
        state: { selectedTab: "fundraise_details" },
      });
    } catch (error: any) {
      toast.error(
        error?.message || "Error while submitting the startup details"
      );
      setFormSubmitting(false);
    }
  };

  return (
    <Stack>
      <Grid container>
        <Grid item xs={5}>
          <Breadcrumb
            steps={BreadCrumbData?.steps}
            pageHeader={BreadCrumbData.pageHeader}
            stepKey={BreadCrumbData.stepKey}
            breadcrumbSeparator={true}
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={() => setIsConfirmationModalOpen(true)}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          setFieldValue,
          handleSubmit,
          touched,
          dirty,
          validateForm,
          setTouched,
        }) => {
          return (
            <FormContainer>
              <Stack gap={2} mt={2}>
                <InputField
                  label="Syndicate Close Threshold Percent"
                  fieldName="syndicateCloseThresholdPercent"
                  id="syndicateCloseThresholdPercent"
                  name="syndicateCloseThresholdPercent"
                  value={values.syndicateCloseThresholdPercent}
                  error={!!errors.syndicateCloseThresholdPercent}
                  touched={touched.syndicateCloseThresholdPercent}
                  type="number"
                  required
                />
                <InputField
                  label="Fund Carry Percent"
                  fieldName="fundCarryPercent"
                  id="fundCarryPercent"
                  name="fundCarryPercent"
                  value={values.fundCarryPercent}
                  error={!!errors.fundCarryPercent}
                  type="number"
                  required
                />
                <InputField
                  label="Lead Carry Percent"
                  fieldName="leadCarryPercent"
                  id="leadCarryPercent"
                  name="leadCarryPercent"
                  value={values.leadCarryPercent}
                  error={!!errors.leadCarryPercent}
                  type="number"
                  required
                />
                {values?.leadInvestors?.length ? (
                  <>
                    <Divider />

                    <Stack sx={{ p: 2, background: "#F1F4F8" }}>
                      <LeadInvestorList
                        isMandatory
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        labelName="leadInvestors"
                      />
                    </Stack>
                    <Divider />
                  </>
                ) : null}
                <InputField
                  label="Hurdle Rate"
                  fieldName="hurdleRate"
                  id="hurdleRate"
                  name="hurdleRate"
                  value={values.hurdleRate}
                  error={!!errors.hurdleRate}
                  type="number"
                  required
                />
                <InputField
                  label="Transaction Fees Percent"
                  fieldName="transactionFeesPercent"
                  id="transactionFeesPercent"
                  name="transactionFeesPercent"
                  value={values.transactionFeesPercent}
                  error={!!errors.transactionFeesPercent}
                  type="number"
                  required
                />
                <Stack alignItems="start" mb={1}>
                  <ToggleSwitch
                    switchLabel="Override Transaction Fees for all (Mandatory to be checked for changes)"
                    showYesOrNo
                    switchLabelPositon="start"
                    defaultChecked={values?.defaultTransactionFee}
                    labelSwitchDistance="20px"
                    onSwitchToggle={(value: any) => {
                      setFieldValue("defaultTransactionFee", value);
                    }}
                  />
                </Stack>

                <InputField
                  label="Transaction Fees Amount"
                  fieldName="transactionFeesAmount"
                  id="transactionFeesAmount"
                  name="transactionFeesAmount"
                  value={values.transactionFeesAmount}
                  error={!!errors.transactionFeesAmount}
                  type="number"
                  required
                />

                <TimePeriodDropdown
                  coreDropdownProps={{
                    label: "",
                    options: [{ label: "Lakhs", value: 100000 }],
                    disabled: true,
                    defaultValue: { label: "Lakhs", value: 100000 },
                    value: values.lvAllocationUnit,
                    id: "lvAllocationUnit",
                    name: "lvAllocationUnit",
                    hideEndAdornment: true,
                  }}
                  customTextFieldProps={{
                    id: "lvAllocation",
                    name: "lvAllocation",
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography color={getColor("primary", 700)}>
                            ₹
                          </Typography>
                        </InputAdornment>
                      ),
                    },
                    value: values.lvAllocation,
                    label: "LV Allocation",
                  }}
                  dropdownWidth="120px"
                  error={errors?.lvAllocation}
                  touched={touched?.lvAllocation}
                  labelBackgroundColor="#F6F9FC"
                />

                <TimePeriodDropdown
                  coreDropdownProps={{
                    label: "",
                    options: [{ label: "Lakhs", value: 100000 }],
                    disabled: true,
                    defaultValue: { label: "Lakhs", value: 100000 },
                    value: values.minCommitmentUnit,
                    id: "minCommitmentUnit",
                    name: "minCommitmentUnit",
                    hideEndAdornment: true,
                  }}
                  customTextFieldProps={{
                    id: "minCommitment",
                    name: "minCommitment",
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography color={getColor("primary", 700)}>
                            ₹
                          </Typography>
                        </InputAdornment>
                      ),
                    },
                    value: values.minCommitment,
                    label: "Minimum Commitment",
                  }}
                  dropdownWidth="120px"
                  error={errors?.minCommitment}
                  touched={touched?.minCommitment}
                  labelBackgroundColor="#F6F9FC"
                />

                {/* <LvAutoComplete
                  selectedObject={values?.mailerList}
                  setSelectedObject={(value: any) =>
                    setFieldValue("mailerList", value)
                  }
                  options={mailerListOptions}
                  setOptions={setMailerListOptions}
                  label="Add mailer list to allow list"
                  required
                  multiple
                /> */}

                <Divider />

                <Stack sx={{ p: 2, background: "#F1F4F8" }}>
                  <PitchingSessionList
                    isMandatory
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    labelName="pitchingSession"
                    user="pitchingSession"
                    carryError
                    innerText="Add Pitching Session"
                    removeIcon
                    notes="Note: You can upload video (Format: MP4, WebM, OGG)/audio(Format: MP3, WAV, OGG) file (max size 250 MB)"
                  />
                </Stack>

                <Divider />

                <Stack alignItems="start">
                  <ToggleSwitch
                    switchLabel="Switch to toggle Off Scheme Consent"
                    showYesOrNo
                    switchLabelPositon="start"
                    defaultChecked={values?.schemeConsentToggle}
                    labelSwitchDistance="20px"
                    onSwitchToggle={(value: any) => {
                      setFieldValue("schemeConsentToggle", value);
                    }}
                  />
                </Stack>

                {values?.leadInvestors?.length ? (
                  <Stack alignItems="start">
                    <ToggleSwitch
                      switchLabel="Show Lead Investor Commitment Switch"
                      showYesOrNo
                      switchLabelPositon="start"
                      defaultChecked={values?.showCommitmentSwitch}
                      labelSwitchDistance="20px"
                      onSwitchToggle={(value: any) => {
                        setFieldValue("showCommitmentSwitch", value);
                      }}
                    />
                  </Stack>
                ) : null}
                <Stack alignItems="start">
                  <ToggleSwitch
                    switchLabel="Startup DD"
                    showYesOrNo
                    switchLabelPositon="start"
                    defaultChecked={values?.startupDD}
                    labelSwitchDistance="20px"
                    onSwitchToggle={(value: any) => {
                      setFieldValue("startupDD", value);
                    }}
                  />
                </Stack>

                <Stack alignItems="start">
                  <ToggleSwitch
                    switchLabel="Pro Rata Rights"
                    showYesOrNo
                    switchLabelPositon="start"
                    defaultChecked={values?.proRataRights}
                    labelSwitchDistance="20px"
                    onSwitchToggle={(value: any) => {
                      setFieldValue("proRataRights", value);
                    }}
                  />
                </Stack>

                <Stack
                  sx={{
                    p: values?.lightningDeal ? 2 : 0,
                    background: values?.lightningDeal ? "#F1F4F8" : "inherit",
                  }}
                  gap={2}
                >
                  <Stack alignItems="start">
                    <ToggleSwitch
                      switchLabel="Lightning Deal"
                      showYesOrNo
                      switchLabelPositon="start"
                      defaultChecked={values?.lightningDeal}
                      labelSwitchDistance="20px"
                      onSwitchToggle={(value: any) => {
                        setFieldValue("lightningDeal", value);
                      }}
                    />
                  </Stack>

                  {values?.lightningDeal ? (
                    <Stack gap={2} sx={{ animation: "fadeIn 0.5s" }}>
                      <TimePeriodDropdown
                        coreDropdownProps={{
                          label: "",
                          options: [{ label: "Lakhs", value: 100000 }],
                          disabled: true,
                          defaultValue: { label: "Lakhs", value: 100000 },
                          value: values.minCommitmentUnit,
                          id: "minCommitmentUnit",
                          name: "minCommitmentUnit",
                          hideEndAdornment: true,
                        }}
                        customTextFieldProps={{
                          id: "minCommitAmount",
                          name: "minCommitAmount",
                          InputProps: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography
                                  color={
                                    errors?.minCommitAmount &&
                                    touched?.minCommitAmount
                                      ? getColor("error", 1000)
                                      : getColor("primary", 700)
                                  }
                                >
                                  ₹
                                </Typography>
                              </InputAdornment>
                            ),
                          },
                          value: values.minCommitAmount,
                          label: "Minimum Commitment Amount",
                          type: "number",
                          disabled: startupDetails?.lightning_deal,
                        }}
                        dropdownWidth="120px"
                        id="minCommitAmount"
                        name="minCommitAmount"
                        fieldName="minCommitAmount"
                        error={errors?.minCommitAmount}
                        touched={touched?.minCommitAmount}
                        labelBackgroundColor="#F1F4F8"
                      />

                      <DatePicker
                        placeholder="Start Date"
                        id="startDate"
                        name="startDate"
                        fieldName="startDate"
                        value={values?.startDate}
                        onChange={(value: any) =>
                          setFieldValue("startDate", value)
                        }
                        required
                        alignCalenderIcon="right"
                        error={
                          errors.startDate && touched.startDate
                            ? errors.startDate
                            : ""
                        }
                        touched={touched}
                        disabled={startupDetails?.lightning_deal}
                      />
                      <DatePicker
                        placeholder="End Date"
                        id="endDate"
                        name="endDate"
                        fieldName="endDate"
                        value={values?.endDate}
                        onChange={(value: any) =>
                          setFieldValue("endDate", value)
                        }
                        required
                        alignCalenderIcon="right"
                        error={
                          errors.endDate && touched.endDate
                            ? errors.endDate
                            : ""
                        }
                        touched={touched}
                        disabled={startupDetails?.lightning_deal}
                      />
                    </Stack>
                  ) : null}
                </Stack>

                <Stack direction="row" justifyContent="end" mt={2}>
                  <ActionButton
                    disabled={!dirty}
                    onClick={(e: any) => {
                      validateForm().then((errors: any) => {
                        setTouched(errors);
                      });

                      e?.preventDefault();
                      handleSubmit();
                    }}
                  >
                    Submit
                  </ActionButton>
                </Stack>
              </Stack>
              <CommonModal
                isOpen={isConfirmationModalOpen}
                handleClose={() => setIsConfirmationModalOpen(false)}
                handleSubmit={() => handleStartupDetailsSubmit(values)}
                title="Are you sure?"
                submitText="Submit"
                buttonStyles={{ width: "100%" }}
                closeIcon
                hideCancelButton
                isLoading={formSubmitting}
                disableSubmitButton={formSubmitting}
              >
                <Stack>Are you sure you want to submit the changes?</Stack>
              </CommonModal>
            </FormContainer>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default FundraiseDetailsEdit;
