import { Box, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { API_STATUS } from "../../../../constants/commonConstants";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { AuthContext } from "../../../../contexts/authContext";
import { useContext } from "react";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import StartupManagement from "../../managers/startupManager";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import {
  STARTUP_STATUS,
  STARTUP_TYPE,
} from "../../constants/StartupDetailsContant";
import { CoreDropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import { tooltipText } from "../../../dashboard/constants/dashboardConstants";

export default function StartupTable() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const startupManagement = new StartupManagement(authContext);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [startupOptions, setStartupOptions] = useState<any>([]);
  const [selectedStartup, setSelectedStartup] = useState<any>(null);
  const [startupSchemeOptions, setStartupSchemeOptions] = useState<any>([]);
  const [selectedStartupScheme, setSelectedStartupScheme] = useState<any>(null);
  const [selectedStartupType, setSelectedStartupType] = useState(
    location?.state?.startupType ?? STARTUP_TYPE[0].value
  );
  const [selectedStartupStatus, setSelectedStartupStatus] = useState(
    location?.state?.startupStatus ?? STARTUP_STATUS[0].value
  );

  let columns = [
    {
      field: "startup_id",
      headerName: "Startup ID",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "startup_name",
      headerName: "Startup Name",
      minWidth: 150,
      headerAlign: "left",
      renderCell: (params: any) => {
        return (
          <Link
            to={`/startup/startup-panel/${params.row.startup_id}`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.startup_name}
          </Link>
        );
      },
    },
    {
      field: "scheme_name",
      headerName: "Scheme Name",
      minWidth: 200,
      headerAlign: "left",
      renderCell: (params: any) => params.row.scheme_name ?? "-",
      helpText: tooltipText.schemeName,
    },
    {
      field: "website",
      headerName: "Website",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "stage",
      headerName: "Stage",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "is_faas",
      headerName: "Is FAAS?",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "startup_status",
      headerName: "Startup Status",
      minWidth: 150,
      headerAlign: "left",
      renderCell: (params: any) =>
        params.row.startup_status === "Syndicating"
          ? "Live"
          : params.row.startup_status ?? "-",
    },
    {
      field: "published",
      headerName: "Published",
      minWidth: 150,
      headerAlign: "left",
    },
    // {
    //   field: "fund_raising_since",
    //   headerName: "Fund Raising Since",
    //   width: 180,
    //   headerAlign: "left",
    // },
    // {
    //   field: "total_ask",
    //   headerName: "Total Ask",
    //   width: 150,
    //   headerAlign: "left",
    //   renderCell: (params: any) =>
    //     params?.row?.total_ask?.amount ? `${params?.row?.total_ask?.symbol} ${params?.row?.total_ask?.amount} ${params?.row?.total_ask?.unit}` : "-",
    // },
    // {
    //   field: "total_raised",
    //   headerName: "Total Raised",
    //   width: 150,
    //   headerAlign: "left",
    //   renderCell: (params: any) =>
    //     params?.row?.total_raised?.amount ? `${params?.row?.total_raised?.symbol} ${params?.row?.total_raised?.amount} ${params?.row?.total_raised?.unit}` : "-",
    // },
    // {
    //   field: "lv_ask",
    //   headerName: "LV Ask",
    //   width: 150,
    //   headerAlign: "left",
    //   renderCell: (params: any) =>
    //     params?.row?.lv_ask?.amount ? `${params?.row?.lv_ask?.symbol} ${params?.row?.lv_ask?.amount} ${params?.row?.lv_ask?.unit}` : "-",
    // },
    // {
    //   field: "lv_raised",
    //   headerName: "LV Raised",
    //   width: 150,
    //   headerAlign: "left",
    //   renderCell: (params: any) =>
    //     params?.row?.lv_raised?.amount ? `${params?.row?.lv_raised?.symbol} ${params?.row?.lv_raised?.amount} ${params?.row?.lv_raised?.unit}` : "-",
    // },
    // {
    //   field: "external_ask",
    //   headerName: "External Ask",
    //   width: 150,
    //   headerAlign: "left",
    //   renderCell: (params: any) =>
    //     params?.row?.external_ask?.amount ? `${params?.row?.external_ask?.symbol} ${params?.row?.external_ask?.amount} ${params?.row?.external_ask?.unit}` : "-",
    // },
    // {
    //   field: "external_raised",
    //   headerName: "External Raised",
    //   width: 150,
    //   headerAlign: "left",
    //   renderCell: (params: any) =>
    //    params?.row?.external_raised?.amount ? `${params?.row?.external_raised?.symbol} ${params?.row?.external_raised?.amount} ${params?.row?.external_raised?.unit}` : "-",
    // },
    {
      field: "created_at",
      headerName: "Created Date",
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "updated_at",
      headerName: "Last Updated Date",
      minWidth: 150,
      headerAlign: "left",
    },
  ];

  const handleStartupSearch = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData({
        search: value,
        type: "admin_search_startups",
      });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setStartupOptions(result);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const handleSchemeSearch = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData({
        search: value,
        type: "admin_search_startup_scheme",
      });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setStartupSchemeOptions(result);
    } catch (err: any) {
      toast.error(err);
    }
  };

  async function getStartupList(
    startupId: any = null,
    schemeId: any = null,
    startupType: any = null,
    startupStatus: any = null
  ) {
    try {
      setApiStatus(API_STATUS.LOADING);
      let result = await startupManagement.getStartupList(
        page,
        startupId,
        schemeId,
        startupType,
        startupStatus
      );
      setCount(result.count);
      setFormattedData(result.results);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error("Error while fetching startup list");
      setApiStatus(API_STATUS.ERROR);
    }
  }

  useEffect(() => {
    getStartupList(
      selectedStartup?.value,
      selectedStartupScheme?.value,
      selectedStartupType,
      selectedStartupStatus
    );
  }, [
    page,
    selectedStartup,
    selectedStartupScheme,
    selectedStartupType,
    selectedStartupStatus,
  ]);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const handleStartupStatusOptions = (status: string) => {
    setSelectedStartupStatus(status);
  };

  const handleStartupTypeOptions = (status: string) => {
    setSelectedStartupType(status);
  };

  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "Startup Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: "Startup List",
      navigationButton: false,
    },
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <Box width="23vw">
            <LvAutoComplete
              label={"Search Startup"}
              options={startupOptions}
              setOptions={setStartupOptions}
              selectedObject={selectedStartup}
              setSelectedObject={setSelectedStartup}
              onSelect={() => {
                setStartupOptions([]);
                setSelectedStartupScheme(null);
                setPage(1);
              }}
              onChange={handleStartupSearch}
              helperText="startups where name CONTAINS search text are displayed"
            />
          </Box>
          <Box width="23vw">
            <LvAutoComplete
              label={"Search Startup's Scheme"}
              options={startupSchemeOptions}
              setOptions={setStartupSchemeOptions}
              selectedObject={selectedStartupScheme}
              setSelectedObject={setSelectedStartupScheme}
              onSelect={() => {
                setStartupSchemeOptions([]);
                setSelectedStartup(null);
                setPage(1);
              }}
              onChange={handleSchemeSearch}
              helperText="startups where scheme name CONTAINS search text are displayed"
            />
          </Box>
          <Box width="13vw">
            <ActionButton onClick={() => navigate("/startup/create-startup")}>
              Create Startup
            </ActionButton>
          </Box>
        </Grid>
        <Stack direction={"row"} gap={2} pl={3} pt={2} alignItems="center">
          <Typography>Select</Typography>
          <Stack width={"10vw"}>
            <CoreDropdown
              label="startup Type"
              options={STARTUP_TYPE}
              onClickMenuItem={(option: any) => {
                handleStartupTypeOptions(option.value);
              }}
              defaultValue={STARTUP_TYPE.find(
                (item: any) => item.value === selectedStartupType
              )}
            />
          </Stack>
          <Typography>startups with</Typography>
          <Stack width={"14vw"}>
            <CoreDropdown
              label="startup Status"
              options={STARTUP_STATUS}
              onClickMenuItem={(option: any) => {
                handleStartupStatusOptions(option.value);
              }}
              defaultValue={STARTUP_STATUS.find(
                (item: any) => item.value === selectedStartupStatus
              )}
            />
          </Stack>
          <Typography>status</Typography>
        </Stack>
      </Grid>

      <br />
      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={10}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
            rightPinnedColumns={["action"]}
          />
        </>
      )}
    </Box>
  );
}
