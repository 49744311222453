import { Box } from "@mui/material";
import { CardContainer } from "../../../../../ui-library/card/card-container";
import { tooltipText } from "../../../constants/StartupDetailsContant";

const ScreeningDetails = ({ details = {} }: any) => {
  const cardOptions: any = [
    {
      label: "Initial Screening Approved By",
      spacing: 3,
      value: details?.initial_action_taken_by ?? "-",
      helpText: tooltipText.initialScreenApprovedBy,
    },
    {
      label: "Initial Screening Approved On",
      spacing: 3,
      value: details?.initial_action_taken_on ?? "-",
    },
    {
      label: "Final Screening Approved By",
      spacing: 3,
      value: details?.final_action_taken_by ?? "-",
    },
    {
      label: "Final Screening Approved On",
      spacing: 3,
      value: details?.final_action_taken_on ?? "-",
    },
  ];

  return (
    <Box
      sx={{
        border: "1px solid #ececec",
        borderRadius: "0.5rem",
        textAlign: "left",
        marginTop: "1rem",
      }}
    >
      <CardContainer options={cardOptions} />
    </Box>
  );
};

export default ScreeningDetails;
