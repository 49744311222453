import React, { ReactNode, CSSProperties } from "react";
import "./section-header-style.css";
import Typography from "@mui/material/Typography";
import { LVThemeProvider } from "../Styles/Themes";
import { getColor } from "../Styles/Colors";
import { Stack, SxProps, Tooltip } from "@mui/material";
import { SectionHeaderSx } from "./section-header-sx";

export type SectionHeaderProps = {
  /**
   * a node to be rendered in the special component.
   */
  children?: ReactNode;
  customStyles?: SxProps;
  helpText?: string | ReactNode;
};

export function SectionHeader({
  children,
  customStyles = {},
  helpText,
}: SectionHeaderProps) {
  return (
    <LVThemeProvider>
      <Stack direction="row" spacing={0.2} mb={1} alignItems="center">
        <Typography
          variant="heading_07_bold"
          sx={{
            color: getColor("neutral", 900),
            fontFamily: "Work Sans",
            lineHeight: "30px",
            fontSize: "18px",
            fontWeight: 600,
            fontStyle: "normal",
            textAlign: "left",
            ...customStyles,
          }}
        >
          {children}
        </Typography>
        {helpText && (
          <Tooltip
            title={helpText}
            placement="top-start"
            arrow
            slotProps={{
              arrow: {
                sx: SectionHeaderSx.tooltipArrow,
              },
              tooltip: {
                sx: SectionHeaderSx.tooltip,
              },
            }}
            sx={{ position: "relative", width: "10%" }}
          >
            <i
              className="fa-sharp-duotone fa-regular fa-circle-info"
              style={
                {
                  marginLeft: "0.5rem",
                  "--fa-primary-color": "#5d6065",
                  "--fa-secondary-color": "#ffffff",
                } as CSSProperties
              }
            ></i>
          </Tooltip>
        )}
      </Stack>
    </LVThemeProvider>
  );
}
