export const INVESTMENT_TYPE = {
  AIF: {
    label: "AIF",
    value: "aif",
  },
  DIRECT: {
    label: "Direct",
    value: "direct",
  },
};

export const INVESTOR_RANKING = {
  P0: {
    label: "P0",
    value: 0,
  },
  P1: {
    label: "P1",
    value: 1,
  },
};

export const SCHEME_DOC_STATUS = {
  SENT: {
    label: "Sent",
    value: "Sent",
  },
  VIEWED: {
    label: "Viewed",
    value: "Viewed",
  },
  TO_BE_SENT: {
    label: "To be sent",
    value: "To be sent",
  },
};

export const DealConsentOptions = [
  {
    label: "Yes",
    value: 1,
  },
  {
    label: "No",
    value: 0,
  },
];

export const TransferTypeOptions = [
  {
    label: "Foreign",
    value: "foreign",
  },
  {
    label: "Indian",
    value: "indian",
  },
  {
    label: "NRO",
    value: "nro",
  },
  {
    label: "NRE",
    value: "nre",
  },
];

export const ActionType = {
  edit: "Edit",
  lock: "Lock",
  unlock: "Unlock",
  restrict: "Restrict Whatsapp Switch",
  without_gst: "Without GST",
  send_drawdown: "Send Drawdown",
};

export const tooltipText = {
  commitTracker: (
    <p>
      This is used by AIF team to track the payments, consents, drawdown etc for
      closed deals.
      <br />
      The PROGRESS tab shows commits where the funds have not yet been received
      by the AIF and therefore are not locked yet.
      <br />
      The COMPLETED tab shows commits where the funds have been received by the
      AIF and commits are locked. AIF team can choose Unlock action to change
      any of the fields for an investor in the commit tracker.
    </p>
  ),
  noOfDrawdownSent:
    "Number of investors who have received drawdown notice from AIF team via email to transfer the commitment amount.",
  noOfDrawdownAccepted:
    "Number of investors who have transferred the commitment amount to AIF bank account.",
  noOfSchemeSent:
    "The total number of schemes shared with investors or viewed by them while making a commitment on the LetsVenture platform.",
  noOfSchemeAccepted:
    "The total number of schemes to which investors have given their consent and committed to the deal on the LetsVenture platform.",
  postCommitStatus: (
    <p>
      This was used to update the investors on the progress of the deals after
      the deal is closed e.g. DD completed, SHA in progress etc.
      <br />
      This is a ready only page and this feature is not being used currently.
    </p>
  ),
  commitWithdrawInfo:
    "LV Admin can only withdraw a commitment done by an investor from the investor panel. Once the commit is withdrawn, the information can be seen in this page for all such withdrawls.",
  transactionPaymentStatus:
    "status of transaction fees payment by Investors who have committed into a deal",
  drawdownPaymentStatus: (
    <p>
      Status of drawdown payment i.e. the payment of committed amount.
      <br />
      How the drawdown amount is paid? For Public deals, the drawdown payment is
      collected manually by the AIF team using the commit tracker.
      <br />
      For FaaS deals, by default the drawdown payment is collected at the time
      of commitment itself unless the "???" field in the syndicate platform is
      set to NO. In that case, the drawdown payment needs to be collected
      manually by the AIF team using commit tracker.
    </p>
  ),
  createPaymentLink:
    "AIF team can create this payment link from here and send it to investors to pay online. The platform will automatically show the relevant amount as paid when the online payment is done.",
};

export const CommitTrackerBreadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Commitment Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Commit Tracker",
    navigationButton: false,
    helpText: tooltipText.commitTracker,
  },
};
