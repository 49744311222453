import { useEffect } from "react";
import { LicenseInfo } from "@mui/x-license-pro";
import "./App.css";
import AppRoutes from "./routes";
import { MUI_X_PRO_LICENSE_KEY } from "./constants/commonConstants";

function App() {
  useEffect(() => {
    LicenseInfo.setLicenseKey(MUI_X_PRO_LICENSE_KEY);
  }, []);

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
