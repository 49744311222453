import { Divider, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { getColor } from "../../../../../ui-library/Styles/Colors";

const iconStyleCheck = {
  fontSize: "18px",
  color: getColor("success", 500),
};
const iconStyleCross = { fontSize: "18px", color: getColor("error", 500) };

const LockFundraisingActionTooltip: FC<any> = ({ startupDetails }) => {
  return (
    <Stack gap={0.6}>
      <Stack direction="row" gap={2} alignItems="center">
        {startupDetails?.final_screening_approved ? (
          <i
            className="fa-duotone fa-regular fa-circle-check"
            style={iconStyleCheck}
          ></i>
        ) : (
          <i className="fa-regular fa-circle-xmark" style={iconStyleCross}></i>
        )}{" "}
        <Typography variant="paragraph_03_bold">
          Approve this startup from Final Screening.{" "}
          {!startupDetails?.final_screening_approved && (
            <Link to="/startup/final-screening">Go Here</Link>
          )}
        </Typography>
      </Stack>
      <Divider />

      <Stack direction="row" spacing={2} alignItems="center">
        {startupDetails?.is_scheme_verified ? (
          <i
            className="fa-duotone fa-regular fa-circle-check"
            style={iconStyleCheck}
          ></i>
        ) : (
          <i className="fa-regular fa-circle-xmark" style={iconStyleCross}></i>
        )}{" "}
        <Typography variant="paragraph_03_bold">
          Generate Scheme Document for this startup.{" "}
          {!startupDetails?.is_scheme_verified && (
            <Link to="/aif/scheme-doc-management">Go Here</Link>
          )}
        </Typography>
      </Stack>
      <Divider />

      <Stack direction="row" spacing={2} alignItems="center">
        {startupDetails?.pitching_founder ? (
          <i
            className="fa-duotone fa-regular fa-circle-check"
            style={iconStyleCheck}
          ></i>
        ) : (
          <i className="fa-regular fa-circle-xmark" style={iconStyleCross}></i>
        )}{" "}
        <Typography variant="paragraph_03_bold">
          Add Pitching Founder for this startup.
          {!startupDetails?.pitching_founder && (
            <Link
              to={`/startup/startup-panel/${startupDetails?.id}/edit/basic`}
              state={{ startupDetails }}
            >
              Go Here
            </Link>
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default LockFundraisingActionTooltip;
