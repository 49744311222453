import React, { useContext } from "react";
import { getColor } from "../Styles/Colors";

const useChipStyles = (active: Boolean) => {
  const activeStyle = active
    ? {
        background: getColor("background", 1400),
        color: "#fff",
        fontWeight: "bold",
      }
    : {
        color: "#293847",
        background: "#ECECEC",
        fontWeight: "600",
      };

  const chipStyle = {
    alignItems: "normal",
    padding: "5px 7px",
    cursor: "pointer",
    fontFamily: "Work Sans",
    "&:hover": {
      background: getColor("background", 1400),
      color: "#fff",
    },
  };

  const iconStyles = { color: active ? "#fff" : "inherit" };

  return [chipStyle, activeStyle, iconStyles];
};

export default useChipStyles;
