import { Box, Tooltip, Typography } from "@mui/material";
import { CardContainer } from "../../../../../ui-library/card/card-container";
import moment from "moment";
import { LinkButton } from "../../../../../ui-library/buttons/LinkButton";
import { tooltipText } from "../../../constants/common";

const UserBasicDetails = ({ details = {} }: any) => {
  const cardOptions: any = [
    {
      label: "User Id",
      spacing: 3,
      value: details?.user_id ?? "-",
    },
    {
      label: "User Name",
      spacing: 3,
      value: details?.name ?? "-",
    },
    {
      label: "Phone Number",
      spacing: 3,
      value: details?.phone_no ?? "-",
    },
    {
      label: "Email",
      spacing: 3,
      value: details?.email ?? "-",
    },
    {
      label: "LinkedIn",
      spacing: 3,
      renderer:
        details?.linkedin_url?.includes("http") ||
        details?.linkedin_url?.includes("www") ? (
          <LinkButton
            title={"Go to profile"}
            onClick={() =>
              window.open(
                details?.linkedin_url?.includes("http")
                  ? details?.linkedin_url
                  : `https://${details?.linkedin_url}`,
                "_blank"
              )
            }
          />
        ) : (
          "-"
        ),
    },
    {
      label: "Admin Hashtag",
      spacing: 3,
      helpText: tooltipText.adminHashtags,
      renderer: details?.private_hashtag_tokens?.length ? (
        details?.private_hashtag_tokens?.length > 1 ? (
          <>
            <Typography
              align="left"
              variant="heading_04_medium"
              fontWeight={600}
            >
              {details?.private_hashtag_tokens?.[0]?.name}{" "}
              <Tooltip
                title={details?.private_hashtag_tokens
                  .slice(1)
                  .map((hashtag: any) => hashtag.name)
                  .join(", ")}
                placement="top-start"
                arrow
                componentsProps={{
                  tooltip: { sx: { background: "#f2f2f2", color: "#000" } },
                }}
              >
                <Typography display="inline" variant="paragraph_03_bold">
                  <LinkButton
                    title={`+ ${
                      details?.private_hashtag_tokens?.length - 1
                    } more`}
                  />{" "}
                </Typography>
              </Tooltip>
            </Typography>
          </>
        ) : (
          <>{details?.private_hashtag_tokens?.[0]?.name} </>
        )
      ) : (
        "-"
      ),
    },

    {
      label: "Joined On",
      spacing: 3,
      helpText: tooltipText.joined,
      value: details?.joined_on
        ? moment(details?.joined_on).format("MMM DD, YYYY")
        : "-",
    },
    {
      label: "Is FAAS User?",
      spacing: 3,
      value: details?.is_faas ? "Yes" : "No",
      helpText: tooltipText.faasStatus,
    },
    {
      label: "Is Owner of Private Syndicate?",
      spacing: 3,
      value: details?.is_owner_of_syndicate ? "Yes" : "No",
      helpText: tooltipText.isOwnerOfPrivateSyndicate,
    },
    {
      label: "Backers of Private Syndicate",
      spacing: 3,
      helpText: tooltipText.backersOfPrivateSyndicate,
      renderer: details?.backer_private_syndicate?.length ? (
        details?.backer_private_syndicate?.length > 1 ? (
          <>
            <Typography
              align="left"
              variant="heading_04_medium"
              fontWeight={600}
            >
              {details?.backer_private_syndicate?.[0]?.syndicate_name}{" "}
              <Tooltip
                title={details?.backer_private_syndicate
                  .slice(1)
                  .map((item: any) => item?.syndicate_name)
                  .join(", ")}
                placement="top-start"
                arrow
                componentsProps={{
                  tooltip: { sx: { background: "#f2f2f2", color: "#000" } },
                }}
              >
                <Typography display="inline" variant="paragraph_03_bold">
                  <LinkButton
                    title={`+ ${
                      details?.backer_private_syndicate?.length - 1
                    } more`}
                  />{" "}
                </Typography>
              </Tooltip>
            </Typography>
          </>
        ) : (
          <>{details?.backer_private_syndicate?.[0]?.syndicate_name} </>
        )
      ) : (
        "-"
      ),
    },
  ];

  return (
    <Box
      sx={{
        border: "1px solid #ececec",
        borderRadius: "0.5rem",
        textAlign: "left",
        marginTop: "1rem",
      }}
    >
      <CardContainer options={cardOptions} />
    </Box>
  );
};

export default UserBasicDetails;
