import * as Yup from "yup";

export const SyndicateTypeOptions = [
  {
    label: "Regular",
    value: "normal",
  },
  {
    label: "Private",
    value: "private",
  },
  {
    label: "Zeno",
    value: "zeno",
  },
];

export const formPlaceholderConfig = Array(12).fill("input");

export const syndicateFormValidationSchema = {
  syndicateName: Yup.string().required("Syndicate Name is required"),
  selectedInvestor: Yup.object().required("Investor is required"),
  syndicateType: Yup.object().required("Syndicate Type is required"),
  syndicateHeader: Yup.string().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) => schema.required("Syndicate Header is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  aboutSyndicate: Yup.string().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) => schema.required("About Syndicate is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  syndicateInvestmentThesis: Yup.string().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) =>
      schema.required("Syndicate Investment Thesis is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  minInvestment: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) => schema.required("Minimum Investment is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  maxInvestment: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) => schema.required("Maximum Investment is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  syndicateDealPerYear: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) =>
      schema.required("Syndicate Deal Per Year is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  syndicateProfileImage: Yup.array().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) => schema.min(1).required("Profile Image is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  syndicateCoverImage: Yup.array().when("syndicateType", {
    is: (val: any) => val.value === "normal" || val.value === "private",
    then: (schema: any) => schema.min(1).required("Cover Image is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  maxCorpus: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "zeno",
    then: (schema: any) => schema.required("Maximum Corpus is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  preCommitTenure: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "zeno",
    then: (schema: any) =>
      schema.required("Pre-commit Syndicate Tenure is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  managementFee: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "zeno",
    then: (schema: any) =>
      schema.required("Management Fee Percentage is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  numberOfDrawdowns: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "zeno",
    then: (schema: any) => schema.required("Number of Drawdowns is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  drawDownIntervals: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "zeno",
    then: (schema: any) =>
      schema.required("Expected Drawdown Interval is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  LvCarry: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "zeno",
    then: (schema: any) => schema.required("LV Carry is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
  hurdleRate: Yup.number().when("syndicateType", {
    is: (val: any) => val.value === "zeno",
    then: (schema: any) => schema.required("Hurdle Rate is required"),
    otherwise: (schema: any) => schema.notRequired(),
  }),
};

export const tooltipText = {
  createSyndicate: (
    <p>
      This tab is primarily used to create Syndicates on the LV platform. <br />
      Syndicates can be created here when a Lead Investor requests LV admins to
      set one up.
      <br />
      LV admins can create three types of Syndicates from this tab:
      <ol>
        <li>
          Regular Syndicate-
          <ul>
            <li>
              A standard syndicate that is visible to all public investors on
              the platform.
            </li>
            <li>
              Any public investor can choose to back this syndicate on their
              own.
            </li>
          </ul>
        </li>
        <li>
          Private syndicate-
          <ul>
            <li>
              Exclusive Access: This syndicate is private and only visible to
              its backers.
            </li>
            <li>
              Joining Process: A backer can join only if the Syndicate owner or
              an LV admin adds them to it.
            </li>
            <li>
              Access Levels: Once a backer joins, their access depends on the
              syndicate’s visibility settings and their previous visibility
              status (if they were already an investor on the LV platform).
            </li>
            <li>
              Backer Restrictions: Private Syndicates have their own set of
              backers. These backers can only view public deals if they request
              LV admins for public access.
            </li>
          </ul>
        </li>
        <li>
          Zeno Syndicate -
          <ul>
            <li>
              Pre-Pooled Funds: Like a Private Syndicate, but with pre-committed
              funds collected from its backers.
            </li>
            <li>
              Key Difference: Unlike Private Syndicates, backers do not commit
              to startups individually. Instead, the Syndicate owner allocates
              funds on their behalf. The commitment amount for each backer is
              automatically calculated based on their percentage contribution to
              the pre-pooled fund.
            </li>
          </ul>
        </li>
      </ol>
    </p>
  ),
};
