import React from "react";
import { Box } from "@mui/material";
import { CardContainer } from "../../../../../ui-library/card/card-container";
import { PlanTypeOptionsArray } from "../../../constants/USER_PANEL_CONSTANTS";
import { NoDataAvailable } from "../../../../../ui-library/error/NoDataAvailable";
import { IconConstant } from "../../../../../constants/IconConstants";
import { tooltipText } from "../../../constants/common";

const SubscriptionDetails = ({ details = {} }: any) => {
  const cardOptions: any = [
    {
      label: "Plan Type",
      spacing: 3,
      value: PlanTypeOptionsArray.has(details?.subscription_data?.plan_type)
        ? PlanTypeOptionsArray.get(details?.subscription_data?.plan_type)?.label
        : "-",
      helpText: tooltipText.planType,
    },
    {
      label: "Status",
      spacing: 3,
      value: details?.subscription_data?.active ? "Active" : "Expired",
    },

    {
      label: "Start Date",
      spacing: 3,
      value: details?.subscription_data?.formatted_start_date ?? "-",
    },
    {
      label: "End Date",
      spacing: 3,
      value: details?.subscription_data?.formatted_end_date ?? "-",
    },
  ];
  return (
    <Box
      sx={{
        border: "1px solid #ececec",
        borderRadius: "0.5rem",
        textAlign: "left",
        marginTop: "1rem",
      }}
    >
      {details?.subscription_data ? (
        <CardContainer options={cardOptions} />
      ) : (
        <Box p={3}>
          <NoDataAvailable
            title="No Data Available"
            subTitle={"No Subscription Details Found"}
            image={IconConstant.DATA_NOT_FOUND_ICON}
          />
        </Box>
      )}
    </Box>
  );
};

export default SubscriptionDetails;
