import React from "react";
import { Box } from "@mui/material";
import { CardContainer } from "../../../../../ui-library/card/card-container";
import { isEmpty } from "../../../../../utils/commonUtils";
import { tooltipText } from "../../../constants/common";
import { LinkButton } from "../../../../../ui-library/buttons/LinkButton";
import ActivationNotesModal from "./ActivationNotesModal";

const AccessDetails = ({ details = {} }: any) => {
  const [openNotesModal, setOpenNotesModal] = React.useState(false);

  const handleOpenCloseModal = () => setOpenNotesModal(!openNotesModal);

  const cardOptions: any = [
    {
      label: "Activation Status",
      spacing: 3,
      value: details?.activated ? "Activated" : "Not Activated",
      helpText: tooltipText.activationStatus,
    },
    {
      label: "Activation Note",
      spacing: 3,
      value: details?.notes?.length ? (
        <LinkButton title="view all" onClick={handleOpenCloseModal} />
      ) : (
        "-"
      ),
      helpText: tooltipText.activationNote,
    },
    {
      label: "Publish Status",
      spacing: 3,
      value: details?.published ? "Published" : "Not Published",
      helpText: tooltipText.publishStatus,
    },
    {
      label: "Access for Deals/Feed/Syndicate",
      spacing: 3,
      value: details?.is_blocked ? "Blocked" : "Allowed",
      helpText: tooltipText.accessForDealsFeedSyndicate,
    },

    {
      label: "Private Syndicate Investor Landing Page",
      spacing: 3,
      value: !isEmpty(details?.backer_has_fass_no_platform_access)
        ? "Access Revoked - Lands on Syndicates Page"
        : "Access Granted - Lands on Feed Page",
      helpText: tooltipText.privateSyndicateInvestorLandingPage,
    },
  ];

  return (
    <Box
      sx={{
        border: "1px solid #ececec",
        borderRadius: "0.5rem",
        textAlign: "left",
        marginTop: "1rem",
      }}
    >
      {openNotesModal && (
        <ActivationNotesModal
          open={openNotesModal}
          handleClose={handleOpenCloseModal}
          notes={details?.notes ?? []}
        />
      )}
      <CardContainer options={cardOptions} />
    </Box>
  );
};

export default AccessDetails;
