import React, { useEffect, useState } from "react";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { Box, Grid } from "@mui/material";
import { API_STATUS } from "../../../../constants/commonConstants";
import { toast } from "react-toastify";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import VerifyModal from "../../../userManagement/components/modals/VerifyModal";
import CommitmentManagementManager from "../../managers/commitmentManagementManager";
import {
  PostCommitStatusBreadcrumbData,
  PostCommitStatusColumn,
  PostCommitStatusTabs,
} from "../../constants/PostCommitmentConstants";

export default function PostCommitStatusTracker() {
  const commitmentManagementManager = new CommitmentManagementManager();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [selectedTab, setSelectedTab] = useState(PostCommitStatusTabs[0].value);
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const getData = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let data: any = [];
      data = await commitmentManagementManager.getPostCommitStatusList(
        selectedTab,
        page
      );
      let formatted_data = data.results;
      formatted_data = formatted_data.map((item: any) => ({
        ...item,
        fundraise_amount: !!item.funding_ask.amount
          ? `${item.funding_ask.symbol} ${item.funding_ask.amount} ${item.funding_ask.unit}`
          : null,
      }));
      setFormattedData(formatted_data);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  useEffect(() => {
    getData();
  }, [page, selectedTab]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={4}>
          <Breadcrumb
            steps={PostCommitStatusBreadcrumbData?.steps}
            pageHeader={PostCommitStatusBreadcrumbData.pageHeader as any}
            stepKey={PostCommitStatusBreadcrumbData.stepKey}
          />
        </Grid>
      </Grid>

      <Box>
        {openDeleteModal && (
          <VerifyModal
            isOpen={openDeleteModal}
            title="Delete Post Commit Process"
            message="Are you sure to delete?"
            handleClose={() => setOpenDeleteModal(false)}
          />
        )}
        <HorizontalTabs
          tabs={PostCommitStatusTabs}
          selectedTab={selectedTab}
          onTabChange={(tab: any) => {
            setSelectedTab(tab);
            setPage(1);
          }}
          scrollable={false}
        />

        {apiStatus == API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        ) : (
          <LvComplexTable
            rows={formattedData}
            columns={PostCommitStatusColumn}
            rowHeight={40}
            headerHeight={60}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={30}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        )}
      </Box>
    </Box>
  );
}
