import { tooltipText } from "./CrmConstants";

export const tabsOptions: any = [
  {
    name: "Paid Transaction",
    value: "paid",
  },
  {
    name: "Pending Transaction",
    value: "pending",
  },
];

export const OrderType: any = [
  {
    label: "Transaction Fess",
    value: "transaction_fee",
  },
  {
    label: "Drawdown",
    value: "drawdown",
  },
];

export const TransactionBreadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Commitment Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Transaction Payment Status",
    navigationButton: false,
    helpText: tooltipText.transactionPaymentStatus,
  },
};

export const DrawdownBreadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Commitment Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Drawdown Payment Status",
    navigationButton: false,
    helpText: tooltipText.drawdownPaymentStatus,
  },
};
