import schemeServices from "../services/schemeServices";

export default class SchemeManager {
  async getAdminLookupData(status: string, search: string, tab: string) {
    try {
      let result: any = await schemeServices.getAdminLookupData(
        status,
        search,
        tab
      );
      result = result.data.results.map((item: any) => {
        return {
          label: item.text,
          value: item.id,
        };
      });
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getSchemeAndStartupData(startup_id: any) {
    try {
      if (!startup_id) {
        throw new Error("Startup ID is required");
      }

      const result = await schemeServices.getSchemeAndStartupData(startup_id);
      if (!result || !result.data) {
        throw new Error("Invalid response from schemeServices");
      }

      if (result.data.detail === "Scheme Not Found") {
        return "Scheme Not Found";
      }

      const specificData = result.data.specific_data;
      const formattedData = {
        leadCarry: specificData.total_lead_carry || 0,
        roundType: specificData.round_type || "",
        hurdleRate: specificData.hurdle_rate || 0,
        transactionFees: specificData.transaction_fees || 0,
        fundCarry: specificData.fund_carry || 0,
        dealProgress: specificData?.commitment_via_lv || 0,
        totalFundraising: specificData.funding_ask || 0,
        preMoneyValuation: specificData.pre_money_valuation || 0,
        pricePerShare: specificData.share_price_with_text || "",
        portfolioEntityProfile: specificData.startup_service || "",
        numberOfSecurities: specificData.securities_description || "",
        conversionPriceTerms: specificData.conversion_terms
          ? specificData.conversion_terms
          : specificData.round_type === "equity"
          ? "Not Applicable"
          : "",
        investmentSchemeName: specificData.scheme_name || "",
        rightUnderNegotiation: specificData.right_under_negotiations || "",
        lastConsentDate: specificData?.last_date_to_consent
          ? new Date(specificData?.last_date_to_consent)
          : new Date(),

        startupName: specificData.startup_name || "",
        sector: specificData.sector || "",
        stage: specificData.stage || "",
        market: specificData.market || "",
        founded: specificData?.founded ?? "-",
        leadInvestors: specificData?.lead_investors || [],
      };

      result.data.specific_data = { ...formattedData };

      return result.data;
    } catch (error: any) {
      console.error("Error in getSchemeAndStartupData:", error);
      throw error;
    }
  }

  async updateSchemeDocument(data: any) {
    try {
      let result = await schemeServices.updateSchemeDocument(data);
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async generateSchemeDocument(data: any) {
    try {
      let result = await schemeServices.generateSchemeDocument(data);
      return result.data;
    } catch (error) {
      throw error;
    }
  }
}
