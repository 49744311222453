import { Box, SxProps, Tooltip, Typography } from "@mui/material";
import { CSSProperties, FC, useEffect, useRef, useState } from "react";

const CardValueSx: SxProps = {
  textOverflow: "ellipsis",
  overflowX: "hidden",
  wordBreak: "normal",
  whiteSpace: "nowrap",
  textAlign: "left",
  fontWeight: 600,
};

const hoverSpanSx: CSSProperties = {
  position: "absolute",
  right: 0,
  bottom: 0,
  width: "30px", // Adjust width for better hover detection
  height: "100%",
  background: "transparent",
  cursor: "pointer",
};

type CardValueProps = {
  value: string;
};

const CardValueElement: FC<CardValueProps> = ({ value }) => {
  const textRef = useRef<HTMLParagraphElement>(null);

  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [value]);

  return (
    <Box position="relative">
      <Typography variant="heading_04_medium" sx={CardValueSx} ref={textRef}>
        {value}
      </Typography>
      {isTruncated && (
        <Tooltip
          title={value}
          arrow
          componentsProps={{
            tooltip: {
              sx: { background: "#f2f2f2", color: "#000" },
            },
          }}
        >
          <span style={hoverSpanSx} />
        </Tooltip>
      )}
    </Box>
  );
};

export default CardValueElement;
