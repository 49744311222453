export const GenderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const ProfileTypeOptions = [
  { label: "Business Owner", value: "business_owner" },
  { label: "Professional", value: "professional" },
  {
    label: "VC PE Professional",
    value: "vc_pe_professional",
  },
];

export const tooltipText = {
  verifyInvestor:
    "This tab is used to verify or reject new investors who sign up on the platform for the first time.",
  addNewInvestor: (
    <p>
      It essentially invites the user to the platform.
      <br />
      For users who do not wish to sign up on their own, LV admins can invite
      them through this feature and proceed with further actions.
    </p>
  ),
  planType: (
    <p>
      This indicates the user’s plan type:
      <br />
      • If the plan type is 1825 days (premium), it is a five-year plan, which
      is typically available only to private syndicate users.
      <br />• If the plan type is 365 days (premium), it is a one-year plan,
      which can be used by all types of users, including normal users and
      private users (if they have renewed the plan).
    </p>
  ),
  privateSyndicateInvestorLandingPage: (
    <p>
      This is used in the case of private syndicate backers and owners.
      <br />
      It revokes their access to public deals, limiting their visibility to only
      the Syndicates, My Commitment, and Support pages on the platform.
    </p>
  ),
  accessForDealsFeedSyndicate: (
    <p>
      It indicates whether the user has full access to the platform or limited
      access. This is used when the user has not completed their subscription or
      payment.
      <br />• If the user has full access, they can view all pages on the
      platform.
      <br />• If the user is blocked, they will only have limited access to a
      few pages, such as portfolio, commitment, and support.
    </p>
  ),
  publishStatus:
    "If the status is marked as unpublished, it stops communication between LV and the investor.",
  activationStatus:
    "It indicates whether the user’s account is activated, which determines whether the user can view deals and access other features on the platform.",
  activationNote:
    "This is a note added by the LV admins to the user. It is used to keep track of the user’s activation status and any other relevant information.",
  corpusAmount: (
    <p>
      The corpus amount is automatically calculated and not filled by the
      investor.It is initially set to 25L right after onboarding.
      <br />
      As the investor commits more into deals, the corpus amount will increase
      to reflect the current total commit amount of the investor.
    </p>
  ),
  investmentExperience:
    "The experience field is filled out by the investor, detailing their professional background or relevant experience.",
  createdAt:
    "During onboarding, once the investor completes the digital KYC steps, the “created at” date is recorded.",
  aifFundPaid: "If yes, the user has paid the AIF onboarding fee.",
  relationName:
    "This is not the nominee’s name; it is simply the father’s or spouse’s name required for KYC purposes.",
  backersOfPrivateSyndicate:
    "These are private syndicates in which the investor is a backer.",
  isOwnerOfPrivateSyndicate:
    "These are syndicate leads who manage the entire private syndicate through the syndicate platform.",
  faasStatus: (
    <p>
      All investors onboarded through the syndicate platform are automatically
      marked as FAAS investors.
      <br />
      Additionally, LV admins have the ability to mark investors as FAAS from
      the admin dashboard.
    </p>
  ),
  joined:
    "The date on which the investor first signs up on the LV platform is referred to as their “sign-up date.”",
  adminHashtags: (
    <p>
      These hashtags are assigned by LV admins to investors to help segment
      users and categorize them.
      <br />
      For example: #FAAS, #FamilyOffices, #VCFund.
    </p>
  ),
  onboardingFee: (
    <p>
      During onboarding, an onboarding fee is charged, which is typically paid
      via the payment gateway.
      <br />
      If not paid through the gateway, the AIF team collects the fee by
      generating a payment link from the “Create Payment Link” section in AIF
      management.
    </p>
  ),
  verifyInvestorModal: (
    <p>
      Verifying the investor from this tab will ensure that their access remains
      the same as it was <br />
      at the time of signing up.
    </p>
  ),
  rejectInvestorModal: (
    <p>
      Rejecting the investor from this tab will deactivate and unpublish their
      account.
      <br />
      Additionally, an automated email will be sent to inform the investor.
    </p>
  ),
  verifyPhoneNumber:
    "This tab is used to verify an investor’s phone number in case they missed the verification due to not receiving the OTP.",
  profilePendingInvestor:
    "This tab captures the data of all investors who have not yet completed their profiles on the LV platform.",
  investorInformation:
    "This tab captures the data of all investors who have completed their investment profile but have not yet signed the CA document.",
  nomineeInformation: (
    <p>
      This tab captures the data of all investors who have provided nominee
      information during digital KYC.
      <ul>
        <li>
          Nominee information can be added either during digital KYC or from the
          Edit User Profile section.
        </li>
        <li>Once submitted, the information gets locked automatically.</li>
        <li>
          Investors can only fill in nominee details if their investment profile
          is available.
        </li>
      </ul>
    </p>
  ),
  deamtInformation: (
    <p>
      This tab captures the data of all investors who have provided their Demat
      information during digital KYC.
      <ul>
        <li>
          Demat information can be added either during digital KYC or from the
          Edit User Profile section.
        </li>
        <li>Once submitted, the information gets locked automatically.</li>
        <li>
          Investors can only fill in Demat details if their investment profile
          is available.
        </li>
      </ul>
    </p>
  ),
  markAsVerified: (
    <p>
      Performed by LV admins after successfully verifying an investor’s Demat
      information.
      <br />
      Once marked as verified, the investor’s Demat information cannot be
      unlocked.
    </p>
  ),
  unlock:
    "Performed by LV admins to reopen the Demat form, allowing the investor to update the details.",
  kycStatus: (
    <p>
      The User KYC tab is designed to store and manage investors’ KYC data.
      <br />
      It consists of three sections:
      <ol>
        <li>Pending</li>
        <li>In-Progress</li>
        <li>Verified</li>
      </ol>
      LV admins review and verify the KYC data, then categorize it into the
      appropriate section based on the verification status.
    </p>
  ),
};
