import React, { CSSProperties, ReactNode } from "react";
import { Grid, Typography, Box, Tooltip, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getColor } from "../Styles/Colors";
import { SectionHeader } from "../header/section-header";
import CardValueElement from "./card-value-element";
import { SectionHeaderSx } from "../header/section-header-sx";
import { InfoOutlined } from "@mui/icons-material";

const useStyles = makeStyles({
  containerClass: {
    borderRadius: "0.5rem",
    padding: "1.875rem",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: "5%",
  },
  subHeader: {
    fontSize: "1.125rem",
    lineHeight: "1.875rem",
    color: getColor("neutral", 900),
    fontFamily: "Work Sans",
    fontWeight: 600,
    marginBottom: "1.25rem",
  },
  expansionPanelContainer: {
    padding: `0px !important`,
  },
});

type optionsObject = {
  label: any;
  value?: any;
  spacing: number;
  /**
   * here you can customize the design of the values and pass the ReactNode (elements) directly instead of just value
   * Eg: <Typography>value</Typography>
   */
  renderer?: any;
  /**
   * boolean value, if we want to make this particular item to become expansion panel .Value to this item will be iside of the expansion panel
   */
  isExpansionPanel?: boolean;
  helpText?: string;
};

export type CardContainerProps = {
  /**
   * section header
   */
  header?: string;
  /**
   * section header react node
   */
  headerNode?: ReactNode;
  /**
   * sub header in the card itself
   */
  subHeader?: string;
  /**
   * sub header react node in the card itself
   */
  subHeaderNode?: ReactNode;
  /**
   * here options is the array of objects , here you can add options to render the key value data
   */
  options: optionsObject[];
  /**
   * add custom styles
   */
  customClass?: string;
  customStyle?: any;
};

export function CardContainer({
  header,
  headerNode,
  subHeader,
  subHeaderNode,
  options,
  customClass,
  customStyle,
}: CardContainerProps) {
  const classes = useStyles();
  const renderOptions = () => {
    return (
      <Box
        className={`${classes.containerClass} ${customClass}`}
        style={customStyle}
      >
        {subHeader && !subHeaderNode && (
          <Box className={classes.subHeader}>{subHeader}</Box>
        )}
        {!subHeader && subHeaderNode && <>{subHeaderNode}</>}
        <Grid container>
          {options.map((ele, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={ele?.spacing ?? 6}
              xl={ele?.spacing ?? 4}
              key={`${ele.value} ${index}`}
            >
              <Box mb={1.875}>
                <Stack direction="row" gap={1}>
                  <Typography
                    align="left"
                    variant="paragraph_03_bold"
                    component="span"
                  >
                    {ele.label}
                    {ele?.helpText && (
                      <Tooltip
                        title={ele?.helpText}
                        placement="top-start"
                        arrow
                        slotProps={{
                          arrow: {
                            sx: SectionHeaderSx.tooltipArrow,
                          },
                          tooltip: {
                            sx: SectionHeaderSx.tooltip,
                          },
                        }}
                        sx={{ position: "relative", width: "10%" }}
                      >
                        <i
                          className="fa-sharp-duotone fa-regular fa-circle-info"
                          style={
                            {
                              marginLeft: "0.5rem",
                              "--fa-primary-color": "#5d6065",
                              "--fa-secondary-color": "#ffffff",
                            } as CSSProperties
                          }
                        ></i>
                      </Tooltip>
                    )}
                  </Typography>{" "}
                </Stack>
                <Box width="100%">
                  {ele.renderer ? (
                    <Typography
                      textAlign="left"
                      variant="heading_04_medium"
                      fontWeight={600}
                    >
                      {ele.renderer}
                    </Typography>
                  ) : (
                    <CardValueElement value={ele.value} />
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  return (
    <Box id="card-container">
      {header && !headerNode && (
        <Box mb={1.5}>
          <SectionHeader>{header}</SectionHeader>
        </Box>
      )}
      {!header && headerNode && <Box mb={2.5}>{headerNode}</Box>}
      {renderOptions()}
    </Box>
  );
}
