import { useAuth0 } from "@auth0/auth0-react";
import { setInterceptors, setHeader } from "../utils/interceptor";
import { createContext, useEffect, useState } from "react";
import { AuthManager } from "../app/auth/authManager/authManager";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { USER_ROLES } from "../constants/appConstants";

export type UserAuthInfo = {
  token: string | void;
  user?: any;
};

export type AuthContextProps = {
  authState: UserAuthInfo;
  userRoles: any;
  setAuthState: (authState: UserAuthInfo) => void;
  isAuthLoading: boolean;
  setIsAuthLoading: (isAuthLoading: boolean) => void;
  getTokenFromAuth0: () => Promise<string | void>;
};

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const authManager = new AuthManager();
  const navigate = useNavigate();
  const [authState, setAuthState] = useState<UserAuthInfo>({} as UserAuthInfo);
  const [userRoles, setUserRoles] = useState<any>();
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  const {
    getAccessTokenSilently,
    isAuthenticated = false,
    isLoading = true,
    logout,
    error,
  } = useAuth0();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  if (!domain || !clientId || !audience) {
    throw new Error("Missing Auth0 environment variables");
  }
  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
        federated: true,
      },
    });
    setAuthState({} as UserAuthInfo);
  };

  const getTokenFromAuth0 = async () =>
    await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        prompt: "none",
      },
      detailedResponse: true,
    })
      .then((res) => {
        return res.access_token;
      })
      .catch((error) => {
        console.error("getTokenFromAuth0 -> error", error.message, error.error);
        if (error.error === "invalid_grant") {
          handleLogout();
        }
        throw error;
      });

  const fetchUserMe = async () => {
    try {
      if (isAuthenticated) {
        const result = await authManager.getUserMe();
        if (!result?.admin) {
          handleLogout();
          toast.error("You are not authorized to access this application");
          return;
        }
        const roles = result?.has_write_access
          ? [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]
          : [USER_ROLES.ADMIN];

        setUserRoles(roles);
        return result;
      }
    } catch (error: any) {
      let errorMessage = "";
      if (error.response.status === 404) {
        errorMessage = "Email not registered";
      } else if (error.response.status === 500) {
        errorMessage = "Something went wrong";
      } else {
        errorMessage = "Username or Password is wrong";
      }
      toast.error(errorMessage);
      setTimeout(() => {
        handleLogout();
      }, 3000);
    }
  };

  const setAuthStateAndInterceptors = async () => {
    try {
      const accessToken = await getTokenFromAuth0();
      setHeader();
      setInterceptors(accessToken, getTokenFromAuth0, setAuthState, navigate);
      const user = await fetchUserMe();
      setAuthState({ user, token: accessToken });
      setIsAuthLoading(false);
    } catch (error: any) {
      let errorMessage = "Something went wrong";
      console.error(error?.message || errorMessage);
      navigate("/");
      sessionStorage.clear();
      setIsAuthLoading(false);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setAuthStateAndInterceptors();
    }
  }, [isLoading]);

  useEffect(() => {
    if (authState.token) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${authState.token}`;
    }
  }, [authState]);

  return (
    <AuthContext.Provider
      value={{
        authState,
        userRoles,
        setAuthState,
        isAuthLoading,
        setIsAuthLoading,
        getTokenFromAuth0,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
