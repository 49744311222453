import { Box, Grid, Stack } from "@mui/material";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { useState } from "react";
import {
  FinalScreeningBreadCrumbData,
  FinalScreeningTabs,
} from "../../constants/FinalScreeningConstant";
import { API_STATUS } from "../../../../constants/commonConstants";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import StartupManagement from "../../managers/startupManager";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import VerifyModal from "../../../userManagement/components/modals/VerifyModal";
import RejectModal from "./RejectModal";

export default function addNewInstitution() {
  const authContext = useContext(AuthContext);
  const startupManagement = new StartupManagement(authContext);
  const [selectedTab, setSelectedTab] = useState(FinalScreeningTabs[0].value);
  const [sortBy, setSortBy] = useState<any>({});
  const [sortByDoc, setSortByDoc] = useState<any>({});
  const [dealType, setDealType] = useState<any>({});
  const [sector, setSector] = useState<any>({});

  const [name, setName] = useState("");
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openMoveAwaitingModal, setOpenMoveAwaitingModal] = useState(false);
  const [submittingAction, setSubmittingAction] = useState(false);

  const getCaDocStatus = async (pageNo: number) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { formattedData, count } =
        await startupManagement.getFinalScreeningDocs(
          selectedTab,
          pageNo,
          name ?? ""
        );
      setFormattedData(formattedData);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleAceept = async (id: any) => {
    try {
      setSubmittingAction(true);
      const result = await startupManagement.finalScreeningAction(id, true);
      toast.success(result?.detail);
      setSubmittingAction(false);
      setOpenAcceptModal(false);
      getCaDocStatus(1);
      setPage(1);
    } catch (e: any) {
      setSubmittingAction(false);
      toast.error(e?.detail);
    }
  };

  const handleReject = async () => {
    setOpenRejectModal(false);
    getCaDocStatus(1);
    setPage(1);
  };

  const handleAwaiting = async (id: any) => {
    try {
      setSubmittingAction(true);
      const result = await startupManagement.finalScreeningAction(id, null);
      toast.success(result?.detail);
      setSubmittingAction(false);
      setOpenMoveAwaitingModal(false);
      getCaDocStatus(1);
      setPage(1);
    } catch (e: any) {
      setSubmittingAction(false);
      toast.error(e?.detail);
    }
  };

  const AwaitingColumns = [
    {
      field: "startup_name",
      headerName: "Startup Name",
      flex: 2,
      minWidth: 180,
      headerAlign: "left",
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link
            to={`/startup/startup-panel/${params.row?.id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "sector",
      headerName: "Startup's Sector",
      flex: 2,
      minWidth: 250,
      headerAlign: "left",
    },
    {
      field: "approved_from_initial",
      headerName: "Approved On",
      flex: 2,
      minWidth: 200,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "approved_from_initial_by",
      headerName: "Approved By",
      flex: 2,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "mou_uploaded",
      headerName: "MOU Uploaded",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => (params?.row?.mou_uploaded ? "Yes" : "No"),
      align: "center",
    },
    {
      field: "write_up_url",
      headerName: "Startup Blurb",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) =>
        params?.row?.startup_blurb_uploaded ? "Yes" : "No",
      align: "center",
    },

    {
      field: "is_faas",
      headerName: "Deal Type",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => (params?.row?.is_faas ? "FaaS" : "Non-FaaS"),
    },

    {
      field: "action",
      headerName: "Actions",
      flex: 3,
      minWidth: 210,
      align: "center",
      renderCell: (params: any) => {
        return (
          <Stack direction="row" gap={2}>
            <OutlinedButton
              customStyles={{
                color: getColor("action_button", 300),
                borderColor: getColor("action_button", 300),
              }}
              onClick={() => {
                setOpenAcceptModal(params.row?.id);
              }}
              tableAction
            >
              Accept
            </OutlinedButton>
            <OutlinedButton
              onClick={() => setOpenRejectModal(params?.row?.id)}
              customStyles={{
                color: getColor("error", 500),
                borderColor: getColor("error", 500),
              }}
              tableAction
            >
              Reject
            </OutlinedButton>
          </Stack>
        );
      },
    },
  ];

  const ApprovedColumns = [
    {
      field: "startup_name",
      headerName: "Startup Name",
      flex: 2,
      minWidth: 180,
      headerAlign: "left",
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link
            to={`/startup/startup-panel/${params.row?.id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "sector",
      headerName: "Startup's Sector",
      headerAlign: "left",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "approved_from_initial",
      headerName: "Approved On",
      headerAlign: "right",
      align: "right",
      flex: 2,
      minWidth: 200,
    },
    {
      field: "approved_from_initial_by",
      headerName: "Approved By",
      headerAlign: "left",
      align: "left",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "mou_uploaded",
      headerName: "MOU Uploaded",
      flex: 2,
      minWidth: 150,
      align: "center",
      renderCell: (params: any) => (params?.row?.mou_uploaded ? "Yes" : "No"),
    },
    {
      field: "startup_blurb_uploaded",
      headerName: "Startup Blurb",
      flex: 1,
      minWidth: 100,
      align: "center",
      renderCell: (params: any) =>
        params?.row?.startup_blurb_uploaded ? "Yes" : "No",
    },
    // {
    //   field: "profile_complete",
    //   headerName: "Profile Complete",
    //   flex: 1,
    //   minWidth: 100
    // },
  ];

  const RejectedColumns = [
    {
      field: "startup_name",
      headerName: "Startup Name",
      flex: 2,
      minWidth: 200,
      headerAlign: "left",
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link
            to={`/startup/startup-panel/${params.row?.id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "sector",
      headerName: "Startup's Sector",
      flex: 2,
      minWidth: 250,
      headerAlign: "left",
    },
    {
      field: "approved_from_initial",
      headerName: "Rejected On",
      flex: 2,
      minWidth: 200,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "approved_from_initial_by",
      headerName: "Rejected By",
      flex: 1,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
    },

    {
      field: "mou_uploaded",
      headerName: "MOU Uploaded",
      flex: 2,
      minWidth: 100,
      align: "center",
      renderCell: (params: any) => (params?.row?.mou_uploaded ? "Yes" : "No"),
    },
    {
      field: "startup_blurb_uploaded",
      headerName: "Startup Blurb",
      flex: 1,
      minWidth: 100,
      align: "center",

      renderCell: (params: any) =>
        params?.row?.startup_blurb_uploaded ? "Yes" : "No",
    },
    // {
    //   field: "profile_complete",
    //   headerName: "Profile Complete",
    //   flex: 1,
    //   minWidth: 100
    // },

    {
      field: "action",
      headerName: "Action",
      flex: 3,
      minWidth: 210,

      renderCell: (params: any) => {
        return (
          <OutlinedButton
            tableAction
            onClick={() => setOpenMoveAwaitingModal(params?.row?.id)}
          >
            Move to Awaiting
          </OutlinedButton>
        );
      },
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    getCaDocStatus(page + 1);
  };

  const handleSearchClick = () => {
    getCaDocStatus(1);
    setPage(1);
  };

  const getColumnData = (tab: string) => {
    switch (tab) {
      case "awaiting":
        return AwaitingColumns;
      case "approved":
        return ApprovedColumns;
      case "rejected":
        return RejectedColumns;
      default:
        return AwaitingColumns;
    }
  };

  useEffect(() => {
    getCaDocStatus(1);
  }, [selectedTab]);

  return (
    <Box>
      {openAcceptModal && (
        <VerifyModal
          title="Accept Startup"
          message="Are you sure you want to accept this startup from final screening?"
          isOpen={openAcceptModal}
          handleClose={() => setOpenAcceptModal(false)}
          handleSubmit={() => handleAceept(openAcceptModal)}
          submitting={submittingAction}
        />
      )}
      {openRejectModal && (
        <RejectModal
          isOpen={openRejectModal}
          handleClose={() => setOpenRejectModal(false)}
          handleSubmit={handleReject}
        />
      )}
      {openMoveAwaitingModal && (
        <VerifyModal
          title="Move To Awaiting Status"
          message="Are you sure you want to move this startup to awaiting status?"
          isOpen={openMoveAwaitingModal}
          handleClose={() => setOpenMoveAwaitingModal(false)}
          handleSubmit={() => handleAwaiting(openMoveAwaitingModal)}
          submitting={submittingAction}
        />
      )}
      <Grid container spacing={2} mb={"1rem"}>
        <Grid item xs={2}>
          <Breadcrumb
            steps={FinalScreeningBreadCrumbData?.steps}
            pageHeader={FinalScreeningBreadCrumbData.pageHeader}
            stepKey={FinalScreeningBreadCrumbData.stepKey}
          />
        </Grid>
        <Grid
          item
          xs={10}
          display="flex"
          gap={2}
          justifyContent="end"
          flexWrap="wrap"
        >
          <Stack direction="row" gap={2}>
            <Box width="20vw">
              <CustomTextField
                value={name}
                onChange={(val: any) => setName(val)}
                label="Search By Startup Name"
              />
            </Box>{" "}
            <ActionButton onClick={handleSearchClick}>Search</ActionButton>
          </Stack>
        </Grid>
      </Grid>
      <HorizontalTabs
        tabs={FinalScreeningTabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
        }}
        scrollable={false}
      />

      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={getColumnData(selectedTab)}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={10}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
            rightPinnedColumns={["action"]}
          />
        </>
      )}
    </Box>
  );
}
