import { useState, useEffect, useContext, useCallback } from "react";
import { API_STATUS } from "../../../../constants/commonConstants";
import { toast } from "react-toastify";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { IconConstant } from "../../../../constants/IconConstants";
import { NoDataAvailable } from "../../../../ui-library/error/NoDataAvailable";
import StartupManagement from "../../managers/startupManager";
import { AuthContext } from "../../../../contexts/authContext";
import { Checkbox, Stack } from "@mui/material";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { Link } from "react-router-dom";
import VerifyModal from "../../../userManagement/components/modals/VerifyModal";
import { tooltipText } from "../../constants/StartupDetailsContant";

const AwaitingTable = (props: any) => {
  const { searchByStartup, trigger } = props;
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const authContext = useContext(AuthContext);
  const startupManagement = new StartupManagement(authContext);
  const [page, setPage] = useState(1);

  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openMarkAsGoodModal, setOpenMarkAsGoodModal] = useState<any>(false);
  const [submittingAction, setSubmittingAction] = useState(false);

  const getCaDocStatus = async (pageNo: number) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { formattedData, count } =
        await startupManagement.getInitialScreeningDocs(
          "awaiting",
          pageNo,
          searchByStartup ?? ""
        );
      setFormattedData(formattedData);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleMark = async (id: string, flag: any) => {
    try {
      setSubmittingAction(true);
      const result = await startupManagement.initialScreeningMarkAsGood(
        id,
        flag
      );
      setSubmittingAction(false);
      toast.success(result?.detail);
      setOpenMarkAsGoodModal(false);
      getCaDocStatus(1);

      setPage(1);
    } catch (e: any) {
      setSubmittingAction(false);
      toast.error(e?.detail);
    }
  };

  const handleAceept = async (id: any) => {
    try {
      setSubmittingAction(true);
      const result = await startupManagement.initialScreeningAction(id, true);
      toast.success(result?.detail);
      setSubmittingAction(false);
      setOpenAcceptModal(false);
      getCaDocStatus(1);
      setPage(1);
    } catch (e: any) {
      setSubmittingAction(false);
      toast.error(e?.detail);
    }
  };

  const handleReject = async (id: any) => {
    try {
      setSubmittingAction(true);
      const result = await startupManagement.initialScreeningAction(id, false);
      toast.success(result?.detail);
      setSubmittingAction(false);
      setOpenRejectModal(false);
      getCaDocStatus(1);
      setPage(1);
    } catch (e: any) {
      setSubmittingAction(false);
      toast.error(e?.detail);
    }
  };

  const columns = [
    {
      field: "startup_name",
      headerName: "Startup Name",
      sortable: false,
      headerAlign: "left",
      flex: 2,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link
            to={`/startup/startup-panel/${params.row?.id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name}{" "}
          </Link>
        );
      },
      minWidth: 200,
    },
    {
      field: "startup_sector",
      headerName: "Startup's Sector",
      sortable: false,
      headerAlign: "left",
      flex: 2,
      minWidth: 300,
    },
    {
      field: "applied_on",
      headerName: "Applied On",
      headerAlign: "left",
      sortable: false,
      minWidth: 150,
    },

    {
      field: "referred_type_and_by",
      headerName: "Referred Type - Referred By",
      sortable: false,
      headerAlign: "left",
      flex: 2,
      minWidth: 300,
    },
    {
      field: "mark_as_good",
      headerName: "Mark as Good",
      sortable: false,
      flex: 1,
      minWidth: 120,
      align: "center",
      helpText: tooltipText.markAsGood,
      renderCell: (params: any) => {
        return (
          <Checkbox
            checked={params.row?.mark_as_good}
            onChange={(e) => {
              setOpenMarkAsGoodModal({
                id: params?.row?.id,
                flag: e.target.checked,
              });
            }}
          />
        );
      },
    },
    {
      field: "accept",
      headerName: "Accept",
      headerAlign: "center",
      sortable: false,
      helpText: tooltipText.accept,
      minWidth: 150,
      align: "center",
      renderCell: (params: any) => {
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("action_button", 300),
              borderColor: getColor("action_button", 300),
              width: "7rem",
            }}
            onClick={() => setOpenAcceptModal(params.row?.id)}
            tableAction
          >
            Accept
          </OutlinedButton>
        );
      },
    },
    {
      field: "reject",
      headerName: "Reject",
      headerAlign: "center",
      sortable: false,

      minWidth: 150,
      align: "center",
      renderCell: (params: any) => {
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("error", 500),
              borderColor: getColor("error", 500),
              width: "7rem",
            }}
            onClick={() => {
              setOpenRejectModal(params?.row?.id);
            }}
            tableAction
          >
            Reject
          </OutlinedButton>
        );
      },
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    getCaDocStatus(page + 1);
  };

  useEffect(() => {
    getCaDocStatus(1);
    setPage(1);
  }, [trigger]);

  if (apiStatus === API_STATUS.ERROR) {
    return (
      <div>
        <NoDataAvailable
          title="Error"
          subTitle={"Something went wrong"}
          image={IconConstant.DATA_NOT_FOUND_ICON}
        />
      </div>
    );
  }

  return apiStatus == API_STATUS.LOADING ? (
    <TablePlaceholder
      rows={6}
      columns={6}
      rowHeight="40px"
      columnHeaderHeight="40px"
    />
  ) : (
    <div>
      {openAcceptModal && (
        <VerifyModal
          title="Accept Startup"
          message="Are you sure you want to accept this startup from initial screening?"
          isOpen={openAcceptModal}
          handleClose={() => setOpenAcceptModal(false)}
          handleSubmit={() => handleAceept(openAcceptModal)}
          submitting={submittingAction}
        />
      )}
      {openRejectModal && (
        <VerifyModal
          title="Reject Startup"
          message="Are you sure you want to reject this startup from initial screening?"
          isOpen={openRejectModal}
          handleClose={() => setOpenRejectModal(false)}
          handleSubmit={() => handleReject(openRejectModal)}
          submitting={submittingAction}
        />
      )}
      {openMarkAsGoodModal && (
        <VerifyModal
          title={
            openMarkAsGoodModal?.flag ? "Mark as Good" : "Mark as Not Good"
          }
          message={`Are you sure to mark as ${
            openMarkAsGoodModal?.flag ? "Good" : "Not Good"
          }?`}
          isOpen={openMarkAsGoodModal}
          handleClose={() => setOpenMarkAsGoodModal(false)}
          handleSubmit={() =>
            handleMark(openMarkAsGoodModal?.id, openMarkAsGoodModal?.flag)
          }
          submitting={submittingAction}
        />
      )}
      <LvComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={60}
        minWidth={750}
        filterMode="server"
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={10}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
        rightPinnedColumns={["mark_as_good", "accept", "reject"]}
      />
    </div>
  );
};

export default AwaitingTable;
