import { useState, useContext } from "react";
import * as Yup from "yup";
import { Box, FormLabel, Stack } from "@mui/material";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../contexts/authContext";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import DocumentManagement from "../../../documentManagement/managers/documentManager";
import CommitmentManagementManager from "../../managers/commitmentManagementManager";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { Dropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import { InputField } from "../../../../ui-library/form-elements/CustomTextField";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { OrderTypeOptions } from "../../constants/CreatePaymentConstants";
import { CommonModal } from "../../../../ui-library/modals/common-modal";
import { FormContainer } from "../../../../ui-library/form-elements/FormContainer";
import { tooltipText } from "../../constants/CrmConstants";

const breadCrumbData = {
  stepKey: 1,
  steps: [{ title: "Commitment Management", titleDisplay: false }],
  pageHeader: {
    header: "Generate Payment Link",
    navigationButton: false,
    helpText: tooltipText.createPaymentLink,
  },
};

const initialValues = {
  selectedInvestor: "",
  investmentProfile: "",
  selectedStartup: "",
  orderType: "",
  amount: "",
};

const validationSchema = Yup.object().shape({
  selectedInvestor: Yup.object().required("Investor is required"),
  selectedStartup: Yup.object().when("orderType", {
    is: (val: any) => !["aif", "subscription"].includes(val?.value),
    then: (schema: any) => schema.required("Startup is required"),
  }),
  orderType: Yup.object().required("Order Type is required"),
  amount: Yup.number().required("Amount is required"),
  investmentProfile: Yup.object().required("Investment Profile is required"),
});

export default function CreatePaymentLink() {
  const userData = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(userData);
  const documentManagementManager = new DocumentManagement(userData);
  const commitmentManagement = new CommitmentManagementManager();

  const [nameEmailOptions, setNameEmailOptions] = useState<any>([]);
  const [investmentProfileOptions, setInvestmentProfileOptions] = useState<any>(
    []
  );
  const [startupOptions, setStartupOptions] = useState<any>([]);
  const [generating, setGenerating] = useState(false);
  const [showLink, setShowLink] = useState("");
  const [handleModal, setHandleModal] = useState(false);

  const handleInvestorSearch = async (value: any) => {
    try {
      const result = await userManagementManager.getLookupData({
        search: value,
        type: "only_investor_users",
      });
      setNameEmailOptions(
        result?.results.map((item: any) => ({
          label: item.text,
          value: item.id,
        }))
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleProfileSearch = async (value: any, setFieldValue: any) => {
    try {
      if (!value?.value) return;
      let result = await userManagementManager.getInvestmentProfile(
        value?.value
      );
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setInvestmentProfileOptions(result);
      setFieldValue("investmentProfile", result[0]);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearchStartup = async (value: any) => {
    try {
      const result = await documentManagementManager.searchDealName(value);
      setStartupOptions(
        result?.data.map((item: any) => ({ label: item.text, value: item.id }))
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handlePaymentLink = async (values: any, actions: any) => {
    try {
      setGenerating(true);
      const result = await commitmentManagement.createPaymentLink(values);
      toast.success("Payment link generated successfully");
      if (result) {
        actions.resetForm();

        setShowLink(result?.payment_link);
        setHandleModal(true);
      }
      setGenerating(false);
    } catch (e: any) {
      toast.error(e.message);
      setGenerating(false);
    }
  };

  const handleModalState = () => {
    setHandleModal(!handleModal);
  };

  return (
    <Stack>
      <Breadcrumb
        steps={breadCrumbData.steps}
        pageHeader={breadCrumbData.pageHeader}
        stepKey={breadCrumbData.stepKey}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handlePaymentLink}
      >
        {({ values, touched, errors, setFieldValue, handleSubmit }: any) => {
          return (
            <FormContainer>
              <Form>
                <Stack gap={2} mb={3}>
                  <LvAutoComplete
                    label="Investor Name or Email"
                    options={nameEmailOptions}
                    setOptions={setNameEmailOptions}
                    selectedObject={values.selectedInvestor}
                    setSelectedObject={(value: any) =>
                      setFieldValue("selectedInvestor", value)
                    }
                    onChange={handleInvestorSearch}
                    onSelect={(value: any) =>
                      handleProfileSearch(value, setFieldValue)
                    }
                    required
                    error={
                      !!errors.selectedInvestor &&
                      touched.selectedInvestor &&
                      errors.selectedInvestor
                    }
                  />
                  <Dropdown
                    label="Investment Profile"
                    fieldName="investmentProfile"
                    id="investmentProfile"
                    name="investmentProfile"
                    value={values.investmentProfile}
                    error={!!errors.investmentProfile}
                    options={investmentProfileOptions}
                    disabled
                    required
                  />

                  <Dropdown
                    label="Order Type"
                    required
                    options={OrderTypeOptions}
                    value={values.orderType}
                    error={!!errors.orderType && touched.orderType}
                    id="orderType"
                    name="orderType"
                    fieldName="orderType"
                  />

                  {!["aif", "subscription"]?.includes(
                    values?.orderType?.value
                  ) && (
                    <LvAutoComplete
                      label="Select Startup"
                      options={startupOptions}
                      setOptions={setStartupOptions}
                      selectedObject={values.selectedStartup}
                      setSelectedObject={(value: any) =>
                        setFieldValue("selectedStartup", value)
                      }
                      onChange={handleSearchStartup}
                      required
                      error={
                        !!errors.selectedStartup &&
                        touched.selectedStartup &&
                        errors.selectedStartup
                      }
                    />
                  )}
                  <InputField
                    label="Amount"
                    fieldName="amount"
                    id="amount"
                    name="amount"
                    value={values.amount}
                    type="number"
                    error={!!errors.amount}
                    touched={touched.amount}
                    required
                  />
                  <Stack direction="row" justifyContent="end" mt={2}>
                    <ActionButton
                      onClick={handleSubmit}
                      isLoading={generating}
                      disabled={generating}
                    >
                      {generating ? "Generating..." : "Generate Payment Link"}
                    </ActionButton>
                  </Stack>
                </Stack>
              </Form>
            </FormContainer>
          );
        }}
      </Formik>

      <CommonModal
        isOpen={handleModal}
        handleClose={handleModalState}
        handleSubmit={() => {
          navigator.clipboard.writeText(showLink);
          toast.success("Link copied to clipboard");
        }}
        title="Generated Payment Link"
        submitText="Copy Link"
        cancelText="Close"
        buttonStyles={{ width: "100%" }}
        closeIcon
      >
        <a href={showLink} target="_blank" rel="noopener noreferrer">
          {showLink}
        </a>
      </CommonModal>
    </Stack>
  );
}
