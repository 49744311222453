import axios from "axios";
import { toast } from "react-toastify";

const setInterceptors = (
  accessToken: string | void,
  getAccessTokenSilently: any = null,
  setAuthState: any = null,
  navigate: any = null
) => {
  const reqInter = axios.interceptors.request.use(
    async (config) => {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.data) {
        try {
          const { status } = error.response;
          if (status === 490 && getAccessTokenSilently) {
            const accessTokenNew = await getAccessTokenSilently();
            const originalRequest = error.config;
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${accessTokenNew}`;

            setAuthState((prevState: any) => ({
              ...prevState,
              token: accessTokenNew,
            }));

            axios.interceptors.request.eject(reqInter);
            return axios.create().request(originalRequest);
          } else if (status === 403) {
            if (navigate) navigate("/dashboard");
            else window.location.href = "/dashboard";
            toast?.error(
              error.response?.data?.detail ?? "something went wrong"
            );
          }
          return Promise.reject(error?.response?.data);
        } catch (err) {
          console.error("setInterceptors -> response -> err", err);
        }
      }
      return Promise.reject(error);
    }
  );
};

const setHeader = () => {
  axios.defaults.headers.common["Content-Type"] = "application/json";
};

export { setInterceptors, setHeader };
