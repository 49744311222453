import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import {
  ActionType,
  KycStageOptions,
  KycStatusTabs,
  KycStatusType,
  ReviewStatusOptions,
} from "./KycStatusConstant";
import { API_STATUS } from "../../../../constants/commonConstants";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import UserManagementManager from "../../managers/userManagementManager";
import { Link } from "react-router-dom";
import UpdateReviewStatusModal from "./UpdateReviewStatusModal";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../contexts/authContext";
import { useContext } from "react";
import { CoreDropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionDropdown } from "../../../../ui-library/buttons/ActionDropdown/ActionDropdown";
import { tooltipText } from "../../constants/common";

export default function KycStatus() {
  const userData = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(userData);

  const [selectedInvestor, setSelectedInvestor] = useState<any>({});
  const [investorOptions, setInvestorOptions] = useState<any>([]);

  const [selectedTab, setSelectedTab] = useState(KycStatusTabs[0].value);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const [selected, setSelected] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isOnboarded, setIsOnboarded] = useState<any>();

  const closeModal = () => {
    setOpenModal(false);
  };
  const viewFile = (url: string) => {
    window.open(url, "_blank");
  };

  let columns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      minWidth: 180,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link
            style={{ color: getColor("action_button", 300) }}
            to={`/user/user-panel/${params.row?.user_id}/`}
          >
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "lv_review_stage",
      headerName: "LV Review Stage",
      flex: 2,
      minWidth: 150,
    },

    {
      field: "kyc_progress",
      headerName: "Kyc Progress",
      headerAlign: "left",
      flex: 2,
      minWidth: 220,
    },
    {
      field: "kyc_matches",
      headerName: "KYC Matches",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "ckyc_no",
      headerName: "CKYC No",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "kra_matches",
      headerName: "KRA Matches",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "kra_id",
      headerName: "KRA No",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "onboarding_complete",
      headerName: "Investment Profile Created",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "name_as_per_pan",
      headerName: "Name as per PAN",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      flex: 2,
      minWidth: 150,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "country_of_citizenship",
      headerName: "Country of Citizenship",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "country_of_residence",
      headerName: "Country of Residence",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "relation_type",
      headerName: "Relation Type",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "relation_name",
      headerName: "Relation Name",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },

    {
      field: "permanent_address",
      headerName: "Permanent Address",
      flex: 2,
      minWidth: 300,
      headerAlign: "left",
    },

    {
      field: "correspondence_address",
      headerName: "Correspondence Address",
      flex: 2,
      minWidth: 300,
      headerAlign: "left",
    },

    {
      field: "bank_account_type",
      headerName: "Bank Account Type",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "bank_account_number",
      headerName: "Bank Account Number",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },
    {
      field: "ifsc_code",
      headerName: "IFSC/SWIFT code",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },

    {
      field: "comments",
      headerName: "Comment",
      flex: 2,
      minWidth: 150,
      headerAlign: "left",
    },

    {
      field: "action_dropdown",
      headerName: "Action",
      flex: 3,
      minWidth: 100,
      align: "center",
      renderCell: (params: any) => {
        const ActionOptions = [
          {
            label: ActionType.view_pan,
            disabled: !params.row.pan_card_file,
          },
          {
            label: ActionType.view_address_proof,
            disabled: !params.row.proof_of_address_file,
          },
          {
            label: ActionType.view_cancelled_cheque,
            disabled: !params.row.cancelled_cheque_file,
          },
          {
            label: ActionType.update_review_status,
          },
        ];
        const handleOnClickOption = (option: any) => {
          switch (option) {
            case ActionType.view_pan:
              viewFile(params?.row.pan_card_file);
              break;
            case ActionType.view_address_proof:
              viewFile(params?.row.proof_of_address_file);
              break;
            case ActionType.view_cancelled_cheque:
              viewFile(params?.row.cancelled_cheque_file);
              break;
            case ActionType.update_review_status:
              setSelected(params.row);
              setOpenModal(true);
              break;
            default:
              break;
          }
        };
        return (
          <ActionDropdown
            options={ActionOptions}
            onOptionClick={handleOnClickOption}
          />
        );
      },
    },
  ];

  const getColumns = (tab: any) => {
    return tab === KycStatusType.VERIFIED
      ? columns.filter((item: any) => item.field !== "action")
      : columns;
  };

  const handleInvestorSearch = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData({
        search: value,
        type: "only_investor_users",
      });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setInvestorOptions(result);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const getData = async (pageNo: number) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { count, results } = await userManagementManager.getKycStatusData(
        pageNo,
        selectedInvestor?.value ?? "",
        selectedTab,
        isOnboarded?.value ?? ""
      );

      results = results?.map((item: any) => ({
        ...item,
        lv_review_stage:
          ReviewStatusOptions?.find(
            (it: any) => it?.value === item.lv_review_stage
          )?.label ?? "-",
        kyc_matches: item?.kyc_matches ? "Yes" : "No",
        kra_matches: item?.kra_matches ? "Yes" : "No",
        onboarding_complete: item?.onboarding_complete ? "Yes" : "No",
        kyc_progress:
          KycStageOptions?.find((it: any) => it?.value === item.kyc_progress)
            ?.label ?? "-",
      }));

      setFormattedData(results);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (er: any) {
      setApiStatus(API_STATUS.ERROR);

      toast.error(er);
    }
  };
  useEffect(() => {
    getData(1);
  }, [selectedInvestor?.value, selectedTab, isOnboarded?.value]);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    getData(page + 1);
  };

  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "User Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: "KYC Status",
      navigationButton: false,
      helpText: tooltipText.kycStatus,
    },
  };

  return (
    <Box>
      {openModal && (
        <UpdateReviewStatusModal
          selected={selected}
          isOpen={openModal}
          handleClose={closeModal}
          reload={getData}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <Box width={"40%"}>
            <CoreDropdown
              label="Investment Profile Created"
              options={[
                { label: "Choose Profile Status" },
                {
                  label: "Yes",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                },
              ]}
              value={isOnboarded}
              onClickMenuItem={(val: any) => {
                setIsOnboarded(val);
                setPage(1);
              }}
            />
          </Box>
          <Box width="23vw">
            <LvAutoComplete
              label={"Select Investor"}
              options={investorOptions}
              setOptions={setInvestorOptions}
              selectedObject={selectedInvestor}
              setSelectedObject={setSelectedInvestor}
              onSelect={() => {
                setPage(1);
                setInvestorOptions([]);
              }}
              onChange={handleInvestorSearch}
            />
          </Box>
        </Grid>
      </Grid>

      <HorizontalTabs
        tabs={KycStatusTabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
          setPage(1);
        }}
        scrollable={false}
      />
      <br />
      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={getColumns(selectedTab)}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
            rightPinnedColumns={["action_dropdown"]}
          />
        </>
      )}
    </Box>
  );
}
