import * as Yup from "yup";

export const tooltipText = {
  caDocManagement:
    "This tab is used to send ca docs to investor who has not signed ca doc from digital KYC or to send ca doc to investor who has onbaorded Manually or whose Ca doc has been expired.",
  caDocStatus:
    "This tab stores the data of investors to whom the ca doc has been sent and who have signed the ca doc, or to know whether the LV trustee or LV Authorised has signed ca doc which investor has signed already.",
  caDocPaymentStatus:
    "This tab stores information of Indian and NRO investors who has paid the AIF fee and not Paid the AIF fee to get onboard to LV AIF.",
  foriegnPaymentStatus:
    "This tab stores information of Foreign and NRE investors who has paid the AIF fee and not Paid the AIF fee to get onboard to LV AIF.",
  schemeDocManagement:
    "This tab is used to generate scheme for the startup and also to check the investors who have consented to the scheme of particular startup.",
};

export const breadCrumbDataCADocManagement = {
  stepKey: 1,
  steps: [
    {
      title: "AIF Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "CA Doc Management",
    navigationButton: false,
    helpText: tooltipText.caDocManagement,
  },
};

export const CADoctabs: any = [
  {
    name: "Generate CA Document",
    value: "generate_ca_document",
  },
  {
    name: "Approve CA Process",
    value: "approve_ca_process",
    disabled: true,
  },
];

export const breadCrumbDataSchemeDoc = {
  stepKey: 1,
  steps: [
    {
      title: "AIF Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Scheme Doc Management",
    navigationButton: false,
    helpText: tooltipText.schemeDocManagement,
  },
};

export const StartCaGenerationFormSchema = Yup.object().shape({
  date: Yup.date().required("Date is required"),
  onboardingFee: Yup.number().required("Onboarding Fee is required"),
  upfrontDrawdown: Yup.number().required("Upfront Drawdown is required"),
  placementFee: Yup.number().required("Placement Fee is required"),
  isKycVerified: Yup.string().required("KYC Verification is required"),
  kycNumber: Yup.string().when("isKycVerified", {
    is: (val: any) => val == "yes",
    then: (schema) => schema.required("KYC Number is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  isKraVerified: Yup.string().required("KRA Verification is required"),
  kraNumber: Yup.string().when("isKraVerified", {
    is: (val: any) => val == "yes",
    then: (schema) => schema.required("KRA Number is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export const schemeFormPlaceholderConfig = Array(15).fill("input");
