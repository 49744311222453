import { API_STATUS } from "../../../../constants/commonConstants";
import { useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { useEffect } from "react";
import {
  DrawdownBreadCrumbData,
  OrderType,
  tabsOptions,
} from "../../constants/FeePaymentStatusConstant";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import DocumentManagement from "../../../documentManagement/managers/documentManager";
import { toast } from "react-toastify";
import CommitmentManagementManager from "../../managers/commitmentManagementManager";
import { getFormattedDate } from "../../../../utils/dateUtils";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { Link } from "react-router-dom";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { CopyAll } from "@mui/icons-material";

export default function DrawdownPaymentStatus() {
  const userData = useContext(AuthContext);
  const documentManagementManager = new DocumentManagement(userData);
  const commitmentManager = new CommitmentManagementManager();
  const [selectedTab, setSelectedTab] = useState(tabsOptions[0].value);
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const [selectedStartup, setSelectedStartup] = useState<any>({});
  const [startupOptions, setStartupOptions] = useState<any>([]);
  const handleSearchStartup = async (value: string) => {
    try {
      let result = await documentManagementManager.searchDealName(value);
      result = result?.data.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setStartupOptions(result);
    } catch (error: any) {
      toast.error(error);
    }
  };

  let columns: any = [
    {
      field: "startup_name",
      headerName: "Startup Name",
      headerAlign: "left",
      flex: 2,
      minWidth: 200,
      renderCell: (params: any) => (
        <Link
          to={`/startup/startup-panel/${params.row?.id}/`}
          style={{ color: getColor("action_button", 300) }}
        >
          {params?.row?.startup_name}{" "}
        </Link>
      ),
    },
    {
      field: "user_id",
      headerName: "User Id",
      headerAlign: "left",
      minWidth: 100,
      renderCell: (params: any) =>
        params?.row?.investor_informations?.investor?.user?.id ?? "-",
    },
    {
      field: "profile_name",
      headerAlign: "left",
      headerName: "Profile Name",

      minWidth: 150,
    },
    {
      field: "email",
      headerAlign: "left",
      headerName: "Investor Email",

      minWidth: 300,
    },
    {
      field: "order_type",
      headerAlign: "left",
      headerName: "Order Type",

      minWidth: 150,
    },

    {
      field: "created_date",
      headerName: "Created Date",
      flex: 2,
      minWidth: 150,
      headerAlign: "right",
      align: "right",
    },

    {
      field: "last_updated_date",
      headerName: "Last Updated Date",
      flex: 2,
      minWidth: 150,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "amount",
      headerName: "Amount",
      align: "right",
      headerAlign: "right",
      minWidth: 100,
    },
  ];

  let pendingColumns: any = [...columns];
  pendingColumns.splice(pendingColumns.length, 0, {
    field: "payment_link",
    headerName: "Payment Link",
    align: "center",
    minWidth: 140,
    headerAlign: "center",
    renderCell: (params: any) => {
      return params?.row?.payment_link ? (
        <IconButton
          size="small"
          onClick={() => {
            navigator.clipboard.writeText(params?.row?.payment_link);
            toast.success("Payment link copied to clipboard");
          }}
        >
          <CopyAll sx={{ color: getColor("action_button", 300) }} />
        </IconButton>
      ) : (
        <>-</>
      );
    },
  });

  const getColumns = (tab: any) => {
    return tab === "pending" ? pendingColumns : columns;
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
    getData(page + 1);
  };

  const getData = async (pageNo: number) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { count, results } = await commitmentManager.drawdownPaymentStatus(
        pageNo,
        selectedStartup?.value ?? "",
        selectedTab
      );
      setCount(count);
      let result = results?.map((item: any) => ({
        ...item,
        user_id: item?.investor_informations?.investor?.user?.id,
        startup_name: item?.startups?.name,
        profile_name: item?.investor_informations?.profile_name,
        email: item?.investor_informations?.investor?.user?.email,
        order_type:
          OrderType?.find((it: any) => it?.value === item?.order_type)?.label ??
          "-",
        payment_link: item?.order?.payment_link,
        created_date: getFormattedDate(item?.created_date),
        last_updated_date: getFormattedDate(item?.last_updated_date),
      }));

      setFormattedData(result);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (e: any) {
      toast.error(e);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  useEffect(() => {
    getData(1);
  }, [selectedStartup?.value, selectedTab]);
  return (
    <Box>
      <Grid container spacing={3} marginBottom="1rem">
        <Grid item xs={4}>
          <Breadcrumb
            steps={DrawdownBreadCrumbData?.steps}
            pageHeader={DrawdownBreadCrumbData.pageHeader as any}
            stepKey={DrawdownBreadCrumbData.stepKey}
          />
        </Grid>
        <Grid item xs={8} display="flex" justifyContent="end" gap={2}>
          <Box width="20vw">
            <LvAutoComplete
              label="Select Startup"
              options={startupOptions}
              setOptions={setStartupOptions}
              selectedObject={selectedStartup}
              setSelectedObject={setSelectedStartup}
              onSelect={() => {
                setPage(1);
                setStartupOptions([]);
              }}
              onChange={handleSearchStartup}
            />
          </Box>
        </Grid>
      </Grid>

      <HorizontalTabs
        tabs={tabsOptions}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
          setPage(1);
        }}
        scrollable={false}
      />

      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={getColumns(selectedTab)}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
            rightPinnedColumns={["amount", "payment_link"]}
          />
        </>
      )}
    </Box>
  );
}
