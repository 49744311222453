import { useState } from "react";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import AwaitingTable from "./awaitingTable";
import RejectedTable from "./rejectedTable";
import { Box, Grid, Stack } from "@mui/material";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { InitialScreeningTabs } from "../../constants/InitialScreeningConstant";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";

export default function CaDocStatus() {
  const [selectedTab, setSelectedTab] = useState(InitialScreeningTabs[0].value);
  const [searchByStartup, setSearchByStartup] = useState("");
  const [trigger, setTrigger] = useState(false);

  const handleTabChange = (tab: any) => {
    setSelectedTab(tab);
  };

  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "Startup Management",
        titleDisplay: true,
      },
    ],
    pageHeader: {
      header: "Initial Screening",
      navigationButton: false,
    },
  };

  const handleSearch = () => {
    setTrigger((trigger) => !trigger);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
        <Grid
          item
          xs={9}
          display="flex"
          gap={2}
          justifyContent="end"
          // flexWrap="wrap"
        >
          <Stack direction="row" gap={2}>
            <Box>
              <CustomTextField
                label={"Search By Startup"}
                name="startup_name"
                value={searchByStartup}
                onChange={(val: string) => setSearchByStartup(val)}
              />
            </Box>

            <ActionButton onClick={handleSearch}>Search</ActionButton>
          </Stack>
        </Grid>
      </Grid>

      <div>
        <HorizontalTabs
          tabs={InitialScreeningTabs}
          selectedTab={selectedTab}
          onTabChange={handleTabChange}
          xAxisPadding={false}
          customTabsStyles={{
            width: "100%",
            height: "44px",
          }}
          customTabStyles={{
            fontFamily: "Work Sans",
            lineHeight: "21px",
            textAlign: "center",
            letterSpacing: "0.5px",
            fontStyle: "normal",
          }}
        />
      </div>

      {selectedTab === "awaiting" && (
        <AwaitingTable searchByStartup={searchByStartup} trigger={trigger} />
      )}
      {selectedTab === "rejected" && (
        <RejectedTable searchByStartup={searchByStartup} trigger={trigger} />
      )}
    </Box>
  );
}
