import LockFundraisingActionTooltip from "../components/startupPanel/showDetails/LockFundraisingActionTooltip";

export const startupDetailsBreadCrumbData = {
  stepKey: 2,
  steps: [
    {
      title: "Startup Management",
      titleDisplay: true,
      key: "startup-management",
    },
    {
      title: "Startup Details",
      titleDisplay: true,
      key: "startup-details",
    },
  ],
  pageHeader: {
    header: "Startup Details",
    navigationButton: true,
  },
};

export const EditFundraiseDetailsBreadCrumbData = {
  stepKey: 3,
  breadcrumbSeparator: true,
  steps: [
    {
      title: "Startup Management",
      titleDisplay: true,
    },
    {
      title: "Fundraise Details",
      titleDisplay: true,
    },
    { title: "Edit Fundraise Details", titleDisplay: true },
  ],
  pageHeader: {
    header: "Edit Fundraise Details",
    navigationButton: true,
  },
};

export const StartupDetailTabs: any = [
  {
    name: "Basic Details",
    value: "basic_details",
  },
  {
    name: "Fundraise Details",
    value: "fundraise_details",
  },
  {
    name: "Screening Details",
    value: "screening_details",
  },
  {
    name: "Portfolio Details",
    value: "portfolio_details",
  },
];

export const visibilityOptions = [
  { value: 1, label: "Limited Visibility to Investors" },
  { value: 3, label: "Visible to All Investors" },
  { value: 4, label: "Stealth/Private" },
];

export const keyMapping = {
  // ### Basic Details ###
  dealDashboardAccess: "deal_dashboard_access",
  published: "publish",
  visibility: "complete_profile_privacy",
  superVisibility: "super_stealth",
  selectedPitchingFounder: "pitching_founder_id",
  typeOfStartup: "startup_type",
  privateSyndicateId: "private_syndicate_id",

  // ### Fundraising Details ###

  syndicateCloseThresholdPercent: "commit_threshold",
  fundCarryPercent: "fund_carry",
  leadCarryPercent: "lead_carry",
  leadInvestors: "update_lead_lead_carry",
  hurdleRate: "hurdle_rate",
  transactionFeesPercent: "default_transaction_fee",
  defaultTransactionFee: "default_transaction_fee",
  transactionFeesAmount: "transaction_fees_amount",
  minCommitment: "min_commitment",
  lvAllocation: "lv_allocated_deal_amount",
  schemeConsentToggle: "scheme_consent_switch",
  showCommitmentSwitch: "lead_show_commitment",
  startupDD: "update_startup_dd",
  proRataRights: "update_startup_prr",
  lightningDeal: "lightning_deal_status",
  maxNoOfCommits: "max_commits",
  minCommitAmount: "min_commit_amount",
  percentageOfRound: "percentage_of_round",
  startDate: "start_date",
  endDate: "end_date",
  pitchingSession: "pitching_medias",

  // ### Portfolio Details ###

  fundPanNumber: "pan_no",
  selectedPrimaryStartup: "primary_startup_id",

  // ### Dropdown Actions ###
  markTrending: "trending_status",
  markFeatured: "mark_featured",
  removeFeatured: "remove_featured",
  markFunded: "mark_funded",
  markFundedOutsideLV: "mark_funded_outside_lv",
  lockEditProfilePage: "lock_edit_profile",
};

export const initialSelectedAction = {
  markTrending: false,
  removeTrending: false,
  markFeatured: false,
  removeFeatured: false,
  markFunded: false,
  markFundedOutsideLV: false,
  lockEditProfilePage: false,
  unlockEditProfilePage: false,
};

export const startupActionModalTitle: any = {
  markTrending: "Are you sure you want to mark this startup as Trending?",
  removeTrending: "Are you sure you want to remove this startup from Trending?",
  markFeatured: "Are you sure you want to mark this startup as Featured?",
  removeFeatured: "Are you sure you want to remove this startup from Featured?",
  markFunded: "Are you sure you want to mark this startup as Funded?",
  markFundedOutsideLV:
    "Are you sure you want to mark this startup as Funded Outside LV?",
  lockEditProfilePage: "Are you sure you want to lock the Edit Profile Page?",
  unlockEditProfilePage:
    "Are you sure you want to unlock the Edit Profile Page?",
};

export const getActionDropdownOptions = (startupDetails: any) => {
  if (
    startupDetails?.final_screening_approved &&
    startupDetails?.is_scheme_verified &&
    startupDetails?.pitching_founder
  )
    return [
      {
        key: "updateStartup",
        label: "Update Startup",
      },
      {
        key: "cloneStartup",
        label: "Clone Startup",
      },
      {
        key: "markTrending",
        label: "Mark as Trending",
        hidden: !startupDetails?.can_make_trending,
        disabledReason: "Startup is already trending",
      },
      {
        key: "removeTrending",
        label: "Remove from Trending",
        hidden: !startupDetails?.can_remove_trending,
        disabledReason: "Startup is not trending",
      },
      {
        key: "markFeatured",
        label: "Mark as Featured",
        hidden: !startupDetails?.can_make_featured,
        disabledReason: "Startup is already featured",
      },
      {
        key: "removeFeatured",
        label: "Remove from Featured",
        hidden: !startupDetails?.can_remove_featured,
        disabledReason: "Startup is not featured",
      },
      {
        key: "markFunded",
        label: "Mark as Funded",
        disabled: !startupDetails?.can_mark_funded_on_lv,
        disabledReason: "Startup is already funded",
      },
      {
        key: "markFundedOutsideLV",
        label: "Mark as Funded Outside LV",
        disabled: !startupDetails?.can_make_funded_outside,
        disabledReason: "Startup is already funded outside LV",
      },
      {
        key: "lockEditProfilePage",
        label: "Lock Edit Profile Page",
        hidden:
          startupDetails?.is_featured || startupDetails?.lock_edit_profile,
        disabledReason: "Edit profile page is already locked",
      },
      {
        key: "unlockEditProfilePage",
        label: "Unlock Edit Profile Page",
        hidden:
          !startupDetails?.is_featured && !startupDetails?.lock_edit_profile,
        disabledReason: "Edit profile page is not locked",
      },
    ];
  else
    return [
      {
        key: "updateStartup",
        label: "Update Startup",
      },
      {
        key: "cloneStartup",
        label: "Clone Startup",
      },
      {
        label: "Mark as Trending",
        disabled: true,
        tooltipVariant: "link",
        disabledReason: (
          <LockFundraisingActionTooltip startupDetails={startupDetails} />
        ),
      },

      {
        key: "markFeatured",
        label: "Mark as Featured",
        tooltipVariant: "link",
        disabled: true,
        disabledReason: (
          <LockFundraisingActionTooltip startupDetails={startupDetails} />
        ),
      },

      {
        key: "markFunded",
        label: "Mark as Funded",
        tooltipVariant: "link",
        disabled: true,
        disabledReason: (
          <LockFundraisingActionTooltip startupDetails={startupDetails} />
        ),
      },
      {
        key: "markFundedOutsideLV",
        label: "Mark as Funded Outside LV",
        tooltipVariant: "link",
        disabled: true,
        disabledReason: (
          <LockFundraisingActionTooltip startupDetails={startupDetails} />
        ),
      },
    ];
};

export const getDropdownActionData = (selectedAction: any) => {
  const actionMapping: { [key: string]: any } = {
    markTrending: { markTrending: true },
    removeTrending: { markTrending: false },
    markFeatured: { markFeatured: true },
    removeFeatured: { removeFeatured: true },
    markFunded: { markFunded: true },
    markFundedOutsideLV: { markFundedOutsideLV: true },
    lockEditProfilePage: { lockEditProfilePage: true },
    unlockEditProfilePage: { lockEditProfilePage: false },
  };

  for (const key in actionMapping) {
    if (selectedAction?.[key]) {
      return actionMapping[key];
    }
  }

  return {};
};

export const STARTUP_TYPE = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "faas",
    label: "FAAS",
  },
  {
    label: "Public",
    value: "non_faas",
  },
];

export const STARTUP_STATUS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Live",
    value: "live",
  },
  {
    label: "Funded",
    value: "funded",
  },
  {
    label: "Fundraising Closed",
    value: "closed",
  },
];

export const tooltipText = {
  entityName: "This is the legal name of the company as per ROC ",
  schemeName:
    "for each deal that goes live on LV platform, the deals team creates a scheme in ZOHO, which captures details for ex:- SEBI term sheet, deal terms(lead carry, pre money valuation, etc)",
  investmentType:
    "AIF means invested via LV Angel Fund (AIF). Deals Prior to 2018 have investment type = Direct, meaning that the investors were directly on the captable of the startup post investment and each investor would transfer money directly to the startup.",
  pitchingFounder: (
    <p>
      This is set by LV Admin only. Email notifications for example - pitch deck
      requests, financial and captable requests are sent only to this founder's
      email id.
      <br />
      Also, without setting the pitching founder, the deal can't be marked as
      FEATURED.
    </p>
  ),
  published:
    "Deal card and details will not be visible to investors unless published is marked as YES by LV Admin",
  dealDashboardAccessibility:
    "If set to NO by LV Admin, when the startup logs in, he won't be able to access his deal dashboard which shows the progress of the deal - total commits, investor names etc",
  visibility:
    "All Investors - deal is visible to all investors who have a live subscription",
  superVisibility: (
    <p>
      By default, for new investors who signs up on the platform and has 15 days
      free trial period, LV platform allows all deals (excluding FaaS) are shown
      to the investors.
      <br />
      However, if you mark Super Visibility as YES then this deal will NOT be
      shown even to the trial period investors.
    </p>
  ),
  startupAsPrivateNormalSyndicate: (
    <p>
      Normally this is set as NO. However, if you want to set a deal as a
      private syndicate deal, change this to YES.
      <br />
      You will also have to select the private syndicate name this deal should
      be associated with.
    </p>
  ),
  startupAsPrivateZenoSyndicate: (
    <p>
      Normally this is set as NO. However, if you want to set a deal as a Zeno
      deal, change this to YES.
      <br />
      You will also have to select the Zeno name this deal should be associated
      with.
    </p>
  ),
  profileCreated: "when the startup first signed up on the platform",
  profileUpdated:
    "any updates to the profile either by the Startup or by LV admin updates this field",
  fundraisingSince: "Date when startup got featured or Trending",
  funded:
    "Once all the investor money has been recieved and all the legal and complaince work in completed and startup has recieved the money from LV AIF, the deal gets marked as FUNDED.",
  syndicateCloseThresholdPercent:
    "Deals that are live get automatically marked as CLOSED, once the total commitment amount by all investors go beyond this percentage value.",
  fundCarryPercent: (
    <p>
      deal terms that are visible to investors on the startup profile. <br />
      This is the total carry % that the fund charges to investors. This equals
      Lead Carry + Platform Carry
    </p>
  ),
  leadCarry: (
    <p>
      deal terms that are visible to investors on the startup profile.
      <br />
      From the total carry %, Lead investor takes this much carry. Rest is taken
      by the LV platform
    </p>
  ),
  hurdleRate: (
    <p>
      deal terms that are visible to investors on the startup profile.
      <br />
      Hurdle rate is the minimum rate of return only beyond which the carry
      percentage is calculated. Ask ChatGPT to learn more
    </p>
  ),
  lvAllocation: (
    <p>
      Important: this is the fundraise amount that is being raised on the LV
      platform.
    </p>
  ),
  lightningDeal: (
    <p>
      If set to yes, the deal can have a smaller minimum commitment amount
      during the period specified in the admin dashboad.
      <br />
      After the time limit expires, the minimum commitment amount is
      automatically increased to the default value.
    </p>
  ),
  transactionFeesPercent: (
    <p>
      Important: what % of commit amount is charged as transaction fees? <br />
      Total amount investor has to pay = commitment amount + transaction fees +
      deal processing charge.
      <br />
      Also GST is charged on transaction fees and deal processing charge.
    </p>
  ),
  transactionFeesAmount:
    "Transaction fees amount calculated based on the % specified",
  minimumCommitment:
    "Important: minimum amount an investor can commit into a startup",
  mou: "Signed agreement between Startup and LetsVenture containing commercial and other terms for fundraise via LV platform.",
  writeUp:
    "This is provided by Startup but is visible only to LV admins and NOT to investors.",
  leadInvestors:
    "This data comes from the startup profile and is provided by the startup. The Lead investor names are also shown to the investors when they view the startup profile.",
  showLeadInvestorCommitmentSwitch:
    "If this is set to YES, the lead investor's commitment amount is also shown to the other investors on the startup profile.",
  startupDD:
    "This is shown to the investors on the startup profile. It signifies whether DD for this deal is completed or not.",
  proRataRights:
    "This is shown to the investors on the startup profile. It signifies whether Pro Rata rights for this deal is available or not.",
  switchToToggleSchemeConsent:
    "If this is set to YES, investors won't be asked for scheme consent while committing to the deal. The consents have to be taken separately by the AIF team via email or so.",
  startupPoc:
    "This is an internal field and can be used by LV Admin to set the name of the POC from the startup side.",
  pitchingSession:
    "These are video recordings of the startup pitch and Q&A sessions. All of these are visible to the investors in the startup profile.",
  syndicateStarted:
    "Date when startup was marked as Featured and went live on the platform",
  syndicateClosed: (
    <p>
      Once the live deals have finished fundraising and are marked as fundrasing
      closed in the startup panel in the admin dashboard,
      <br />
      these deal will not be visible to investors on the platform and the deal
      will move into AIF team for completing the next set of compliances
    </p>
  ),
  syndicateFunded: (
    <p>
      Once all the investor money has been recieved and all the legal and
      complaince work in completed and startup has recieved the money from LV
      AIF, the deal should be marked as funded.
    </p>
  ),
  initialScreenApprovedBy: (
    <p>
      In LV platform, startup evalution happens in two stages - Initial and
      final screening.
      <br />
      This is done by startups team.
      <br />
      Only after the Initial screening is approved the startup will get access
      to its detailed profile to update.
      <br />
      Only after final screening is approved the startup can be marked featured
      and made live to investors.
    </p>
  ),
  showAllCommitments: (
    <p>
      clicking on this link will show you all investors who have committed into
      the deal, amounts, date etc.
    </p>
  ),
  showAllInvExitDetail: (
    <p>
      This data comes from Zoho. All "investor level exits" records from Zoho
      are synced to the LV platform and can be seen from here. This data is
      originally provided by Kratos.
    </p>
  ),
  sharePriceAtFirst:
    'Read only - this data comes from Zoho from the "Active Valuations" module.',
  preMoneyValuationInvTime:
    'Read only - this data comes from Zoho from the "Active Valuations" module.',
  preMoenyValuation:
    'Read only - this data comes from Zoho from the "Active Valuations" module.',
  investmentAmount:
    "This is total funding amount that was raised via LetsVenture platform",
  dateOfTransfer:
    "Date on which the funds were transferred to the Startup from LV Angel Fund bank account",
  gpContribution:
    "GP in our case is the fund manager entity (Lets Venture Advisors LLP). This amount reflects the investment made by the LLP into the deal on behalf of LetsVenture.",
  primaryStartup: (
    <p>
      If a Startup raises multiple rounds on LetsVenture, the first startup ID
      is called primary ID.
      <br />
      For the subsequent rounds, the startup profile should have the primary
      startup ID set to the original startup ID so that all these rounds are
      linked and recognised as belonging to the same startup.
    </p>
  ),
  initialScreening: (
    <p>
      In LV startup evolution happen in two stages Initial and final screening.
      this is done By startups team.
      <br />
      Only after the Initial screening is approved the startup will get access
      to its detailed profile to update.
    </p>
  ),
  finalScreening: (
    <p>
      In LV startup evolution happen in two stages Initial and final screening.
      this is done By startups team.
      <br />
      Only after final screening is approved the startup can be marked featured
      and made live to investors.
    </p>
  ),
  markAsGood:
    "This is used for internal purpose to mark a GOOD startup even though it is REJECTED.",
  accept: "Accepted startups are moved to the final screening page.",
  investorCommunication: (
    <p>
      This is used by the AIF team to send communication to the committed
      investors easily. The message is shown in the portfolio page of the
      investors as well as it can be sent via emails (if send email to investors
      checkbox is selected).
      <br />
      PUBLISH and UNPUBLISH controls the visibility of these messages on the
      portfolio page of the investors.
    </p>
  ),
  createStartup:
    "Create Startup page is used for creating startups which has rights issue or basically Pre-emptive startups.",
  createStartupPermalink:
    "Permalink, is the link by which the startup will be searched on the LV admin panel and Further can be used for making Deeplink for the startup also, which used for sending campaigns",
  publish:
    "Click Publish to make this report visible to all the investors committed in this deal.",

  notify:
    "Click Notify to send email notifications to all the investors in the deal telling them that the report from startup is now available to them.",
};
