export const tooltipText = {
  rsvp: "This tab stores all rsvp events(live and expired), created by the LV admin. This tab is just to create RSVP events, but Campaigns and Rsvp events are sent from ZOHO",
  createEvent:
    "This is to create new RSVP events. RSVP events takes place generally online only.",
};

export const RsvpTabs: any = [
  {
    name: "CURRENT EVENT",
    value: "current_event",
  },
  {
    name: "PAST EVENT",
    value: "past_event",
  },
];

export const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Campaign Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "RSVP Events",
    navigationButton: false,
    helpText: tooltipText.rsvp,
  },
};

export const RSVPEventTypes: any = [
  {
    label: "Monthly Pitching Session",
    value: "monthly_pitching_session",
  },
  {
    label: "Individual Pitching Session",
    value: "individual_pitching_session",
  },
];

export const RSVPDeadlineHours: any = [
  {
    label: "1 Hour",
    value: 1,
  },
  {
    label: "3 Hour",
    value: 3,
  },
  {
    label: "6 Hour",
    value: 6,
  },
  {
    label: "12 Hour",
    value: 12,
  },
  {
    label: "18 Hour",
    value: 18,
  },
  {
    label: "24 Hour",
    value: 24,
  },
];

export const RSVPEventModalMode: any = Object.freeze({
  CREATE: "create",
  EDIT: "edit",
  VIEW: "view",
});
