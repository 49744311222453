import { tooltipText } from "./CADocConstants";

export const ForeignInvPaymentBreadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "AIF Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Foreign Payment Confirmation Status",
    navigationButton: false,
    helpText: tooltipText.foriegnPaymentStatus,
  },
};

export const ForeignInvPaymentTabs = [
  {
    name: "Confirmed Payment status",
    value: "paid",
  },
  {
    name: "Pending Payment Status",
    value: "pending",
  },
];
