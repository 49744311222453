import { Box } from "@mui/material";
import { useState } from "react";
import { CardContainer } from "../../../../../ui-library/card/card-container";
import { isEmpty } from "../../../../../utils/commonUtils";
import moment from "moment";
import { LinkButton } from "../../../../../ui-library/buttons/LinkButton";
import { useNavigate } from "react-router-dom";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { tooltipText } from "../../../constants/StartupDetailsContant";

const PortfolioDetails = ({ details = {} }: any) => {
  const navigate = useNavigate();
  const [openPreMoneyValuation, setOpenPreMoneyValuation] = useState(false);

  const cardOptions: any = [
    {
      label: "Show all Commitments & investor profile details",
      value: details.commitmentsProfileDetails || "-",
      spacing: 3,
      helpText: tooltipText.showAllCommitments,
      renderer: details?.show_all_commitment?.length ? (
        <LinkButton
          title="Show All"
          onClick={() =>
            navigate(
              `/startup/startup-panel/${details?.id}/show-all-commitments`
            )
          }
        />
      ) : (
        "-"
      ),
    },

    {
      label: "Show All Investor Exit Details",
      helpText: tooltipText.showAllInvExitDetail,
      spacing: 3,
      renderer: details?.startup_exit_data?.length ? (
        <LinkButton
          title="Show All"
          onClick={() =>
            navigate(
              `/startup/startup-panel/${details?.id}/portfolio-startup-exit-data`
            )
          }
        />
      ) : (
        "-"
      ),
    },

    {
      label: "Share Price At First",
      value: details.share_price || "-",
      spacing: 3,
      helpText: tooltipText.sharePriceAtFirst,
    },
    //TODO
    // {
    //   label: "Share Price Valuation",
    //   value: details.startup_share_price_valuations || "-",
    //   spacing: 3,
    // },
    {
      label: "Pre-money Valuation(Inv. Time)",
      spacing: 3,
      renderer: !isEmpty(details?.actual_valuation?.amount)
        ? `${details?.actual_valuation?.symbol} ${details?.actual_valuation?.amount} ${details?.actual_valuation?.short_unit}`
        : "-",
      helpText: tooltipText.preMoneyValuationInvTime,
    },
    //TODO
    {
      label: "Pre-money Valuation",

      spacing: 3,
      renderer: details?.startup_valuations_list?.length ? (
        <LinkButton
          title="View"
          onClick={() => setOpenPreMoneyValuation(true)}
        />
      ) : (
        "-"
      ),
      helpText: tooltipText.preMoenyValuation,
    },
    {
      label: "Investment Amount",
      value: details.investment_amount || "-",
      spacing: 3,
      helpText: tooltipText.investmentAmount,
    },
    {
      label: "Date Of Transfer",
      value: details.date_of_transfer
        ? moment(details?.date_of_transfer).format("MMM DD, YYYY")
        : "-",
      spacing: 3,
      helpText: tooltipText.dateOfTransfer,
    },
    {
      label: "GP Contribution",
      value: details.gp_contribution || "-",
      spacing: 3,
      helpText: tooltipText.gpContribution,
    },
    {
      label: "Primary Startup",
      value: details?.primary_startup?.name || "-",
      spacing: 3,
      helpText: tooltipText.primaryStartup,
    },
    {
      label: "Primary Startup ID",
      value: details.primary_startup?.id || "-",
      spacing: 3,
      helpText: tooltipText.primaryStartup,
    },
    // //TODO
    // {
    //   label: "Startup Follow Up",
    //   value: details.startupFollowUp || "-",
    //   spacing: 3,
    // },
  ];

  return (
    <Box
      sx={{
        border: "1px solid #ececec",
        borderRadius: "0.5rem",
        textAlign: "left",
        marginTop: "1rem",
      }}
    >
      <CardContainer options={cardOptions} />

      <CommonModal
        isOpen={openPreMoneyValuation}
        handleClose={() => setOpenPreMoneyValuation(false)}
        handleSubmit={() => setOpenPreMoneyValuation(false)}
        title="Pre-money Valuation"
        submitText="Ok"
        buttonStyles={{ width: "100%" }}
        closeIcon
        hideCancelButton
      >
        <Box>
          {details?.startup_valuations_list?.map((item: any, index: number) => (
            <ul>
              <li>
                <Box
                  key={index}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  Valuation: {item?.currency_name} {item?.valuation} on{" "}
                  {moment(item?.valuation_date).format("MMM DD, YYYY")}
                </Box>
              </li>
            </ul>
          ))}
        </Box>
      </CommonModal>
    </Box>
  );
};

export default PortfolioDetails;
