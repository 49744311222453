import { API_STATUS } from "../../../../constants/commonConstants";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isEmpty } from "../../../../utils/commonUtils";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getColor } from "../../../../ui-library/Styles/Colors";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import UserManagementManager from "../../managers/userManagementManager";
import { BreadcrumbUserPanel } from "../../constants/informationConstants";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import FilterChip from "../../../../ui-library/filter-chip/FilterChip";
import { CheckCircleOutline } from "@mui/icons-material";
import { INVESTOR_STATUS } from "../../constants/USER_PANEL_CONSTANTS";

export default function UserPanel() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [selectedVal, setSelectedVal] = useState<any>({});
  const [option, setOption] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState(INVESTOR_STATUS.ALL);
  const user_type = location.pathname.split("/")[2];
  const capitalisedUserType =
    user_type.charAt(0).toUpperCase() + user_type.slice(1);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const columns = [
    {
      field: "name",
      headerName: `${capitalisedUserType} Name`,
      headerAlign: "left",
      flex: 2,
      minWidth: 180,
      renderCell: (params: any) => {
        return (
          <Link
            to={`/user/user-panel/${params.row?.id}/`}
            style={{ color: getColor("action_button", 300) }}
          >
            {params?.row?.name ? params?.row?.name : "Go to User"}
          </Link>
        );
      },
    },
    {
      field: "email",
      headerName: `${capitalisedUserType} Email`,
      headerAlign: "left",
      flex: 2,
      minWidth: 300,
    },

    ...(user_type === "founder"
      ? [
          {
            field: "startup_name",
            headerName: "Admin of Startup",
            align: "left",
            minWidth: 180,
          },
        ]
      : []),

    {
      field: "phone_no",
      headerName: "Phone no.",
      headerAlign: "left",
      minWidth: 150,
    },
    {
      field: "joined",
      headerName: "Joined on",
      align: "right",
      headerAlign: "right",
      minWidth: 150,
    },
    ...(user_type === "investor"
      ? [
          {
            field: "is_profile_completed",
            headerName: "Profile Completed",
            align: "center",
            minWidth: 180,
            renderCell: (params: any) =>
              params?.row?.is_profile_completed ? "Yes" : "No",
          },
          {
            field: "is_faas",
            headerName: "Faas/Non-Faas",
            headerAlign: "left",
            minWidth: 150,
            renderCell: (params: any) =>
              params?.row?.is_faas ? "Faas" : "Non-Faas",
          },
        ]
      : []),
  ];

  const getData = async ({ page, userId, user_type, investor_type }: any) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let data = await userManagementManager.getAllUsers(
        page,
        userId,
        user_type,
        investor_type
      );

      setFormattedData(data?.formattedData);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleTextChange = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData({
        search: value,
        type:
          user_type === "founder"
            ? "only_founder_users"
            : "only_investor_users",
        user_search_private_hashtag: "",
        without_investment_profile: user_type === "founder" ? false : true,
      });
      result = result?.results?.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setOption(result);
    } catch (err: any) {
      toast.error(err?.detail);
    }
  };

  const onSelect = (val: any) => {
    setOption([]);
    getData({
      page: 1,
      userId: val?.value,
      user_type,
      investor_type: selectedStatus,
    });
    setPage(1);
  };

  useEffect(() => {
    getData({
      page,
      investor_type: selectedStatus,
      user_type,
      userId: selectedVal?.value,
    });
  }, [page, selectedStatus]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={BreadcrumbUserPanel(user_type)?.steps}
            pageHeader={BreadcrumbUserPanel(user_type)?.pageHeader}
            stepKey={BreadcrumbUserPanel(user_type)?.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" justifyContent="end" gap={2}>
          <Box width={"28vw"}>
            <LvAutoComplete
              label={`Search by ${capitalisedUserType} Name or Email`}
              options={option}
              setOptions={setOption}
              selectedObject={selectedVal}
              setSelectedObject={setSelectedVal}
              onChange={handleTextChange}
              onSelect={onSelect}
            />
          </Box>
          {user_type === "investor" && (
            <Box>
              <ActionButton onClick={() => navigate("/user/add-user")}>
                Add New {capitalisedUserType}
              </ActionButton>
            </Box>
          )}
        </Grid>
        {user_type === "investor" && (
          <Stack
            direction="row"
            gap={2}
            width="fit-content"
            alignItems="center"
            mb={2}
            mt={1}
            pl={3}
          >
            <Typography fontSize="14px">Filter By Investor Type:</Typography>
            <FilterChip
              label={INVESTOR_STATUS.ALL}
              icon={<CheckCircleOutline fontSize="small" />}
              active={selectedStatus === INVESTOR_STATUS.ALL}
              onClick={() => {
                setSelectedStatus(INVESTOR_STATUS.ALL);
              }}
            />
            <FilterChip
              label={INVESTOR_STATUS.FAAS}
              icon={<CheckCircleOutline fontSize="small" />}
              active={selectedStatus === INVESTOR_STATUS.FAAS}
              onClick={() => {
                setSelectedStatus(INVESTOR_STATUS.FAAS);
              }}
            />
            <FilterChip
              label={INVESTOR_STATUS.NON_FAAS}
              icon={<CheckCircleOutline fontSize="small" />}
              active={selectedStatus === INVESTOR_STATUS.NON_FAAS}
              onClick={() => {
                setSelectedStatus(INVESTOR_STATUS.NON_FAAS);
              }}
            />
          </Stack>
        )}
      </Grid>

      <div style={{ overflow: "auto" }}>
        {apiStatus == API_STATUS.LOADING ? (
          <TablePlaceholder
            rows={6}
            columns={6}
            rowHeight="40px"
            columnHeaderHeight="40px"
          />
        ) : (
          <LvComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={40}
            headerHeight={40}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        )}
      </div>
    </Box>
  );
}
