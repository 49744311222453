import React, { FC } from "react";
import { CommonModal } from "../../../../../ui-library/modals/common-modal";
import { LvComplexTable } from "../../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "lodash";
import { Box } from "@mui/material";

export type ActivationNotesModalProps = {
  open: boolean;
  handleClose: () => void;
  notes: {
    note: string;
    added_by: string;
    created_at: string | number;
  }[];
};

let columns = [
  {
    field: "note",
    headerName: "Note",
    headerAlign: "left",
    align: "left",
    flex: 3,
  },
  {
    field: "added_by",
    headerName: "Note added by",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },

  {
    field: "created_at",
    headerAlign: "right",
    align: "right",
    headerName: "Date of note",
    flex: 1,
  },
];

const ActivationNotesModal: FC<ActivationNotesModalProps> = (props) => {
  const { open, handleClose, notes } = props;

  return (
    <CommonModal
      isOpen={open}
      handleClose={handleClose}
      handleSubmit={handleClose}
      title="Activation Notes"
      submitText="Okay"
      hideCancelButton
      closeIcon
      buttonStyles={{ width: "100%" }}
    >
      <Box width="60vw">
        <LvComplexTable
          rows={notes}
          columns={columns}
          rowHeight={40}
          headerHeight={40}
          minWidth={"60vw"}
          filterMode="server"
          enableAdvancedFiltering
          isUnFilteredDataPresent={!isEmpty(notes)}
          enableAutoHeight={true}
          hideToobar
        />
      </Box>
    </CommonModal>
  );
};

export default ActivationNotesModal;
