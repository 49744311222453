import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { StatsticCard } from "../../../ui-library/card/statistic-card";
import { SectionHeader } from "../../../ui-library/header/section-header";
import DashboardManager from "../manager/dashboardManager";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { LvComplexTable } from "../../../ui-library/tables/lv-complex-table";
import { DashboardSkeleton } from "../skeleton/dashboardSkeleton";
import { getColor } from "../../../ui-library/Styles/Colors";
import { tooltipText } from "../constants/dashboardConstants";

const columns = [
  {
    field: "startup_id",
    headerName: "Startup Id",
    width: 150,
    headerAlign: "left",
  },
  {
    field: "startup_name",
    headerName: "Startup Name",
    width: 200,
    headerAlign: "left",
    renderCell: (params: any) => {
      return (
        <Link
          to={`/startup/startup-panel/${params.row.startup_id}`}
          style={{ color: getColor("action_button", 300) }}
        >
          {params?.row?.startup_name}
        </Link>
      );
    },
  },
  {
    field: "scheme_name",
    headerName: "Scheme Name",
    width: 200,
    headerAlign: "left",
    renderCell: (params: any) => params.row.scheme_name ?? "-",
    helpText: tooltipText.schemeName,
  },
  {
    field: "founding_date",
    headerName: "Founded On",
    width: 150,
    headerAlign: "left",
  },
  {
    field: "fund_raising_since",
    headerName: "Fund Raising Since",
    width: 180,
    headerAlign: "left",
  },
  {
    field: "total_ask",
    headerName: "Total Ask",
    width: 150,
    headerAlign: "left",
    renderCell: (params: any) =>
      params?.row?.total_ask?.amount ? `${params?.row?.total_ask?.symbol} ${params?.row?.total_ask?.amount} ${params?.row?.total_ask?.unit}` : "-",
  },
  {
    field: "total_raised",
    headerName: "Total Raised",
    width: 150,
    headerAlign: "left",
    renderCell: (params: any) =>
      params?.row?.total_raised?.amount ? `${params?.row?.total_raised?.symbol} ${params?.row?.total_raised?.amount} ${params?.row?.total_raised?.unit}` : "-",
  },
  {
    field: "lv_ask",
    headerName: "LV Ask",
    width: 150,
    headerAlign: "left",
    renderCell: (params: any) =>
      params?.row?.lv_ask?.amount ? `${params?.row?.lv_ask?.symbol} ${params?.row?.lv_ask?.amount} ${params?.row?.lv_ask?.unit}` : "-",
  },
  {
    field: "lv_raised",
    headerName: "LV Raised",
    width: 150,
    headerAlign: "left",
    renderCell: (params: any) =>
      params?.row?.lv_raised?.amount ? `${params?.row?.lv_raised?.symbol} ${params?.row?.lv_raised?.amount} ${params?.row?.lv_raised?.unit}` : "-",
  },
  {
    field: "external_ask",
    headerName: "External Ask",
    width: 150,
    headerAlign: "left",
    renderCell: (params: any) =>
      params?.row?.external_ask?.amount ? `${params?.row?.external_ask?.symbol} ${params?.row?.external_ask?.amount} ${params?.row?.external_ask?.unit}` : "-",
  },
  {
    field: "external_raised",
    headerName: "External Raised",
    width: 150,
    headerAlign: "left",
    renderCell: (params: any) =>
     params?.row?.external_raised?.amount ? `${params?.row?.external_raised?.symbol} ${params?.row?.external_raised?.amount} ${params?.row?.external_raised?.unit}` : "-",
  },
  {
    field: "created_at",
    headerName: "Created At",
    width: 150,
    headerAlign: "left",
  },
  {
    field: "updated_at",
    headerName: "Updated At",
    width: 150,
    headerAlign: "left",
  },
];

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const dashboardManager = new DashboardManager();
  const [dashboardData, setDashboardData] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const data = await dashboardManager.getDashboardData();
      setDashboardData(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      toast.error("Failed to fetch dashboard data");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <DashboardSkeleton />;
  }
  return (
    <Box>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <StatsticCard
            title="Initial Screening"
            value={dashboardData?.initial_screening_pending_count ?? "-"}
            width="250px"
            height="94px"
            onClick={() => navigate("/startup/initial-screening")}
            helpText={tooltipText.initialScreening}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} xl={4}>
          <StatsticCard
            title="Final Screening"
            value={dashboardData?.final_screening_pending_count ?? "-"}
            height="94px"
            width="250px"
            onClick={() => navigate("/startup/final-screening")}
            helpText={tooltipText.finalScreening}
          />
        </Grid>
      </Grid>

      <Box mt={3}>
        <SectionHeader helpText={tooltipText.publicDeal}>
          Public Deals
        </SectionHeader>

        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <StatsticCard
              title="Live Deals"
              value={dashboardData?.fundraising_startups_count ?? "-"}
              width="250px"
              height="94px"
              helpText={tooltipText.liveDeal}
              onClick={() =>
                navigate("/startup/startup-panel", {
                  state: { startupType: "non_faas", startupStatus: "live" },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <StatsticCard
              title="Fundraising Closed"
              value={dashboardData?.funding_closed_startups_count ?? "-"}
              height="94px"
              width="250px"
              helpText={tooltipText.fundraisingClosed}
              onClick={() =>
                navigate("/startup/startup-panel", {
                  state: { startupType: "non_faas", startupStatus: "closed" },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <StatsticCard
              title="Funded"
              value={dashboardData?.funded_startups_count ?? "-"}
              height="94px"
              width="250px"
              helpText={tooltipText.funded}
              onClick={() =>
                navigate("/startup/startup-panel", {
                  state: { startupType: "non_faas", startupStatus: "funded" },
                })
              }
            />
          </Grid>
        </Grid>
        <Grid mt={2}>
          {dashboardData?.live_public_startups?.length > 0 && (
            <LvComplexTable
              rows={dashboardData?.live_public_startups ?? []}
              columns={columns}
              hideToobar
            />
          )}
        </Grid>
      </Box>

      <Box mt={3}>
        <SectionHeader helpText={tooltipText.faasDeal}>
          FAAS Deals
        </SectionHeader>

        <Grid container spacing={3} alignItems="center" overflow="hidden">
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <StatsticCard
              title="Live Deals"
              value={dashboardData?.fundraising_diy_startups_count ?? "-"}
              width="250px"
              height="94px"
              helpText={tooltipText.liveDeal}
              onClick={() =>
                navigate("/startup/startup-panel", {
                  state: { startupType: "faas", startupStatus: "live" },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <StatsticCard
              title="Fundraising Closed"
              value={dashboardData?.funding_closed_diy_startups_count ?? "-"}
              height="94px"
              width="250px"
              helpText={tooltipText.fundraisingClosed}
              onClick={() =>
                navigate("/startup/startup-panel", {
                  state: { startupType: "faas", startupStatus: "closed" },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} xl={4}>
            <StatsticCard
              title="Funded"
              value={dashboardData?.funded_diy_startups_count ?? "-"}
              height="94px"
              width="250px"
              helpText={tooltipText.funded}
              onClick={() =>
                navigate("/startup/startup-panel", {
                  state: { startupType: "faas", startupStatus: "funded" },
                })
              }
            />
          </Grid>
        </Grid>

        <Grid mt={2}>
          {dashboardData?.live_diy_startups?.length > 0 && (
            <LvComplexTable
              rows={dashboardData?.live_diy_startups ?? []}
              columns={columns}
              hideToobar
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
