import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
  Tooltip,
} from "@mui/material";
import { InputField } from "../../../../ui-library/form-elements/CustomTextField";
import { CommonModal } from "../../../../ui-library/modals/common-modal";
import { FC, ReactNode, useEffect, useState } from "react";
import { RadioButtonSelect } from "../../../../ui-library/form-elements/RadioButtonSelect";
import UserManagementManager from "../../managers/userManagementManager";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Formik, Form, Field } from "formik";
import { PhoneNumber } from "../../../../ui-library/form-elements/phone-number/PhoneNumber";
import { Dropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import {
  GenderOptions,
  ProfileTypeOptions,
  tooltipText,
} from "../../constants/common";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MultipleSelectCheckmarks from "../../../../ui-library/dropdowns/multiple-select-checkmark";
import { IamHavingOptions } from "../../constants/VERIFY_INVESTOR_CONSTANTS";
import { FormPlaceholder } from "../../../../ui-library/placeholders/form-placeholder/FormPlaceholder";
import { formPlaceholderConfig } from "../../../syndicateManagement/constants";

type NoteModalType = {
  isOpen: boolean;
  handleClose: () => void;
  selected: any;
  setPage?: (num: number) => void;
  refreshData?: (pageNo: number) => void;
};

const ApproveRejectModal: FC<NoteModalType> = (props) => {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);
  const { isOpen, handleClose, selected, setPage, refreshData } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [countryOptions, setCountryOptions] = useState<any>([]);
  const [cityOptions, setCityOptions] = useState<any>([]);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const individualHavingObj: any = {
    "Early stage investment experience": selected?.individual_statement_fresher,
    "Experience as a serial entrepreneur":
      selected?.individual_statement_intermediate,
    "Senior management professional with at least 10 years of experience":
      selected?.individual_statement_experience,
  };

  const initialValues = {
    name: selected?.name || "",
    email: selected?.email || "",
    phone: selected?.mobile || "",
    linkedin: selected?.linkedin_url || "",
    gender: selected?.gender || "",
    country: selected?.citizenship_country?.[0] || "",
    city: selected?.city
      ? { label: selected?.location, value: selected?.city?.[0]?.id }
      : "",
    profile: selected?.describes_your_profile
      ? {
          label: selected?.describes_your_profile,
          value: selected?.describes_your_profile,
        }
      : "",
    isNetWorth2crore: selected?.net_worth ? "yes" : "no",
    sendEmail: false,
    individualHaving: Object.keys(individualHavingObj).filter(
      (key) => individualHavingObj[key]
    ),
    notes: "",
  };

  const getLookupOptions = async (search: string, type: string) => {
    try {
      let result = await userManagementManager.getLookupData({ search, type });
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      if (type === "locations") setCityOptions(result);
      if (type === "countries") setCountryOptions(result);
    } catch (e: any) {
      console.error(e.message || "Error fetching lookup options");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getLookupOptions("", "countries"),
      getLookupOptions("", "locations"),
    ]).then((result) => setIsLoading(false));
  }, [selected]);

  const handleApprove = async (value: any) => {
    try {
      const formattedData = {
        note: value.notes,
        send_email: value.sendEmail,
      };
      await userManagementManager.approveInvestor(selected?.id, formattedData);
      setIsApproving(false);
      toast.success("Investor approved successfully!");
      handleClose();
      setPage && setPage(1);
      refreshData && refreshData(1);
    } catch (e: any) {
      console.error(e.message || "Error approving investor");
      toast.error("Error approving investor");
      setIsApproving(false);
    }
  };

  const handleReject = async (value: any) => {
    try {
      const formattedData = {
        note: value.notes,
        sendEmail: value.sendEmail,
      };
      await userManagementManager.rejectInvestor(selected?.id, formattedData);
      setIsRejecting(false);
      toast.success("Investor rejected successfully!");
      handleClose();
      setPage && setPage(1);
      refreshData && refreshData(1);
    } catch (e: any) {
      console.error(e.message || "Error rejecting investor");
      toast.error("Error rejecting investor");
      setIsRejecting(false);
    }
  };

  const onSubmit = (values: any) => {
    if (isApproving) {
      handleApprove(values);
    } else if (isRejecting) {
      handleReject(values);
    }
  };

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleClose}
      title="Verify Investor"
      submitText="Close"
      hideButtons
      closeIcon
    >
      {isLoading ? (
        <Box width={"40vw"}>
          <FormPlaceholder elementsConfig={formPlaceholderConfig} />
        </Box>
      ) : (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, setFieldValue, errors, handleSubmit }) => {
            return (
              <Form>
                <Stack gap={2} width={"40vw"} paddingTop={2}>
                  <InputField
                    label="Name"
                    fieldName="name"
                    id="name"
                    value={values?.name}
                    error={!!errors?.name}
                    disabled
                  />
                  <InputField
                    label="Email"
                    fieldName="email"
                    id="email"
                    value={values?.email}
                    error={!!errors?.email}
                    disabled
                  />
                  <Field name="phone">
                    {({ form }: any) => (
                      <PhoneNumber
                        form={form}
                        label="Phone"
                        id="phone"
                        error={!!errors?.phone}
                        value={values?.phone}
                        onChange={(number: any, country: any, e: any) => {
                          setFieldValue("phone", `+${country}`, true);
                        }}
                        disabled
                      />
                    )}
                  </Field>
                  <InputField
                    label="Linkedin URL"
                    fieldName="linkedin"
                    id="linkedin"
                    value={values?.linkedin}
                    error={!!errors?.linkedin}
                    disabled
                  />
                  <Dropdown
                    label="Gender"
                    options={GenderOptions}
                    value={values?.gender}
                    error={!!errors?.gender}
                    disabled
                  />
                  <Dropdown
                    label="Citizenship Country"
                    options={countryOptions}
                    value={values?.country}
                    error={!!errors?.country}
                    disabled
                  />
                  <Dropdown
                    label="City"
                    options={cityOptions}
                    value={values?.city}
                    error={!!errors?.city}
                    disabled
                  />
                  <Dropdown
                    label="Profile"
                    options={ProfileTypeOptions}
                    value={values?.profile}
                    error={!!errors?.profile}
                    disabled
                  />
                  <RadioButtonSelect
                    formLabel="Do you have a net worth of more than 2 CR INR apart from primary residence?"
                    id="isNetWorth2crore"
                    fieldName="isNetWorth2crore"
                    options={[
                      { name: "Yes", value: "yes", disabled: true },
                      { name: "No", value: "no", disabled: true },
                    ]}
                    value={values?.isNetWorth2crore}
                  />
                  <MultipleSelectCheckmarks
                    label="I am an individual having"
                    options={IamHavingOptions}
                    selectedValues={values?.individualHaving}
                    disabled
                  />
                  <FormControlLabel
                    key={1}
                    control={
                      <Checkbox
                        checked={values?.sendEmail}
                        onChange={(e) =>
                          setFieldValue("sendEmail", e.target.checked)
                        }
                        value={values?.sendEmail}
                        size="small"
                      />
                    }
                    label="Send Email to Investor"
                  />

                  <InputField
                    label="Notes"
                    fieldName="notes"
                    id="notes"
                    value={values?.notes}
                    error={!!errors?.notes}
                  />
                  <Stack
                    direction="row"
                    gap={1}
                    mt={2}
                    pb={2}
                    justifyContent="end"
                  >
                    <Tooltip
                      title={tooltipText.verifyInvestorModal}
                      placement="left"
                      arrow
                    >
                      <Box>
                        <ActionButton
                          disabled={isApproving}
                          isLoading={isApproving}
                          onClick={() => {
                            setIsApproving(true);
                            handleSubmit();
                          }}
                        >
                          Approve
                        </ActionButton>
                      </Box>
                    </Tooltip>

                    <Tooltip
                      title={tooltipText.rejectInvestorModal}
                      placement="top"
                      arrow
                    >
                      <Box>
                        <OutlinedButton
                          disabled={isRejecting}
                          isLoading={isRejecting}
                          onClick={() => {
                            setIsRejecting(true);
                            handleSubmit();
                          }}
                        >
                          Reject
                        </OutlinedButton>
                      </Box>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      )}
    </CommonModal>
  );
};

export default ApproveRejectModal;
