import { Box } from "@mui/material";
import { CardContainer } from "../../../../../ui-library/card/card-container";
import { panelCardSx } from "./indexSX";
import StartupManagement from "../../../managers/startupManager";

const FundraiseDetails = ({ details = {} }: any) => {
  const startupManager = new StartupManagement({});
  const cardOptions: any =
    startupManager.getFundraiseDetailsCardoptions(details);

  return (
    <Box sx={panelCardSx}>
      <CardContainer options={cardOptions} />
    </Box>
  );
};

export default FundraiseDetails;
