export const DocumentCategoryType = {
  PRE_EXECUTION_DOCUMENTS: "pre_execution_documents",
  EXECUTION_DOCUMENTS: "execution_documents",
  FILINGS_DOCUMENTS: "filings_documents",
  POST_TRANSFER_DOCUMENTS: "post_transfer_documents",
  EXIT_DOCUMENTS: "exit_documents",
};

export const DocumentTypes = {
  onboarding: "Onboarding",
  navdocument: "NAV",
  unitstatement: "Unit_Statements",
  exitdocumet: "Exit_Document",
  tax: "Tax",
  fund: "FUND",
};

export const QuarterOptions = [
  {
    label: "Q1",
    value: "Q1",
  },
  {
    label: "Q2",
    value: "Q2",
  },
  {
    label: "Q3",
    value: "Q3",
  },
  {
    label: "Q4",
    value: "Q4",
  },
];

export const DocumentTypeNames = [
  {
    label: "Onboarding",
    value: DocumentTypes.onboarding,
  },
  {
    label: "NAV",
    value: DocumentTypes.navdocument,
  },
  {
    label: "Unit Statements",
    value: DocumentTypes.unitstatement,
  },
  {
    label: "Exit Document",
    value: DocumentTypes.exitdocumet,
  },
  {
    label: "Tax",
    value: DocumentTypes.tax,
  },
  {
    label: "Fund",
    value: DocumentTypes.fund,
  },
];

export const DocumentCategoryOptions = [
  {
    label: "Pre Execution Documents",
    value: DocumentCategoryType.PRE_EXECUTION_DOCUMENTS,
  },
  {
    label: "Execution Documents",
    value: DocumentCategoryType.EXECUTION_DOCUMENTS,
  },
  {
    label: "Filling Documents",
    value: DocumentCategoryType.FILINGS_DOCUMENTS,
  },
  {
    label: "Post Transfer Documents",
    value: DocumentCategoryType.POST_TRANSFER_DOCUMENTS,
  },
  {
    label: "Exit Documents",
    value: DocumentCategoryType.EXIT_DOCUMENTS,
  },
];

export const InvestorDocumentNameOptions = [
  {
    label: "Nominee Form",
    value: "Nominee Form",
  },
  {
    label: "CKYC Form",
    value: "CKYC Form",
  },
  {
    label: "Bank Statement/ Cancelled Cheque",
    value: "Bank Statement/ Cancelled Cheque",
  },
  {
    label: "PAN Card",
    value: "PAN Card",
  },
  {
    label: "Proof of Identity (Passport / DL / Aadhaar)",
    value: "Proof of Identity (Passport / DL / Aadhaar)",
  },
  {
    label: "CA signed document",
    value: "CA signed document",
  },
  {
    label: "Exit Document",
    value: "Exit Document",
  },
];

export const tooltipText = {
  investorDocument: (
    <p>
      These are document which get uploaded here By LV admins and Investor
      itself.
      <br />
      LV admins can Publish, Unpublish, View, Approve and Reject the documents
      from here.
      <br />
      Document like signed ca doc, PAN, address proof, cancelled cheque, etc
      which are uploaded by investor from Letsventure platform will also appear
      here.
      <br />
      LV admin can also uplaod same types of documents mentioned above on
      investors behalf.
    </p>
  ),
  uploadDocument: (
    <p>
      To upload different types of documents for particular investor, is done
      from here.
      <br />
      If investor is unwilling to upload documents, LV admin can upload document
      like(CA doc, NAV, UNIT STATEMENT, EXIT, FUND) for particular investor.
    </p>
  ),
  bulkUploadDocument: (
    <p>
      uploading documents for More then one investor at the same time can be
      done from here.
      <br />
      Document like EXIT, NAV, UNIT STATEMENT can be uploaded from bulk Upload.
      <br />
      To bulk Upload LV admins must make a ZIP folder containing All investors
      document for which they have to upload. File name format is
      "User_id"_"User_name".pdf
    </p>
  ),
  dealDcoument: (
    <p>
      This tab contains document related to Deal and is uploaded by LV admin.
      <br />
      after uploading these documents, these documents appears on the investor
      side under document vault---- startup section.
      <br />
      Document like pre-execution, execution, Filling, Post transfer, exit
      documents can be uplaoded from here.
      <br />
      Investor see these document on there side, if they have committed in that
      particular deal and deal got funded.
    </p>
  ),
};

export const BreadCrumbDataDealDocument = {
  stepKey: 1,
  steps: [
    {
      title: "Document Management",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Deal Documents",
    navigationButton: false,
    helpText: tooltipText.dealDcoument,
  },
};

export const BreadCrumbDataInvestorDocument = {
  stepKey: 1,
  steps: [
    {
      title: "Document Management",
      titleDisplay: true,
    },
  ],
  pageHeader: {
    header: "Investor Documents",
    navigationButton: false,
    helpText: tooltipText.investorDocument,
  },
};
