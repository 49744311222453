import React from "react";
import { FormHelperText, Stack } from "@mui/material";
import "./leadinvestor.css";
import { FieldArray } from "formik";

import { InputField } from "../../../../../../ui-library/form-elements/CustomTextField";

export default function LeadInvestorList(props: any) {
  const {
    isMandatory,
    carryError,
    labelTitle,
    notes,
    touched,
    errors,
    labelName,
    values,
    disableInvestorName = true,
    disableLeadCarry = false,
  } = props;

  const totalCarry = values?.leadInvestors?.reduce(
    (sum: any, item: any) => sum + Number(item?.lead_carry ?? 0),
    0.0
  );
  const leadCarryAllocationLeft = values.leadCarryPercent
    ? values.leadCarryPercent - totalCarry
    : 0;

  return (
    <div className="edit-form-row">
      {labelTitle && (
        <label className="edit__profile_label-title">
          {labelTitle}{" "}
          {isMandatory ? <span className="text-danger">*</span> : ""}
        </label>
      )}

      <FieldArray
        name="leadInvestors"
        render={(arrayHelpers) => (
          <>
            {values?.leadInvestors?.length
              ? values?.leadInvestors?.map((investor: any, index: number) => (
                  <>
                    <Stack
                      direction="row"
                      gap={2}
                      key={index}
                      mt={index !== 0 ? 2 : "unset"}
                    >
                      <InputField
                        label={`Lead Investor ${index + 1}`}
                        placeholder="Lead Investor"
                        name={`leadInvestors[${index}].investor_name`}
                        fieldName={`leadInvestors[${index}].investor_name`}
                        id={`leadInvestors[${index}].investor_name`}
                        value={investor.investor_name}
                        disabled={disableInvestorName}
                      />

                      <InputField
                        type="number"
                        label="Carry %"
                        placeholder="Carry %"
                        name={`leadInvestors[${index}].lead_carry`}
                        fieldName={`leadInvestors[${index}].lead_carry`}
                        id={`leadInvestors[${index}].lead_carry`}
                        value={investor.lead_carry}
                        disabled={disableLeadCarry}
                        onChange={(value: any) => {
                          arrayHelpers.replace(index, {
                            investor_id: investor.investor_id,
                            lead_carry: value,
                          });
                        }}
                      />
                    </Stack>

                    {(isMandatory || carryError) &&
                    errors &&
                    values?.leadInvestors?.length === index + 1 &&
                    Object.keys(touched)?.join("")?.includes(labelName) &&
                    errors[labelName] ? (
                      <FormHelperText error={true} sx={{ marginLeft: "auto" }}>
                        {errors[labelName]}
                      </FormHelperText>
                    ) : (
                      <FormHelperText sx={{ marginLeft: "auto" }}>
                        {leadCarryAllocationLeft > 0 &&
                        values?.leadInvestors?.length === index + 1
                          ? `Carry allocation left: ${leadCarryAllocationLeft}`
                          : ""}
                      </FormHelperText>
                    )}
                  </>
                ))
              : null}
          </>
        )}
      />

      {notes && <div className="edit_profile_notes"> {notes} </div>}
    </div>
  );
}
